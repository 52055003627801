<td [hidden]="isHidden">
  {{title}}
</td>
<td *ngIf="!editMode" [hidden]="isHidden">
  {{form.controls[name]?.value | date}}
</td>
<td *ngIf="editMode" [hidden]="isHidden">
  <div class="input-group">
    <input
      [(ngModel)]="date"
      (dateSelect)="onDateSelect($event)"
      [minDate]="birthday ? {year: 1900, month: 1, day: 1} : {}"
      [maxDate]="birthday ? today : {}"
      (click)="datePicker.toggle()"
      container="body"
      class="form-control filters__input filters__input--date"
      [class.is-invalid]="isFormSubmitted && form.get(name).invalid"
      ngbDatepicker
      placeholder="{{'home.label.select_date' | translate}}"
      #datePicker="ngbDatepicker">
  </div>
  <app-display-validation [errors]="errors" [additionalValidate]="isFormSubmitted"></app-display-validation>
</td>