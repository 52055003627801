import { IErrorDto } from './errorDto.model';

export class EnvelopeGenericModel<T> implements IGenericModelEnvelope<T> {
  data?: T;
  errors?: IErrorDto[] | null;
  readonly hasErrors?: boolean;

  constructor(data?: IGenericModelEnvelope<T>) {
    if (!data) {
      return;
    }
    for (const property in data) {
      if (data.hasOwnProperty(property)) {
        (<any>this)[property] = (<any>data)[property];
      }
    }
  }
}

export interface IGenericModelEnvelope<T> {
  data?: T;
  errors?: IErrorDto[] | null;
  hasErrors?: boolean;
}
