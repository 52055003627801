import { setAuthPage, setErrorPage, setFetching } from './page.actions';
import { INITIAL_STATE, IPageState } from './page.model';

import { Action, createReducer, on } from '@ngrx/store';

export const pageReducer = (
  state: IPageState,
  action: Action,
): IPageState => reducer(state, action);

const reducer = createReducer<IPageState>(
  INITIAL_STATE,
  on(setAuthPage, (state, action) => ({
    ...state,
    authPage: action.authPage,
  })),
  on(setErrorPage, (state, action) => ({
    ...state,
    errorPage: action.errorPage,
  })),
  on(setFetching, (state, action) => ({
    ...state,
    isFetching: action.isFetching,
  })),
);
