import { RideTypeCategoryListState } from 'app/features/captains/captains/captain-details/store/models/trips.model';
import * as actions from '../actions/profile-tabs.actions';
import { IProfileModelState } from '../models/profile-tabs.model';

import { Action, createReducer, on } from '@ngrx/store';

export const initialState: IProfileModelState = {

};

export const rideTypeCategoryListInitialState: RideTypeCategoryListState = {
  filterColumns: [],
  list: [],
  metaData: [],
  pageInfo: {
      totalCount: 1,
      customCount: undefined,
      pageNumber: 1,
      totalPages: 1,
      pageSize: 20,
  },
};

const reducer = createReducer(
  initialState,
  on(actions.fetchProfileDetailsSuccess, (state, { profile }) => {
    const cloneProfile = JSON.parse(JSON.stringify(profile));

    return { ...state, ...cloneProfile, documentUpdated: false };
  }),
  on(actions.rejectedReasonsSuccess, (state, { data }) => ({ ...state, reasonsRejected: [...data] })),
  on(actions.citySuccess, (state, { data }) => ({ ...state, cities: [...data] })),
  on(actions.saveNationalIdDataSuccess, (state, { profile }) => ({ ...state, ...profile })),
  on(actions.saveBankInformationSuccess, (state, { profile }) => ({ ...state, ...profile })),
  on(actions.saveDrivingLicenseSuccess, (state, { profile }) => ({ ...state, ...profile })),
  on(actions.changePhoneNumberSuccess, (state, { phoneNumber }) => ({ ...state, phoneNumber })),
  on(actions.changeEmailSuccess, (state, { email }) => ({ ...state, email })),
  on(actions.currentVehicle, (state, { vehicle }) => ({ ...state, currentVehicle: { ...vehicle } })),
  on(actions.changeVehicleDetailsSuccess, (state, { vehicle }) => ({ ...state, currentVehicleDetails: { ...vehicle } })),
  on(actions.defaultVehicleDetailsSuccess, (state, { vehicle }) => ({ ...state, defaultVehicleDetails: { ...vehicle } })),
  on(actions.updateVehicleRegistrationSuccess, (state, { vehicle }) => ({
    ...state,
    currentVehicleDetails: {
      ...state.currentVehicleDetails,
      registration: { ...vehicle },
    },
  })),
  on(actions.updateVehicleInsuranceSuccess, (state, { vehicle }) => ({
    ...state, currentVehicleDetails: {
      ...state.currentVehicleDetails,
      insurance: { ...vehicle },
    },
  })),
  on(actions.updateApplicationStatusSuccess, (state, { profile }) => ({ ...state, ...profile })),
  on(actions.sendToAPISuccess, (state, { profile }) => ({ ...state, ...profile })),
  on(actions.rejectDocumentSuccess, (state) => ({ ...state, documentUpdated: true })),
  on(actions.approveDocumentSuccess, (state) => ({ ...state, documentUpdated: true })),
);

const RideTypeCategoryListReducerInternal = createReducer(
  rideTypeCategoryListInitialState,
  on(actions.fetchRideTypeCategoryListSuccess, (state, { rideTypeCategoryList }) => ({
      list: rideTypeCategoryList.list,
      filterColumns: rideTypeCategoryList.filterColumns,
      metaData: rideTypeCategoryList.metaData,
      pageInfo: rideTypeCategoryList.pageInfo,
  })),
);

export const profileTabsReducer = (state: IProfileModelState | undefined, action: Action) => reducer(state, action);
export const rideTypeCategoryListReducer = (state: RideTypeCategoryListState, action: Action) => RideTypeCategoryListReducerInternal(state, action);
