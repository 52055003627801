import { IDocumentResponseModel, ILookupResponseModel, IVehicleDetailsResponse } from '@jameel/core/models/';
import { ToastService } from '@jameel/core/services/toast.service';

import * as fromActions from '../../../features/home/application-detail/store/actions';
import * as fromProfileTabs from '../../../features/home/application-detail/store/actions/profile-tabs.actions';
import { IProfileState } from '../../../features/home/application-detail/store/profile-tabs.state';
import { documentUpdated, rejectedReasons } from '../../../features/home/application-detail/store/selectors';

import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewEncapsulation } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CarouselComponent implements OnDestroy, OnInit {
  selectedValue: ILookupResponseModel;

  @Input() file: IDocumentResponseModel;
  @Input() profileId: string;
  @Input() vehicle: IVehicleDetailsResponse;

  list: ILookupResponseModel[];
  notes = '';
  private sub: Subscription = new Subscription();

  constructor(private modalService: NgbModal, private store: Store<IProfileState>, private toastService: ToastService,
    private translateService: TranslateService) {

  }

  ngOnInit(): void {
    this.sub.add(this.store.select(rejectedReasons).subscribe((reasons) => {
      this.list = reasons;
    }));

    this.sub.add(this.store.select(documentUpdated).subscribe((result) => {
      this.store.dispatch(fromActions.fetchProfileDetails({ id: this.profileId }));

      if (this.vehicle) {
        this.store.dispatch(fromActions.changeVehicleDetails({ id: this.vehicle.externalId }));
      }
    }));
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  openModal(modal: TemplateRef<NgbModal>, responsive: boolean = false) {
    this.modalService.open(modal, { windowClass: responsive ? 'modal-responsive' : '' });
  }

  reject() {
    if (!(this.notes && this.list.includes(this.selectedValue))) {
      this.toastService.showError(this.translateService.instant('error.fill_data'));

      return;
    }
    this.store.dispatch(fromProfileTabs.rejectDocument({
      documentExternalId: this.file.externalId,
      rejectReasonExternalId: this.selectedValue.externalId,
      notes: this.notes,
    }));

    this.modalService.dismissAll();
  }

  approve() {
    this.store.dispatch(fromProfileTabs.approveDocument({ id: this.file.externalId }));
    this.modalService.dismissAll();
  }
}
