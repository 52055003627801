export type Language = 'en' | 'ar';

export enum DocumentDir {
  // tslint:disable-next-line: naming-convention
  en = 'ltr',
  // tslint:disable-next-line: naming-convention
  ar = 'rtl',
}

export interface ISettingsState {
  language: string;
}
