<td>{{title}}</td>
<td *ngIf="!editMode">{{selectedValue || '-'}}</td>
<td *ngIf="editMode">
  <div class="input-group">
    <ng-select      
      class="auto-grow"
      [class.is-invalid]="isFormSubmitted && form.get(name).invalid"
      [(ngModel)]="selectedItems"
      (ngModelChange)=onChange($event)
      [loading]="loading"
      [closeOnSelect]="closeOnSelect"
      [hideSelected]="hideSelected"
      [tabIndex]="0"
      [multiple]="multiple"
      [disabled]="disabled"
      placeholder="{{'shared.placeholder.select_option' | translate}}">
      <ng-option *ngFor="let type of options" [value]="type.value || type">{{type.display || type | splitCamelCase}}</ng-option>
    </ng-select>
  </div>
</td>
