import { CitiesModel } from '@jameel/core/models/';

import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DropdownComponent,
      multi: true,
    },
  ],

})

export class DropdownComponent implements ControlValueAccessor {
  @Input() items: CitiesModel[];
  @Input() label: string;

  public value: string;
  public isDisabled: boolean;

  private onChange;

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(value: any): void {
    this.value = value;
  }

  selectItem(item: string): void {
    this.onChange(item);
    this.value = item;
  }
}
