<td>{{title}}</td>
<td *ngIf="!editMode">{{selectedItemsLabel || '-'}}</td>
<td *ngIf="editMode">
  <div class="input-group">
    <ng-select
      class="auto-grow"
      [class.is-invalid]="isFormSubmitted && form.get(name).invalid"
      [(ngModel)]="selectedItems"
      [loading]="loading"
      [multiple]="multiple"
      (ngModelChange)="getChangeEvent($event)"
      placeholder="{{'shared.placeholder.select_option' | translate}}"
    >
      <ng-option *ngFor="let type of options" [value]="type.value || type">{{type.display || type | splitCamelCase}}</ng-option>
    </ng-select>
  </div>
</td>
