import { LocalStorageService } from '@jameel/core/services/local-storage.service';

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import * as fromActions from 'app/authentication/store/auth.actions';
import { IAuthState } from 'app/authentication/store/auth.models';
import * as fromActionRegister from 'app/features/register/store/actions';
import { actionSettingsChangeLanguage } from '@jameel/core/settings/settings.actions';
import { Subscription } from 'rxjs';
import { selectSettingsLanguage } from '@jameel/core/settings/settings.selectors';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  providers: [NgbDropdownConfig],
  changeDetection: ChangeDetectionStrategy.OnPush,

})
export class NavbarComponent implements OnInit {
  public iconOnlyToggled = false;
  public sidebarToggled = false;
  userDetails: any; // TODO: Add interface for decoded JWT
  name: string;
  surname: string;
  
  lang: 'ar' | 'en';
  private sub = new Subscription();

  constructor(config: NgbDropdownConfig, private store: Store<IAuthState>,public localStorageService: LocalStorageService,private langStore: Store,
    private router: Router, private localStorage: LocalStorageService) {
    config.placement = 'bottom-right';
  }
  

  ngOnInit() {
    this.userDetails = this.localStorage.getDecodedJWT;

    for (const key in this.userDetails) {
      if (this.userDetails.hasOwnProperty(key) && key.includes('name')) {
        this.name = this.userDetails[key];
      }
    }

    this.toggleSidebar();

    this.sub.add(this.langStore.select(selectSettingsLanguage)
    .subscribe((result) => {
      this.lang = result as 'ar' | 'en' === 'en' ? 'ar' : 'en';
    }));
    
  }
 

  
  logout() {
    var priv =[];
    this.localStorageService.setUserPrivilages(JSON.stringify(priv));
    if (this.localStorage.isCaptain) {
      this.router.navigateByUrl('/auth/login');
    } else {
      this.router.navigateByUrl('/auth/admin');

    }
    this.localStorage.clearLocalStorage();

    this.store.dispatch(fromActions.authLogout());
    this.store.dispatch(fromActionRegister.actionResetStore());
  }

  // toggle sidebar in small devices
  toggleOffcanvas() {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  }

  // toggle sidebar
  toggleSidebar() {
    const body = document.querySelector('body');

    if ((!body.classList.contains('sidebar-toggle-display')) && (!body.classList.contains('sidebar-absolute'))) {
      this.iconOnlyToggled = !this.iconOnlyToggled;
      if (this.iconOnlyToggled) {
        body.classList.add('sidebar-icon-only');
      } else {
        body.classList.remove('sidebar-icon-only');
      }
    } else {
      this.sidebarToggled = !this.sidebarToggled;
      if (this.sidebarToggled) {
        body.classList.add('sidebar-hidden');
      } else {
        body.classList.remove('sidebar-hidden');
      }
    }
  }

  // toggle right sidebar
  toggleRightSidebar() {
    document.querySelector('#right-sidebar').classList.toggle('open');
  }

  changeLang() {
    if (this.lang === 'ar') {
      this.sub.add(this.store.dispatch(actionSettingsChangeLanguage({ language: 'ar' })));
      this.localStorage.setItem('lang', JSON.stringify({ lang: 'ar' }));
    } else {
      this.sub.add(this.store.dispatch(actionSettingsChangeLanguage({ language: 'en' })));
      this.localStorage.setItem('lang', JSON.stringify({ lang: 'en' }));
    }
  }
}
