import { NavigationEnum } from '../enums';
import { AuthenticationTokensResponseModelEnvelope, RefreshTokenRequestModel } from '../models/';
import { setAuthPage } from '../page/page.actions';
import { AuthService } from '../services/auth.service';
import { LocalStorageService } from '../services/local-storage.service';

import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor(private authService: AuthService,
    private store: Store,
    private localStorageService: LocalStorageService,
    private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.localStorageService.getUserInfo?.tokens?.token) {
      request = this.addToken(request, this.localStorageService.getUserInfo?.tokens?.token);
    }

    // return next.handle(request).pipe(catchError((error) => {
    //   debugger;
    //   if (error instanceof HttpErrorResponse && error.status === 401) {
    //     return this.handle401Error(request, next);
    //   }

    //   return throwError(error);
    // }));

    return next.handle(request).pipe(
      tap((event: any) => {
        if(event.status===200)
        {
          if(event.body?.privilages)
          {
            if(event.body?.privilages.length>0)
            {
              this.localStorageService.setUserPrivilages(JSON.stringify(event.body?.privilages));
            }else{
              this.localStorageService.setUserPrivilages(JSON.stringify([]));
            }
          }
        }
        else if (event instanceof HttpErrorResponse && event.status === 401) {
          return this.handle401Error(request, next);
        }
        else{
          return throwError(HttpErrorResponse);
        }
       
      }));

    return next.handle(request).pipe(catchError((error) => {
      debugger;
      if (error instanceof HttpErrorResponse && error.status === 401) {
        return this.handle401Error(request, next);
      }

      return throwError(error);
    }));
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService.refreshToken(new RefreshTokenRequestModel({
        refreshToken:
          this.localStorageService.getUserInfo.tokens.refreshToken,
      })).pipe(
        switchMap((token) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(token.data.token);
          this.updateLocalStorage(token);

          return next.handle(this.addToken(request, token.data.token));
        }),
        catchError((x) => {
          if (this.localStorageService.isAdmin) {
            this.router.navigateByUrl(NavigationEnum.LoginAdmin);
          } else {
            this.router.navigateByUrl(NavigationEnum.Default);
          }
          this.localStorageService.clearLocalStorage();
          this.store.dispatch(setAuthPage({ authPage: true }));

          return next.handle(request);
        }));
    }

    return this.refreshTokenSubject.pipe(
      filter((token) => token != null),
      take(1),
      switchMap((jwt) => next.handle(this.addToken(request, jwt))));
  }

  private updateLocalStorage(token: AuthenticationTokensResponseModelEnvelope) {
    const user = this.localStorageService.getUserInfo;

    const userCloned = { ...user };

    userCloned.tokens.refreshToken = token.data.refreshToken;
    userCloned.tokens.token = token.data.token;

    this.localStorageService.setUserInfo(userCloned);
  }
}
