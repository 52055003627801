
import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-form-time-picker',
  templateUrl: './form-time-picker.component.html',
  styleUrls: ['./form-time-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormTimePickerComponent implements OnChanges {
  @Input() editMode: boolean = false;
  @Input() isFormSubmitted: boolean = false;
  @Input() name: string;
  @Input() form: FormGroup;
  @Input() title: string = this.translateService.instant('shared.label.fill_title');
  time: { hour: number, minute: number };
  timeLabel: string;

  // defaultValue: NgbDate;
  constructor(private translateService: TranslateService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (!(changes.editMode?.previousValue !== changes.editMode?.currentValue)) {
      return;
    }
    const date: Date = this.form.controls[this.name]?.value;

    if (!date) {
      return;
    }
    this.time = {
      hour: date?.getHours(),
      minute: date?.getMinutes(),
    };

    this.updateTimeLabel();
  }

  updateTimeLabel() {
    const hour = this.time.hour > 12
      ? this.time.hour - 12
      : this.time.hour === 0
        ? 12
        : this.time.hour;

    let label = `${hour < 10 ? '0' : ''}${hour}`;

    label += `:${this.time.minute < 10 ? '0' : ''}${this.time.minute}`;
    label += ` ${this.time.hour > 11 ? 'PM' : 'AM'}`;
    this.timeLabel = label;
  }

  onTimeChange(time: { hour: number, minute: number }) {
    if (!time) { return; }

    const now = new Date();
    const date = new Date(now.getFullYear(), now.getMonth(), now.getDate(), time.hour, time.minute);
    const control = this.form.controls[this.name];

    control.patchValue(date);
    this.updateTimeLabel();
  }
}
