<td>
  {{title}}
</td>
<td *ngIf="!editMode">
  {{form.controls[name]?.value}}
</td>
<td *ngIf="editMode" [formGroup]="form">
  <div class="input-group">
    <ng-select class="auto-grow" placeholder="{{'shared.placeholder.select_license' | translate}}" [formControlName]="name"
      [class.is-invalid]="isFormSubmitted && form.get(name).invalid">
      <ng-option *ngFor="let type of driversTypes" [value]="type.value">{{type.display}}</ng-option>
    </ng-select>
  </div>
  <app-display-validation [errors]="errors" [additionalValidate]="isFormSubmitted"></app-display-validation>
</td>