import { IPageState, PAGE_STORE_NAME } from './page.model';

import { createFeatureSelector, createSelector } from '@ngrx/store';

export const featureSelector = createFeatureSelector<
  IPageState>(PAGE_STORE_NAME);

export const selectAuthPage = createSelector(
  featureSelector,
  (state) => state.authPage,
);

export const selectErrorPage = createSelector(
  featureSelector,
  (state) => state.errorPage,
);

export const selectIsFetching = createSelector(
  featureSelector,
  (state) => state.isFetching,
);
