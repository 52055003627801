import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-form-calendar-time',
  templateUrl: './form-calendar-time.component.html',
  styleUrls: ['./form-calendar-time.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormCalendarTimeComponent implements OnChanges {
  @Input() editMode: boolean = false;
  @Input() isFormSubmitted: boolean = false;
  @Input() name: string;
  @Input() form: FormGroup; 
  @Input() title: string = this.translateService.instant('shared.label.fill_title');
  @Input() birthday: boolean = false;
  today: NgbDateStruct = null;
  date: { year: number, month: number, day: number };
  get errors() {
    return this.form.controls[this.name].errors;
  }
  defaultValue: NgbDate;

  //time
  time: { hour: number, minute: number };
  timeLabel: string;

  constructor(private translateService: TranslateService) {
    const date = new Date();

    this.today = {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    // tslint:disable-next-line: early-exit
    if (changes.editMode?.previousValue !== changes.editMode?.currentValue && this.form.controls[this.name]?.value) {
      this.date = {
        year: this.form.controls[this.name]?.value?.getFullYear(),
        month: this.form.controls[this.name]?.value?.getMonth() + 1,
        day: this.form.controls[this.name]?.value?.getDate(),
      };
    }

    if (!(changes.editMode?.previousValue !== changes.editMode?.currentValue)) {
      return;
    }
    const date: Date = this.form.controls[this.name]?.value;

    if (!date) {
      return;
    }
    this.time = {
      hour: date?.getHours(),
      minute: date?.getMinutes(),
    };

    this.updateTimeLabel();
  }

  onDateSelect($event: NgbDate) {
    const date = new Date(Date.UTC($event.year, $event.month - 1, $event.day));

    const control = this.form.controls[this.name];

    control.patchValue(date);
    this.defaultValue = $event;
  }


    updateTimeLabel() {
    const hour = this.time.hour > 12
      ? this.time.hour - 12
      : this.time.hour === 0
        ? 12
        : this.time.hour;

    let label = `${hour < 10 ? '0' : ''}${hour}`;

    label += `:${this.time.minute < 10 ? '0' : ''}${this.time.minute}`;
    label += ` ${this.time.hour > 11 ? 'PM' : 'AM'}`;
    this.timeLabel = label;
  }

  onTimeChange(time: { hour: number, minute: number }) {
    if (!time) { return; }

    const now = new Date();
    const date = new Date(now.getFullYear(), now.getMonth(), now.getDate(), time.hour, time.minute);
    const control = this.form.controls[this.name];

    control.patchValue(date);
    this.updateTimeLabel();
  }
}
