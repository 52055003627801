import { selectAuthState } from '@jameel/core/core.state';

import { IAuthState } from './auth.models';

import { createSelector } from '@ngrx/store';

export const selectAuth = createSelector(
  selectAuthState,
  (state: IAuthState) => state,
);

export const selectCities = createSelector(
  selectAuthState,
  (state: IAuthState) => state.cities,
);
