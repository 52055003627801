<td>
  {{title}}:
</td>
<td *ngIf="!editMode">
  {{value}}
</td>
<td *ngIf="editMode" [formGroup]="form">
  <div class="input-group">
    <ng-select [formControlName]="name" class="auto-grow" placeholder="{{'home.label.select_city' | translate}}">
      <ng-option *ngFor="let city of cities" [value]="city.name">{{city.name}}</ng-option>
    </ng-select>
  </div>
</td>