import { NavigationEnum } from '../enums/';
import { LocalStorageService } from '../services/local-storage.service';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class HomePageGuard implements CanActivate {
  constructor(private localStorageService: LocalStorageService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): UrlTree {
    const isLoggedIn = this.localStorageService.getUserInfo?.isUserRegistered;

    if (isLoggedIn) {
      return this.localStorageService.isAdmin
        ? this.router.parseUrl('/' + NavigationEnum.Home)
        : this.router.parseUrl(NavigationEnum.RegisterDashboard);
    }

    return this.router.parseUrl(NavigationEnum.Login);
  }
}
