import { IErrorDto } from './errorDto.model';

export class EmptyDataEnvelope implements IEmptyDataEnvelope {
  data?: null;
  errors?: IErrorDto[] | null;
  readonly hasErrors?: boolean;
  constructor(data?: IEmptyDataEnvelope) {
    if (!data) {
      return;
    }
    for (const property in data) {
      if (data.hasOwnProperty(property)) {
        (<any>this)[property] = (<any>data)[property];
      }
    }
  }
}

export interface IEmptyDataEnvelope {
  data?: null;
  errors?: IErrorDto[] | null;
  hasErrors?: boolean;
}

// tslint:disable-next-line: no-empty-interface
export interface IEmptyData { }
