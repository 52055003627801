import { environment } from '../../../environments/environment';

import { HttpHeaders } from '@angular/common/http';

export abstract class AbstractBaseService {
  public readonly headers = new HttpHeaders({
    'Content-Type': 'application/json-patch+json',
    'Accept': 'text/plain',
  });

  protected baseUrl: string;

  constructor() {
    this.baseUrl = environment.API_BASE_URL;
  }
}
