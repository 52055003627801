import { ApplicationStatus, DocumentStatus, DrivingLicenseType, Gender, MinistryDriverAndVehicleRegistrationStatus, PaymentType, TrainingType } from '../enums/';

import { IDocumentResponseModel, IErrorDto, ITrainingCaptainResponseModel, IVehicleDetails } from './';
import { IAmountResponseModel, IGenericItemLinkResponse } from './captains.model';

export interface ICaptainBankInformationReadServiceModel {
  bankName?: string | null;
  bankAccountTitle?: string | null;
  bankNumber?: string | null;
  ibanNumber?: string | null;
  stcPayNumber?: string | null;
  status?: DocumentStatus;
}

export interface ICaptainDocumentsModel {
  avatarUrl: string;
  bankAccountInformationDocument: IDocumentResponseModel;
  drivingLicenseFrontDocument: IDocumentResponseModel;
  nationalIdDocument: IDocumentResponseModel;
  vehicles: IVehicleDetails[];
}

export class CaptainDocumentsResponseModel implements ICaptainDocumentsResponseModel {
  data?: ICaptainDocumentsModel;
  errors?: IErrorDto[] | null;
  readonly hasErrors?: boolean;

  constructor(data?: ICaptainDocumentsResponseModel) {
    if (!data) {
      return;
    }
    for (const property in data) {
      if (data.hasOwnProperty(property)) {
        (this)[property] = (data)[property];
      }
    }
  }
}

export interface ICaptainDocumentsResponseModel {
  data?: ICaptainDocumentsModel;
  errors?: IErrorDto[] | null;
  hasErrors?: boolean;
}

export interface ICaptainDrivingLicenseResponseModel {
  drivingLicenseType?: DrivingLicenseType;
  drivingLicenseNumber?: string | null;
  expirationDate?: number | null;
  status?: DocumentStatus;
}

export interface ICaptainNationalIdRequestModel {
  firstName: string;
  middleName: string;
  lastName: string;
  gender: Gender;
  birthday: number;
  nationalIdNumber: string;
  nationalIdExpirationDate: number;
  status: DocumentStatus;
  limoCompany?: string | null;
  externalId: string;
  companyId: number;
  companyName:string;
}

export interface ICaptainNationalIdResponseModel {
  gender?: Gender;
  birthday?: number | null;
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  nationalIdNumber?: string | null;
  limoCompany?: string | null;
  status?: DocumentStatus;
  nationalIdExpirationDate?: number | null;
}

export interface ICaptainDetailsResponseModel {
  applicationStatus?: ApplicationStatus;
  drivingLicenseFrontDocument?: IDocumentResponseModel;
  nationalIdDocument?: IDocumentResponseModel;
  bankAccountInformationDocument?: IDocumentResponseModel;
  bankAccountInformation?: ICaptainBankInformationReadServiceModel;
  drivingLicense?: ICaptainDrivingLicenseResponseModel;
  nationalId?: ICaptainNationalIdResponseModel;
  trainingCaptainDetails?: ITrainingCaptainResponseModel[] | null;
  hasAcceptedMinistryStatus: boolean;
  hasApprovedDrivingAuthorization: boolean;
  hasCompletedDocuments: boolean;
  hasCompletedTraining: boolean;
  hasUploadedVehiclePhotos: boolean;
  ministryStatusRejectionReasons?: string | null;
  externalId?: string;
  created?: number;
  updated?: number | null;
  ministryStatus: MinistryDriverAndVehicleRegistrationStatus;
  companyId: number;
  companyName:string;
}

export interface ICaptainBankInformationRequestModel {
  bankName: string;
  bankAccountTitle: string;
  bankNumber: string;
  ibanNumber: string;
  stcPayNumber: string;
  status: DocumentStatus;
  externalId: string;
}

export interface ICaptainDrivingLicense {
  drivingLicenseType: DrivingLicenseType;
  drivingLicenseNumber: string;
  expirationDate: number;
  status: DocumentStatus;
  externalId: string;
}

export interface ICaptainUpdateRequestModel {
  name: string;
  city: string;
  email: string;
  phoneNumber: string;
  externalId: string;
}

export interface ICaptainWalletListItemResponseModel {
  earningPeriod?: IGenericItemLinkResponse;
  rating?: number | null;
  acceptanceRate?: number;
  datePaid?: number | null;
  tripCount?: number;
  trips?: number;
  guarantee?: number;
  bonuses?: number;
  lastCycleAdjustment?: number;
  cashTripPayment?: number;
  cancelled?: number;
  totalBalance?: number;
}

export interface ICaptainEarningPeriodListItemResponseModel {
  date?: number;
  transactionType?: PaymentType;
  amount?: IAmountResponseModel;
  details?: string | null;
}

export interface ICaptainTrainingResponseModel {
  name: string;
  startDate: number;
  endDate: number;
  trainingType: TrainingType;
}

export interface ICaptainOnlineDataListResponseModel {
  Created: string;
  minutes: number;
  hours: number;
  StatusString: string;
}