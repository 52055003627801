import { NavigationEnum } from '@jameel/core/enums/';
import { ISidebarNavigation } from '@jameel/core/models';
import { LocalStorageService } from '@jameel/core/services/local-storage.service';

import { environment } from '../../../environments/environment';

import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router, RouterLinkActive } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromActions from 'app/authentication/store/auth.actions';
import { IAuthState } from 'app/authentication/store/auth.models';
import * as fromActionRegister from 'app/features/register/store/actions';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit,OnDestroy {
  public uiBasicCollapsed = false;
  public homeCollapsed = false;
  public samplePagesCollapsed = false;
  public navEnum = NavigationEnum;
  public navigation: ISidebarNavigation[] = null;

  @ViewChild('contactDetails') contactDetails: RouterLinkActive;
  @ViewChild('documents') documents: RouterLinkActive;
  @ViewChild('register') register: RouterLinkActive;
  @ViewChild('zendeskLink') zendeskLink: ElementRef<HTMLElement>;

  public isAdmin = false;
  public isLimited = false;
  public isLimitedCaptain = false;
  public isLimitedJameelEye = false;
  public isLimitedTrips = false;
  public isLimitedCustomer = false;
  public isLimitedFinances = false;
  public isLimitedApplications = false;
  public isLimitedNotifications = false;

  public isOperation_Op= false;
  public isOperation_Super= false;
  public isMarketing_Op= false;
  public isMarketing_Super= false;
  public isIt_Op= false;
  public isIt_Super= false;
  public isFinance_Op= false;
  public isFinance_Super= false;
  
  production: boolean = environment.env_name === 'prod';
  appVersion: string = environment.APP_VERSION;
  private subscription = new Subscription();
  constructor(public localStorageService: LocalStorageService,
    private store: Store<IAuthState>,
    private router: Router,
    private localStorage: LocalStorageService) {
    this.isAdmin = this.localStorageService.isAdmin;
    // this.isLimited = this.localStorageService.isLimited;
    // this.isLimitedCaptain = this.localStorageService.isLimitedCaptain;
    // this.isLimitedJameelEye = this.localStorageService.isLimitedJameelEye;
    // this.isLimitedTrips = this.localStorageService.isLimitedTrips;
    // this.isLimitedCustomer = this.localStorageService.isLimitedCustomer;
    // this.isLimitedFinances = this.localStorageService.isLimitedFinances;
    // this.isLimitedApplications = this.localStorageService.isLimitedApplications;
    // this.isLimitedNotifications = this.localStorageService.isLimitedNotifications;

    this.isOperation_Op = this.localStorageService.isOperation_Op;
    this.isOperation_Super = this.localStorageService.isOperation_Super;
    this.isMarketing_Op = this.localStorageService.isMarketing_Op;
    this.isMarketing_Super = this.localStorageService.isMarketing_Super;
    this.isIt_Op = this.localStorageService.isIt_Op;
    this.isIt_Super = this.localStorageService.isIt_Super;
    this.isFinance_Op = this.localStorageService.isFinance_Op;
    this.isFinance_Super = this.localStorageService.isFinance_Super;

    this.navigation = [{
      children: [],
      expandArrowstate: false,
      name: NavigationEnum.Home,
      isLimited: true,
      options: {
        iconClasses: 'mdi-home',
      },
      routerLink: this.navEnum.Home,
      translate: 'route.home',
      visible:true,
      permission:'home',
    },
    {
      children: [],
      expandArrowstate: false,
      name: NavigationEnum.Users,
      isLimited: false,
      options: {
        iconClasses: 'mdi-account',
      },
      routerLink: this.navEnum.Home + '/' + this.navEnum.Users,
      translate: 'route.users',
      visible:this.UserHaveAccess('canViewUsersPage'),
      permission:'canViewUsersPage',
    },
    {
      children: [],
      expandArrowstate: false,
      name: NavigationEnum.Applications,
      isLimited: true,
      // isLimitedApplications : true,
      options: {
        iconClasses: 'mdi-file-document',
      },
      routerLink: this.navEnum.Home + '/' + this.navEnum.Applications,
      translate: 'route.applications',
      visible:this.UserHaveAccess('CanViewApplicationPage'),
      permission:'CanViewApplicationPage',
    },
    {
      children: [],
      expandArrowstate: false,
      name: NavigationEnum.AdminNotifications,
      isLimited: true,
      // isLimitedNotifications : true,
      options: {
        iconClasses: 'mdi-calendar-check',
      },
      routerLink: this.navEnum.Home + '/' + this.navEnum.AdminNotifications,
      translate: 'route.applications',
      visible:this.UserHaveAccess('CanViewNotificationPage'),
      permission:'CanViewNotificationPage',
    },
    {
      children: [],
      expandArrowstate: false,
      name: NavigationEnum.Captains,
      isLimited: true,
      // isCaptains: true,
      options: {
        iconClasses: 'mdi-account-star',
      },
      routerLink: '/' + this.navEnum.Captains,
      translate: 'route.captains',
      visible:this.UserHaveAccess('CanViewCaptainPage'),
      permission:'CanViewCaptainPage',
    },
    {
      children: [],
      expandArrowstate: false,
      name: NavigationEnum.JameelEye,
      isLimited: true,
      // isJameelEye: true,
      options: {
        iconClasses: 'mdi-eye',
      },
      routerLink: '/' + this.navEnum.JameelEye,
      translate: 'route.jameel_eye',
      visible:this.UserHaveAccess('CanViewJameelEyePage'),
      permission:'CanViewJameelEyePage',
    },
    {
      expandArrowstate: false,
      name: NavigationEnum.Finances,
      routerLink: '/' + this.navEnum.Finances,
      translate: 'route.finances',
      children: [],
      isLimited: true,
      // isFinance: true,
      options: {
        iconClasses: 'mdi-square-inc-cash',
      },
      visible:this.UserHaveAccess('CanViewFinancePage'),
      permission:'CanViewFinancePage',
    },
    {
      children: [],
      expandArrowstate: false,
      name: NavigationEnum.Customers,
      isLimited: true,
      // isCustomers: true,
      options: {
        iconClasses: 'mdi-human',
      },
      routerLink: '/' + this.navEnum.Customers + '/' + NavigationEnum.CustomersList,
      translate: 'route.customers',
      visible:this.UserHaveAccess('CanViewCustomerPage'),
      permission:'CanViewCustomerPage',
    },
    {
      children: [],
      expandArrowstate: false,
      name: NavigationEnum.Trips,
      isLimited: true,
      // isTrips: true,
      options: {
        iconClasses: 'mdi-car',
      },
      routerLink: '/' + this.navEnum.Trips,
      translate: 'route.trips',
      visible:this.UserHaveAccess('CanViewTripPage'),
      permission:'CanViewTripPage',
    }]
    // .filter((navigation) => (
    //   (this.isLimitedCaptain && navigation.isCaptains) 
    //   || (this.isLimitedTrips && navigation.isTrips) 
    //   || (this.isLimitedJameelEye && navigation.isJameelEye) 
    //   || (this.isLimitedCustomer && navigation.isCustomers) 
    //   || (this.isLimitedFinances && navigation.isFinance) 
    //   || (this.isLimitedApplications && navigation.isLimitedApplications) 
    //   || (this.isLimitedNotifications && navigation.isLimitedNotifications) 
    //   || (this.isLimited && navigation.isLimited) 
    //   || this.isAdmin));
  }

  ngOnInit() {
    // TODO: Refactor
    const body = document.querySelector('body');

    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    document.querySelectorAll('.sidebar .nav-item').forEach((el) => {
      el.addEventListener('mouseover', () => {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', () => {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });

    this.subscription.add(this.localStorageService.relolad.subscribe((value) => {
      this.navigation.forEach(element => {
        if(element.permission!='home')
        {
          element.visible = this.UserHaveAccess(element.permission);
        }
      });
    }));

  }

  logout() {
    if (this.localStorage.isCaptain) {
      this.router.navigateByUrl('/auth/login');
    } else {
      this.router.navigateByUrl('/auth/admin');
    }
    this.localStorage.clearLocalStorage();

    this.store.dispatch(fromActions.authLogout());
    this.store.dispatch(fromActionRegister.actionResetStore());
  }

  goToZendesk() {
    this.zendeskLink.nativeElement.click();
  }

  UserHaveAccess(privialge): boolean {
    var access = false;
    var userAccess = this.localStorageService.getUserPrivilages;
    if (
      userAccess.filter((t) => t.toLowerCase() == "all")
        .length > 0
    )
    {
      access = true;
    }
    else{
    if (!this.isAdmin) {
      if (userAccess) {
        if (
          userAccess.filter((t) => t.toLowerCase() == privialge.toLowerCase())
            .length > 0
        ) {
          access = true;
        }
        else{
          access = false;
        }
      }
    }
    else if(this.isAdmin)
    {
      access = true;
    }
  }
    return access;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
