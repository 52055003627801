import { CarDocumentType } from '@jameel/core/enums/';
import { IVehicleDetails } from '@jameel/core/models/';

import { createAction, props } from '@ngrx/store';

export const actionFixVehicleDocuments = createAction('[Fix vehicle documents] Fix vehicle documents',
  props<{ file: FormData, documentType: CarDocumentType, vehicleExternalId: string }>());

export const actionFixVehicleDocumentsSuccess = createAction(
  '[Fix vehicle documents] Fix vehicle documents success', props<{ vehicle: IVehicleDetails }>(),
);

export const actionFixVehicleDocumentsFailure = createAction(
  '[Fix vehicle documents] Fix vehicle documents failure', props<{ error: string }>(),
);

// Init data
export const actionInitVehicleDocuments = createAction(
  '[Fix vehicle documents] Init vehicle documents', props<{ id: string }>(),
);

export const actionInitVehicleDocumentsSuccess = createAction(
  '[Fix vehicle documents] Init vehicle documents success', props<{ data: IVehicleDetails }>(),
);

export const actionInitVehicleDocumentsFailure = createAction(
  '[Fix vehicle documents] Init vehicle documents failure', props<{ error: string }>(),
);
