import { CheckCarStatus, DocumentStatus, PlateType, VehicleOwnershipType, VehiclePhotoType, VehicleType } from '../enums/';

import { IDocumentResponseModel } from './document.model';
import { IFilterOptions, IPageInfo } from './filters.model';
import { IGenericItemActionResponse, IGenericItemExternalLinkResponse } from './genericList.model';

export interface IVehicleWithDocumentListItemResponseModel {
  externalId?: string;
  licensePlate?: string | null;
  type?: VehicleType;
  model?: string | null;
  color?: string | null;
  vehicleInsurance?: IGenericItemExternalLinkResponse;
  drivingAuthorization?: IGenericItemExternalLinkResponse;
  vehicleRegistration?: IGenericItemExternalLinkResponse;
  make?: string | null;
  isVerified?: boolean;
  actions?: IGenericItemActionResponse[] | null;
}

export interface IVehicleDetailsRequest {
  drivingAuthorizationDocument?: FormData;
  insuranceDocument?: FormData;
  registrationDocument?: FormData;
}

export interface IVehicleDetails {
  drivingAuthorizationDocument?: IDocumentResponseModel;
  externalId?: string;
  insuranceDocument?: IDocumentResponseModel;
  registrationDocument?: IDocumentResponseModel;
}

export interface IVehicleDocumentsSummaryResponseModel {
  drivingAuthorizationDocument?: IDocumentResponseModel;
  insuranceDocument?: IDocumentResponseModel;
  registrationDocument?: IDocumentResponseModel;
  externalId?: string;
  created?: number;
  updated?: number | null;
}

export interface IVehicleResponsePaginated {
  pageInfo?: IPageInfo;
  list?: IVehicleResponseModel[] | null;
  filterColumns?: IFilterOptions[] | null;
}

export interface IVehicleResponseModel {
  color?: string;
  created?: number;
  drivingAuthorizationDocument?: IDocumentResponseModel;
  externalId?: string;
  insuranceDocument?: IDocumentResponseModel;
  isVerified?: boolean;
  licensePlate?: string;
  make?: string;
  model?: string;
  registrationDocument?: IDocumentResponseModel;
  rideTypes?: IRideTypeResponseModel[];
  type?: VehicleType;
  updated?: number;
  vehiclePhotos?: IVehiclePhotos[];
}

export interface IVehiclePhotos {
  pictureType?: VehiclePhotoType;
  fileUrl?: string;
}

export interface IFixCarForm {
  carInsurance: IDocumentResponseModel;
  carRegistration: IDocumentResponseModel;
  drivingAuthorization: IDocumentResponseModel;
}

export class IRideTypeResponseModel {
  description?: string | null;
  name?: string | null;
  externalId?: string;
  created?: number;
  updated?: number | null;
}

export interface IRideTypeResponseModel {
  description?: string | null;
  name?: string | null;
  externalId?: string;
  created?: number;
  updated?: number | null;
}

export interface IVehiclePhotoResponseModel {
  pictureType?: VehiclePhotoType;
  fileUrl?: string | null;
}

export interface IVehicleRegistrationRequestModel {
  make: string;
  model: string;
  year: number;
  color: string;
  plateLetterLeft: string;
  plateLetterMiddle: string;
  plateLetterRight: string;
  plateNumber: string;
  plateType: PlateType;
  vin: string;
  ownership: VehicleOwnershipType;
  checkCar: CheckCarStatus;
  registrationSerialNumber: string;
  registrationExpirationDate: number;
  registrationStatus: DocumentStatus;
  registrationRejectionReasonExternalId?: string | null;
  externalId: string;
}

export interface IVehicleRegistrationResponse {
  make?: string | null;
  model?: string | null;
  year?: number | null;
  color?: string | null;
  plateLetterLeft?: string | null;
  plateLetterMiddle?: string | null;
  plateLetterRight?: string | null;
  plateNumber?: string | null;
  plateType?: PlateType;
  vin?: string | null;
  ownership?: VehicleOwnershipType;
  checkCar?: CheckCarStatus;
  registrationSerialNumber?: string | null;
  registrationExpirationDate?: number | null;
  registrationStatus?: DocumentStatus;
  registrationRejectionReason?: string | null;
  externalId?: string;
  created?: number;
  updated?: number | null;
}

export interface IVehicleInsuranceResponse {
  insuranceCompany?: string | null;
  insuranceStatus?: DocumentStatus;
  insuranceExpirationDate?: number | null;
  externalId?: string;
  created?: number;
  updated?: number | null;
}

export interface IVehicleInsuranceRequest {
  insuranceCompany: string;
  insuranceStatus: DocumentStatus;
  insuranceExpirationDate: number;
  externalId?: string;
}

export interface IVehicleDetailsResponse {
  registration?: IVehicleRegistrationResponse;
  insurance?: IVehicleInsuranceResponse;
  color?: string | null;
  licensePlate?: string | null;
  make?: string | null;
  model?: string | null;
  type?: VehicleType;
  isVerified?: boolean;
  hasPhotosUploaded?: boolean;
  rideTypes?: IRideTypeResponseModel[] | null;
  drivingAuthorizationDocument?: IDocumentResponseModel;
  insuranceDocument?: IDocumentResponseModel;
  registrationDocument?: IDocumentResponseModel;
  vehiclePhotos?: IVehiclePhotoResponseModel[] | null;
  externalId?: string;
  created?: number;
  updated?: number | null;
}

export interface IRideTypeResponseModel {
  description?: string | null;
  name?: string | null;
  externalId?: string;
  created?: number;
  updated?: number | null;
}
