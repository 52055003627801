import {
  actionSettingsChangeLanguage,
} from './settings.actions';
import { ISettingsState } from './settings.model';

import { Action, createReducer, on } from '@ngrx/store';

export const initialState: ISettingsState = {
  language: 'ar',
};

const reducer = createReducer(
  initialState,
  on(
    actionSettingsChangeLanguage,
    (state, { language }) => ({ ...state, language }),
  ),
);

export const settingsReducer = (
  state: ISettingsState | undefined,
  action: Action,
) => reducer(state, action);
