// toast.service.ts
import { Injectable, TemplateRef } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  toasts: any[] = [];

  // Callback method to remove Toast DOM element from view
  remove(toast: any) {
    this.toasts = this.toasts.filter((t) => t !== toast);
  }

  clean() {
    this.toasts = [];
  }

  showSuccess(text: string, delay: number = 5000) {
    this.show(text, { delay, classname: 'bg-success text-light' });
  }

  showWarning(text: string, delay: number = 5000) {
    this.show(text, { delay, classname: 'bg-warning text-light' });
  }

  showError(text: string, delay: number = 4000) {
    this.show(text, { delay, classname: 'bg-gradient-primary text-light toast show text-light' });
  }

  // Push new Toasts to array with content and options
  private show(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    this.toasts.push({ textOrTpl, ...options });
  }
}
