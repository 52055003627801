import { AbstractBaseService } from '@jameel/core/abstraction/base-service';
import {
  CitiesModel, CitiesResponseModelEnvelope, EnvelopeGenericModel, ICaptainBankInformationRequestModel,
  ICaptainDrivingLicenseResponseModel, ICaptainNationalIdRequestModel,
  IEmptyData, ILookupResponseModel, IMinistryResponse, IMissingDocumentsResponseModel, isValidIdModel, IUserProfileResponseModel, IVehicleInsuranceRequest,
  IVehicleInsuranceResponse, IVehicleRegistrationRequestModel, IVehicleRegistrationResponse,
} from '@jameel/core/models/';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AdminProfileService extends AbstractBaseService {
  constructor(private http: HttpClient) {
    super();
  }

  getUserProfile(externalId: string): Observable<IUserProfileResponseModel> {
    let url = this.baseUrl + '/admin/profile/selected?';
    

    if (externalId === undefined || externalId === null) {
      throw new Error('The parameter \'externalId\' must be defined and cannot be null.');
    }

    url += 'externalId=' + encodeURIComponent('' + externalId) + '&';
    url = url.replace(/[?&]$/, '');

    return this.http.get<EnvelopeGenericModel<IUserProfileResponseModel>>(url).pipe(map((userProfile) => userProfile.data));
  }

  fetchRejectedReasons(): Observable<ILookupResponseModel[]> {
    let url = this.baseUrl + '/user/lookup/rejected-reasons';

    url = url.replace(/[?&]$/, '');

    return this.http.get<EnvelopeGenericModel<ILookupResponseModel[]>>(url).pipe(map((data) => data.data));
  }

  approve(documentExternalId: string): Observable<IEmptyData> {
    let url = this.baseUrl + '/admin/document/approve';

    url = url.replace(/[?&]$/, '');

    return this.http.post<EnvelopeGenericModel<IEmptyData>>(url, { documentExternalId });
  }

  reject(documentExternalId: string, rejectReasonExternalId: string, notes: string): Observable<IEmptyData> {
    let url = this.baseUrl + '/admin/document/reject';

    url = url.replace(/[?&]$/, '');

    return this.http.post<EnvelopeGenericModel<IEmptyData>>(url, { documentExternalId, rejectReasonExternalId, notes });
  }

  saveNationalId(captainNationalIdRequestModel: ICaptainNationalIdRequestModel): Observable<IUserProfileResponseModel> {
    let url = this.baseUrl + '/admin/profile/national-id';

    url = url.replace(/[?&]$/, '');

    return this.http.put<EnvelopeGenericModel<IUserProfileResponseModel>>(url, captainNationalIdRequestModel)
      .pipe(map((userProfile) => userProfile.data));
  }

  checkIsValidID(natId: string): Observable<isValidIdModel> {
    let url = this.baseUrl + '/admin/profile/isValid-natId/' + natId;

    url = url.replace(/[?&]$/, '');

    return this.http.get<EnvelopeGenericModel<isValidIdModel>>(url).pipe(map((x) => x.data));
  }

  
  saveDrivingLicense(captainDrivingLicense: ICaptainDrivingLicenseResponseModel): Observable<IUserProfileResponseModel> {
    let url = this.baseUrl + '/admin/profile/driving-license-id';

    url = url.replace(/[?&]$/, '');

    return this.http.put<EnvelopeGenericModel<IUserProfileResponseModel>>(url, captainDrivingLicense)
      .pipe(map((userProfile) => userProfile.data));
  }

  saveBankInformation(captainDrivingLicense: ICaptainBankInformationRequestModel): Observable<IUserProfileResponseModel> {
    let url = this.baseUrl + '/admin/profile/bank-account-information';

    url = url.replace(/[?&]$/, '');

    return this.http.put<EnvelopeGenericModel<IUserProfileResponseModel>>(url, captainDrivingLicense)
      .pipe(map((userProfile) => userProfile.data));
  }

  loadCities(): Observable<CitiesModel[]> {
    let url = this.baseUrl + '/user/lookup/cities';

    url = url.replace(/[?&]$/, '');

    return this.http.get<CitiesResponseModelEnvelope>(url).pipe(map((x) => x.data));
  }

  public getRideTypes(): Observable<ILookupResponseModel[]> {
    let url = this.baseUrl + '/user/lookup/ride-types';

    url = url.replace(/[?&]$/, '');

    return this.http.get<EnvelopeGenericModel<ILookupResponseModel[]>>(url).pipe(map((data) => data.data));
  }

  changeCity(city: string, externalId: string): Observable<IUserProfileResponseModel> {
    let url = this.baseUrl + '/admin/profile/contact/city';

    url = url.replace(/[?&]$/, '');

    return this.http.put<EnvelopeGenericModel<IUserProfileResponseModel>>(url,
      { externalId, cityExternalId: city }).pipe(map((response) => response.data));
  }

  changeEmail(email: string, externalId: string): Observable<IUserProfileResponseModel> {
    let url = this.baseUrl + '/admin/profile/contact/email';

    url = url.replace(/[?&]$/, '');

    return this.http.put<EnvelopeGenericModel<IUserProfileResponseModel>>(url,
      { email, externalId }).pipe(map((response) => response.data));
  }

  changePhoneNumber(phoneNumber: string, externalId: string): Observable<IUserProfileResponseModel> {
    let url = this.baseUrl + '/admin/profile/contact/phone-number';

    url = url.replace(/[?&]$/, '');

    return this.http.put<EnvelopeGenericModel<IUserProfileResponseModel>>(url,
      { phoneNumber, externalId }).pipe(map((response) => response.data));
  }

  selectedVehicle(externalId: string): Observable<IUserProfileResponseModel> {
    let url = this.baseUrl + '/admin/vehicle/selected?';

    if (externalId === undefined || externalId === null) {
      throw new Error('The parameter \'externalId\' must be defined and cannot be null.');
    }
    url += 'externalId=' + encodeURIComponent('' + externalId) + '&';

    url = url.replace(/[?&]$/, '');

    return this.http.get<EnvelopeGenericModel<IUserProfileResponseModel>>(url).pipe(map((response) => response.data));
  }

  updateVehicle(vehicleRegistrationRequestModel: IVehicleRegistrationRequestModel): Observable<IVehicleRegistrationResponse> {
    let url = this.baseUrl + '/admin/vehicle/registration';

    url = url.replace(/[?&]$/, '');

    return this.http.put<EnvelopeGenericModel<IVehicleRegistrationResponse>>(url, vehicleRegistrationRequestModel)
      .pipe(map((response) => response.data));
  }

  updateInsuranceVehicle(vehicleRegistrationRequestModel: IVehicleInsuranceRequest): Observable<IVehicleInsuranceResponse> {
    let url = this.baseUrl + '/admin/vehicle/insurance';

    url = url.replace(/[?&]$/, '');

    return this.http.put<EnvelopeGenericModel<IVehicleInsuranceResponse>>(url, vehicleRegistrationRequestModel)
      .pipe(map((response) => response.data));
  }

  getResponseMinistryCaptain(externalId: string) {
    let url = this.baseUrl + '/admin/ministry/captain/{externalId}';

    if (externalId === undefined || externalId === null) {
      throw new Error('The parameter \'externalId\' must be defined.');
    }
    url = url.replace('{externalId}', encodeURIComponent('' + externalId));
    url = url.replace(/[?&]$/, '');

    return this.http.post<EnvelopeGenericModel<IMinistryResponse>>(url, null)
      .pipe(map((response) => response.data));
  }

  getMissingDocumentsStatus( externalId: string): Observable<EnvelopeGenericModel<IMissingDocumentsResponseModel[]>> {
    let url = this.baseUrl + '/admin/Application/get-missing-documents-status';

    url = url.replace(/[?&]$/, '');

    return this.http.post<EnvelopeGenericModel<IMissingDocumentsResponseModel[]>>(url,{ externalId });
  }
}
