import { FilterKind, TableValueType } from '@jameel/core/enums';
import { debounce } from '@jameel/core/helpers/debounce';
import {
  IDateFilter,
  IEnumFilter,
  IErrorDto,
  IFilterOptions,
  IFilterRequest,
  IGenericListMetaData,
  IGenericListResponseAction,
  IGenericListResponseLink,
  IListParams,
  IPageInfo,
  IQuantityFilter,
  ISingleFilterRequest,
  IValueFilter,
} from '@jameel/core/models';

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-base-table',
  templateUrl: './base-table.component.html',
  styleUrls: ['./base-table.component.scss'],
})
export class BaseTableComponent {
  @Input() list$: Observable<any[]>;
  @Input() pageInfo$: Observable<IPageInfo>;
  @Input() totalPages$: Observable<number>;
  @Input() errors$: Observable<IErrorDto[]>;

  @Input() tableTitle: string;
  @Input() filterColumns: IFilterOptions[];
  @Input() headers: Observable<IGenericListMetaData[]>;
  @Input() params: IListParams;
  @Input() activeFilters: IFilterRequest;
  @Input() translatePrefix: string;
  @Input() isListLoaded: boolean;
  @Input() hideFiltersAndSearch: boolean = false;
  @Input() hideSearch: boolean = false;
  @Input() fullPageTable: boolean = true;
  @Input() showDeleteButton: boolean = false;

  @Output() readonly actionClicked = new EventEmitter<IGenericListResponseAction>();
  @Output() readonly checkActionClicked = new EventEmitter<any>();
  @Output() readonly linkClicked = new EventEmitter<IGenericListResponseLink>();
  @Output() readonly paramsChanged = new EventEmitter<IListParams>();
  @Output() readonly filtersChanged = new EventEmitter<IFilterRequest>();

  readonly valueTypes = TableValueType;
  readonly paginationOptions = [
    { name: `10 ${this.translateService.instant('home.label.items')}`, value: 10 },
    { name: `20 ${this.translateService.instant('home.label.items')}`, value: 20 },
    { name: `50 ${this.translateService.instant('home.label.items')}`, value: 50 },
    { name: `100 ${this.translateService.instant('home.label.items')}`, value: 100 },
    { name: `200 ${this.translateService.instant('home.label.items')}`, value: 200 },
  ];

  setSearchQuery = debounce((query: string) => {
    this.setQuery(query);
  }, 200);

  @Input() dateRangeFilterName?: string;

  constructor(public translateService: TranslateService) { }

  redirectToLink(item: IGenericListResponseLink): void {
    this.linkClicked.emit(item);
  }

  handleAction(item: IGenericListResponseAction): void {
    this.actionClicked.emit(item);
  }

  handleCheckAction(item: any): void {
    this.checkActionClicked.emit(item);
  }
  

  setQuery(query: string): void {
    if (query !== this.params.x_query) {
      this.params.x_pageNumber = 1;
    }
    this.params.x_query = query;
    this.setParams();
  }

  setParams(): void {
    this.paramsChanged.emit(this.params);
  }

  setPageNumber(pageNumber: number): void {
    this.params.x_pageNumber = pageNumber;
    this.setParams();
  }

  sortTableByColumn(columnName: string): void {
    if (this.params.x_order === columnName) {
      this.params.x_desc = !this.params.x_desc;
    }
    this.params.x_order = columnName;
    this.setParams();
  }

  onFiltersChange(changedFilter: ISingleFilterRequest): void {
    let filtersArray: (IDateFilter | IEnumFilter | IQuantityFilter | IValueFilter)[];

    switch (changedFilter.type) {
      case FilterKind.Enum: {
        filtersArray = this.activeFilters.enumFilters;
        break;
      }

      case FilterKind.Int: {
        filtersArray = this.activeFilters.quantityFilters;
        break;
      }
      case FilterKind.String: {
        filtersArray = this.activeFilters.valueFilters;
        break;
      }
      case FilterKind.Date:
      case FilterKind.DateRange: {
        filtersArray = this.activeFilters.dateFilters;
        break;
      }
      default: {
        break;
      }
    }

    const filterIdx = filtersArray
      .findIndex((filter: IDateFilter | IEnumFilter
        | IQuantityFilter | IValueFilter) => filter.fieldName === changedFilter.filter.fieldName);

    if (filterIdx === -1) {
      filtersArray.push(changedFilter.filter);
    } else if (changedFilter.clearFilter) {
      filtersArray.splice(filterIdx, 1);
    } else {
      filtersArray[filterIdx] = changedFilter.filter;
    }
    this.params.x_pageNumber = 1
    this.filtersChanged.emit(this.activeFilters);
  }

  getSortingClass(columnName: string): string {
    let className = '';

    if (this.params.x_order === columnName) {
      className = 'active';
      if (this.params.x_desc) {
        className = 'active descending';
      }
    }

    return className;
  }
}
