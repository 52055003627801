import { ITranslateEnum } from '@jameel/core/models/translateEnumModel.model';

import { Component, Output, Input, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

/**
 * @param multiple - Set to true to enable multiple selections
 * When providing multiple selections, set them in FormGroup as an object { items: string[] }
 */
@Component({
  selector: 'app-form-select-change',
  templateUrl: './form-select-change.component.html',
  styleUrls: ['./form-select-change.component.scss'],
})
export class FormSelectChangeComponent implements OnChanges {
  @Input() editMode: boolean = false;
  @Input() isFormSubmitted: boolean = false;
  @Input() name: string;
  @Input() form: FormGroup;
  @Input() title: string = this.translateService.instant('shared.label.fill_title');
  @Input() type: string = 'text';
  @Input() options: string[] | ITranslateEnum[] = [];
  @Input() loading: boolean = false;
  @Input() multiple: boolean = false;
  @Output() changeEvent = new EventEmitter<boolean>();

  getChangeEvent(value: any) {
    this.changeEvent.emit(value);
    
  }

  selectedItems: string | string[];
  selectedItemsLabel: string;
  get errors() {
    return this.form?.controls[this.name].errors;
  }
  constructor(private translateService: TranslateService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.editMode?.previousValue === changes.editMode?.currentValue) {
      return;
    }

    const formValue = this.form?.controls[this.name]?.value;

    if (formValue) {
      this.setSelectItems(formValue);
      this.updateSelectedItemsLabel();
    }
  }

  /**
   * @param value - Select items received from FormGroup
   *
   * When @param multiple is set to true, <ng-select> is expecting to get
   * and is returning string[] instead of single string.
   *
   * NOTE:
   * On the first initialization with @param multiple, FormGroup will send
   * an object { list: string[] } due to it's initialization process.
   * After first change, next incoming values will be a string[].
   */
  setSelectItems(value: { list: string[] } | string[] | string) {
    let newValues: string | string[];

    if (this.multiple) {
      if (Array.isArray(value)) {
        newValues = value;
      } else if (typeof value === 'object') {
        if (value.list && Array.isArray(value.list)) {
          newValues = value.list;
        } else { newValues = []; }
      } else { newValues = [value]; }
    } else if (typeof value === 'object') {
      newValues = '';
    // tslint:disable-next-line: no-collapsible-if
    } else {
      if (typeof value === 'object') { newValues = ''; } else { newValues = value; }
    }

    this.selectedItems = newValues;
  }

  updateSelectedItemsLabel() {
    let newLabel = '';

    if (Array.isArray(this.selectedItems)) {
      this.selectedItems.forEach((item) => newLabel += `${item} `);
    } else { newLabel = this.selectedItems; }

    this.selectedItemsLabel = newLabel;
  }

  onChange(options: string | string[]) {
    const control = this.form.controls[this.name];

    control.patchValue(options);
    this.updateSelectedItemsLabel();
  }

}
