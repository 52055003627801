
import { NavigationEnum } from '@jameel/core/enums/';
import { AuthService } from '@jameel/core/services/auth.service';
import { LocalStorageService } from '@jameel/core/services/local-storage.service';
import { ToastService } from '@jameel/core/services/toast.service';

import * as authActions from './auth.actions';
import { selectCities } from './auth.selectors';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { environment } from 'environments/environment';
import { of } from 'rxjs';
import { catchError, filter, map, pluck, switchMap, tap, withLatestFrom } from 'rxjs/operators';

const PHONE_NUMBER = 'PHONE.NUMBER';

@Injectable()
export class AuthEffects {
  login$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.authLogin),
        tap((userLoginModel) => this.localStorageService.setItem(PHONE_NUMBER, userLoginModel.user.phoneNumber)),
        switchMap((userLoginModel) => this.authService.loginCaptain(userLoginModel.user)),
        tap((userLoginResponseModel) => {
          if (!userLoginResponseModel.hasErrors) {
            this.localStorageService.setCaptainOtp(userLoginResponseModel.data);
            this.router.navigateByUrl(NavigationEnum.AuthConfirmOtp);
          } else {
            this.toastService.showError('Something went wrong. Check your phone number');
          }
        }),
      ),
    { dispatch: false },
  );

  loginZendesk$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.authLoginZendesk),
        tap((userLoginModel) => this.localStorageService.setItem(PHONE_NUMBER, userLoginModel.user.phoneNumber)),
        switchMap((userLoginModel) => this.authService.loginCaptain(userLoginModel.user)),
        tap((userLoginResponseModel) => {
          this.localStorageService.setCaptainOtp(userLoginResponseModel.data);
          this.router.navigate([NavigationEnum.AuthConfirmOtp], { queryParams: { isZendesk: true } });
        }),
      ),
    { dispatch: false },
  );

  zendeskToken$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.authZendeskToken),
        pluck('jwt'),
        switchMap((jwt) => this.authService.zendeskToken(jwt)),
        map(({ data }) => authActions.authZendeskTokenSuccessful({ token: data.zendeskCaptainToken })),
      ),
  );

  zendeskTokenRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.authZendeskTokenSuccessful),
        pluck('token'),
        tap((token) => {
          window.location.href = `${environment.ZENDESK_URL}/access/jwt?jwt=${token}`;
        }),
      ), { dispatch: false },
  );

  confirmOtp$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.authConfirmOtp),
        switchMap((otpRequestModel) =>
          this.authService.confirmOtp(otpRequestModel.otpRequest).pipe(
            map((result) => authActions.authConfirmOtpSuccess({
              authenticationResponseModel: result.data,
              isZendesk: otpRequestModel.otpRequest.isZendesk,
            })),
            catchError((error) => of(authActions.authConfirmOtpFailure(error))),
          )),
      ),
  );

  confirmOtpSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.authConfirmOtpSuccess),
        tap((authenticationResponseModel) => {
          if (authenticationResponseModel.isZendesk) {
            this.router.navigate(['/auth/zendesk-redirect'],
              { queryParams: { jwt: authenticationResponseModel.authenticationResponseModel.tokens.token } });

            return;
          }

          if (authenticationResponseModel?.authenticationResponseModel) {
            this.localStorageService.setUserInfo(authenticationResponseModel.authenticationResponseModel);
          }
          if (!authenticationResponseModel?.authenticationResponseModel?.isUserRegistered) {
            this.router.navigate([NavigationEnum.AuthRegisterCaptain]);
          } else {
            this.router.navigate([NavigationEnum.RegisterDashboard]);
          }
        }) ,
      ), { dispatch: false });

  authRegisterCaptainSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.authRegisterCaptainSuccess),
        tap((authenticationResponseModel) => {
          if (authenticationResponseModel?.authenticationResponseModel) {
            this.localStorageService.setUserInfo(authenticationResponseModel.authenticationResponseModel);
          }
        }),
        tap(() => this.router.navigate([NavigationEnum.RegisterDocuments])),

      ), { dispatch: false });

  confirmOtpFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.authConfirmOtpFailure, authActions.authRegisterCaptainFailure),
        tap((x) => {
          this.localStorageService.removeCaptainOtp();
          this.router.navigateByUrl(NavigationEnum.Default);
        }) ,
      ), { dispatch: false });

  registerCaptain$ = createEffect(
    () => this.actions$.pipe(
      ofType(authActions.authRegisterCaptain),
      switchMap((x) =>
        this.authService.registerCaptain(x.userCaptainRegistrationRequestModel).pipe(
          map((result) => authActions.authRegisterCaptainSuccess({ authenticationResponseModel: result.data })),
          catchError((error) => of(authActions.authRegisterCaptainFailure(error))),
        )),
    ));

  loadCity = createEffect(
    () => this.actions$.pipe(
      ofType(authActions.authLoadCity),
      withLatestFrom(this.store.select(selectCities)),
      filter(([_, loaded]) => !loaded),
      switchMap(() => this.authService.loadCities().pipe(
        map((result) => authActions.authLoadCitySuccess({ cities: result })),
      )),
    ));

  loginAdmin$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.authLoginAdmin),
    switchMap((action) => this.authService.loginAdmin(action.loginAdminRequest).pipe(
      map((response) => authActions.authLoginAdminSuccess({ authenticationResponseModel: response.data })),
      catchError((error) => of(authActions.authLoginAdminFailure(error))),
    )),
  ));

  loginAdminSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.authLoginAdminSuccess),
        tap((x) => {
          if (x?.authenticationResponseModel) {
            this.localStorageService.setUserInfo(x.authenticationResponseModel);
            //this.router.navigate([NavigationEnum.Home]);
            this.router.navigateByUrl('/auth/authenticator');
          }
        }),
      ), { dispatch: false });

  resendOtp$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.resendOtp),
    switchMap((action) => this.authService.resendOtp(action.otpResend).pipe(
      catchError((error) => of(authActions.resendOtpFailure(error))),
    )),
  ), { dispatch: false });

  sendRequestResetPassword$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.authResetPassword),
    switchMap((action) => this.authService.requestResetPassword(action.resetPassword.email).pipe(
      map(() => of(authActions.authResetPasswordSuccess())),
      tap(() => this.router.navigateByUrl('/auth/confirm-sending-request')),
      catchError((error) => of(authActions.authResetPasswordFailure(error))),
    )),
  ), { dispatch: false });

  sendRequestChangePassword$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.authChangePassword),
    switchMap((action) => this.authService.requestChangePassword(action.resetPassword).pipe(
      map(() => of(authActions.authChangePasswordSuccess())),
      tap(() => this.router.navigateByUrl('/auth/admin')),
      catchError((error) => of(authActions.authChangePasswordFailure(error))),
    )),
  ), { dispatch: false });

  changeEmail$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.authChangeEmail),
    switchMap((action) => this.authService.changeEmail(action.email, action.jwt).pipe(
      map(() => authActions.authChangeEmailSuccess({ jwt: action.jwt })),
    )),
  ));

  constructor(
    private actions$: Actions,
    private localStorageService: LocalStorageService,
    private router: Router,
    private authService: AuthService,
    private store: Store,
    private toastService: ToastService,
  ) { }
}
