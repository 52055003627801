export const PAGE_STORE_NAME = 'page';

export interface IPageState {
  authPage: boolean;
  errorPage: boolean;
  isFetching: boolean;
}

export const INITIAL_STATE = {
  authPage: false,
  errorPage: false,
  isFetching: false,
};
