import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { couldStartTrivia } from 'typescript';

@Component({
  selector: 'app-form-table-item-check',
  templateUrl: './form-table-item-check.component.html',
})
export class FormTableItemCheckComponent implements  OnChanges  {
  @Input() editMode: boolean = false;
  @Input() isFormSubmitted: boolean = false;
  @Input() name: string;
  @Input() form: FormGroup;
  @Input() title: string = this.translateService.instant('shared.label.fill_title');
  @Input() type: string = 'text';
  @Input() mask: string = '';
  @Input() loading: boolean;
  @Output() checkValueEvent = new EventEmitter<string>();

  get errors() {
    return this.form.controls[this.name].errors;
  }

  constructor(private translateService: TranslateService) {

   }
  ngOnChanges(changes: SimpleChanges): void {
  }

   checkValue() {
    this.checkValueEvent.emit(this.form.controls[this.name].value);
  }

   print(){
     
    console.log()
   }
}
