<div [class.d-none]="hide">
  <label>{{ label }}</label>
  <input type="file" #fileInput class="file-upload-default" style="display: none;" />
  <div class="input-group col-xs-12">
    <input class="form-control file-upload-info" id="carPhoto1"
      [class.cursor-zoom]="!newFileLoaded && fileName"
      [class.border-success]="isValid"
      [class.bg-lock]="isLocked"
      [value]="fileName" [placeholder]="label"
      (click)="!newFileLoaded && fileName && openPreviewModal(previewModal,downloadModal)" type="text" readonly />
    <span class="input-group-append flex-column">
      <button class="file-upload-preview btn btn-inverse-success border-success border-bottom-0 d-sm-none"
        *ngIf="!newFileLoaded && fileName"
        (click)="!newFileLoaded && fileName && openPreviewModal(previewModal,downloadModal)" type="button">
        {{ 'register.label.preview' | translate }}
      </button>
      <button class="file-upload-browse btn ml-0"
        [class.btn-gradient-primary]="!isValid"
        [class.btn-success]="isValid"
        [class.cursor-lock]="isLocked"
        (click)="!isLocked && fileInput.click()"
        type="button">
        {{ (isLocked ? lockLabel : 'register.label.upload') | translate }}
      </button>
    </span>
  </div>
</div>

<ng-template #previewModal let-modal class="modal fade" aria-labelledby="previewModal">
  <div class="modal-header">
    <button type="button" class="close" (click)="modal.dismiss()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <img [src]="file && file.name">
  </div>
</ng-template>

<ng-template #downloadModal let-modal class="modal fade" aria-labelledby="previewModal">

  <div class="modal-header">
    <button type="button" class="close" (click)="modal.dismiss()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p><strong>{{ 'register.label.are_you_sure' | translate }} <span class="text-primary">pdf</span> ?</strong></p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="modal.dismiss('cancel click')">{{
      'register.label.cancel' | translate }}</button>
    <button type="button" class="btn btn-gradient-primary" (click)="downloadPdf();modal.dismiss('cancel click')">{{
      'register.label.ok' | translate }}</button>
  </div>
</ng-template>