import { authConfirmOtpSuccess, authLoadCityInternal, authLoadCitySuccess, authLogout, authRegisterCaptainSuccess } from './auth.actions';
import { IAuthState } from './auth.models';

import { Action, createReducer, on } from '@ngrx/store';

const initialState: IAuthState = {
  user: null,
  cities: [{ name: 'Riyadh', externalId: '03016111-666d-ea11-a738-309c2364611c', created: '1614604376', updated: null }],
};

const reducer = createReducer(
  initialState,
  on(authLogout, (state) => ({ ...state, ...initialState })),
  on(authConfirmOtpSuccess, (state, { authenticationResponseModel }) => ({ ...state, user: authenticationResponseModel })),
  on(authRegisterCaptainSuccess, (state, { authenticationResponseModel }) => ({ ...state, user: authenticationResponseModel })),
  on(authLoadCitySuccess, (state, { cities }) => ({ ...state, cities })),
  on(authLoadCityInternal, (state) => ({
    ...state,
    cities: [{ name: 'Riyadh', externalId: '03016111-666d-ea11-a738-309c2364611c', created: '1614604376', updated: null }],
  })),
);

export const authReducer = (
  state: IAuthState,
  action: Action,
): IAuthState => reducer(state, action);
