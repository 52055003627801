<td>
    {{title}}
</td>
<td *ngIf="!editMode">
    {{form.controls[name]?.value}}
</td>
<td *ngIf="editMode" [formGroup]="form">
    <div class="input-group">
        <ng-select class="auto-grow" placeholder="{{'captain.registration.plate_type' | translate}}" [formControlName]="name"
            [class.is-invalid]="isFormSubmitted && form.get(name).invalid">
            <ng-option *ngFor="let ownership of plateTypes" [value]="ownership.value">{{ownership.display}}</ng-option>
        </ng-select>
    </div>
    <app-display-validation [errors]="errors" [additionalValidate]="isFormSubmitted"></app-display-validation>
</td>