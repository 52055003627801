import { countryCallingCode } from '../../shared/consts/consts';
import { AbstractBaseService } from '../abstraction/base-service';
import { IAuth } from '../interfaces/auth';
import {
  AuthenticationResponseModelEnvelope, AuthenticationTokensResponseModelEnvelope,
  CitiesModel, CitiesResponseModelEnvelope, ConfirmOtpRequestModel,
  IChangePasswordRequestModel, IGoogleAuthenticatorResponse, IGoogleAuthenticatorResponseModelEnvelope, RefreshTokenRequestModel, ResendOtpRequestModel,
  UserCaptainRegistrationRequestModel, UserLoginResponseModelEnvelope,
  UserLoginWithPasswordRequestModel, UserLoginWriteServiceModel,
} from '../models/';

import { LocalStorageService } from './local-storage.service';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

export const API_BASE_URL = new InjectionToken<string>('API_BASE_URL');

@Injectable({
  providedIn: 'root',
})
export class AuthService extends AbstractBaseService implements IAuth {
  constructor(private http: HttpClient, private localStorageService: LocalStorageService,
  ) {
    super();
  }

  public registerCaptain(body: UserCaptainRegistrationRequestModel): Observable<AuthenticationResponseModelEnvelope> {
    let url = this.baseUrl + '/admin/identity/register-captain';

    url = url.replace(/[?&]$/, '');

    const clone = {
      ...body,
      phoneNumber: body.phoneNumber.startsWith('677') ?
        body.phoneNumber : countryCallingCode + body.phoneNumber, // country calling code; Fix problem with different phone numbers with
    };

    const content = JSON.stringify(clone);

    return this.http.post<AuthenticationResponseModelEnvelope>(url, content, { headers: this.headers });
  }

  public loginCaptain(body: UserLoginWriteServiceModel): Observable<UserLoginResponseModelEnvelope> {
    let url = this.baseUrl + '/admin/identity/login-captain';

    url = url.replace(/[?&]$/, '');

    const clone = {
      ...body,
      phoneNumber: body.phoneNumber.startsWith('677') ?
        body.phoneNumber : countryCallingCode + body.phoneNumber, // country calling code; Fix problem with different phone numbers with
    };

    const content = JSON.stringify(clone);

    return this.http.post<UserLoginResponseModelEnvelope>(url, content, { headers: this.headers });
  }

  public zendeskToken(jwt: string): Observable<{ data: { zendeskCaptainToken: string } }> {
    let url = this.baseUrl + '/admin/zendesk/token';

    url = url.replace(/[?&]$/, '');

    let localHeaders = new HttpHeaders();

    localHeaders = localHeaders.append('Authorization', `Bearer ${jwt}`);

    return this.http.get<{ data: { zendeskCaptainToken: string } }>(url, { headers: localHeaders });
  }

  public confirmOtp(body: ConfirmOtpRequestModel): Observable<AuthenticationResponseModelEnvelope> {
    let url = this.baseUrl + '/admin/identity/confirm-otp';

    url = url.replace(/[?&]$/, '');

    const content = JSON.stringify(body);

    return this.http.post<AuthenticationResponseModelEnvelope>(url, content, { headers: this.headers }).pipe(tap((x) => {
      this.localStorageService.setUserInfo(x.data);
    }));
  }

  public resendOtp(body: ResendOtpRequestModel): Observable<AuthenticationResponseModelEnvelope> {
    const url = this.baseUrl + '/admin/identity/resend-otp';

    const content = JSON.stringify(body);

    return this.http.post<AuthenticationResponseModelEnvelope>(url, content, { headers: this.headers });
  }

  public loginAdmin(body: UserLoginWithPasswordRequestModel): Observable<AuthenticationResponseModelEnvelope> {
    let url = this.baseUrl + '/admin/identity/login-admin';

    url = url.replace(/[?&]$/, '');

    const content = JSON.stringify(body);

    return this.http.post<AuthenticationResponseModelEnvelope>(url, content, { headers: this.headers });
  }

  public googleAuthenticator(email : string): Observable<IGoogleAuthenticatorResponseModelEnvelope> {
    let url = this.baseUrl + '/admin/identity/google-authenticator/' + email;

    url = url.replace(/[?&]$/, '');

    return this.http.get<IGoogleAuthenticatorResponseModelEnvelope>(url, { headers: this.headers });
  }

  public GoogleAuthenticatorVerify(key : string, userExternalId : string): Observable<any> {
    let url = this.baseUrl + '/admin/identity/google-authenticator-verify/' + key + '/' + userExternalId;

    url = url.replace(/[?&]$/, '');

    return this.http.get<any>(url, { headers: this.headers });
  }

  public changeEmail(email: string, jwt: string): Observable<{ email: string }> {
    let url = this.baseUrl + '/admin/profile/update/email';

    url = url.replace(/[?&]$/, '');

    let localHeaders = new HttpHeaders();

    if (this.localStorageService.getUserInfo === undefined || this.localStorageService.getUserInfo === null) {
      localHeaders = localHeaders.append('Authorization', `Bearer ${jwt}`);
    }

    return this.http.put<{ email: string }>(url, { email }, { headers: localHeaders });
  }

  public refreshToken(body: RefreshTokenRequestModel): Observable<AuthenticationTokensResponseModelEnvelope> {
    let url = this.baseUrl + '/admin/identity/refresh-token';

    url = url.replace(/[?&]$/, '');

    const content = JSON.stringify(body);

    return this.http.post<AuthenticationTokensResponseModelEnvelope>(url, content, { headers: this.headers });
  }

  public loadCities(): Observable<CitiesModel[]> {
    let url = this.baseUrl + '/user/lookup/cities';

    url = url.replace(/[?&]$/, '');

    return this.http.get<CitiesResponseModelEnvelope>(url).pipe(map((x) => x.data));
  }

  public requestResetPassword(email: string): Observable<AuthenticationTokensResponseModelEnvelope> {
    let url = this.baseUrl + '/admin/identity/request-reset-password?';

    if (email === undefined) {
      throw new Error('The parameter \'email\' must be defined.');
    }
    if (email !== null) {
      url += 'email=' + encodeURIComponent('' + email) + '&';
    }
    url = url.replace(/[?&]$/, '');

    return this.http.post<AuthenticationTokensResponseModelEnvelope>(url, null);
  }

  public requestChangePassword(data: IChangePasswordRequestModel): Observable<AuthenticationResponseModelEnvelope> {
    let url = this.baseUrl + '/admin/identity/reset-password';

    url = url.replace(/[?&]$/, '');

    const content = JSON.stringify(data);

    return this.http.post<AuthenticationResponseModelEnvelope>(url, content, { headers: this.headers });
  }
}
