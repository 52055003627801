<p [class.active]="isFilterActive" class="filters__label">
  {{translatePrefix + filter.name | translate}}
</p>
<ng-container *ngIf="filter.kind === filterTypes.Enum">
  <ng-select [(ngModel)]="enumValue" (change)="filterChanged()" [placeholder]="filter.label" [closeOnSelect]="true"
    [searchable]="false" [selectOnTab]="true" appendTo="body"
    class="filters__dropdown dropdown--small dropdown--border">
    <ng-option *ngFor="let option of filter.possibleValues" [value]="option">{{option | splitCamelCase}}</ng-option>
  </ng-select>
</ng-container>
<ng-container *ngIf="filter.kind === filterTypes.String && filter.name === 'city'">
  <app-city-filter (cityChangedEvent)="cityValue = $event;filterChanged()">
  </app-city-filter>
</ng-container>
<ng-container *ngIf="filter.kind === filterTypes.String && filter.name === 'rideType'">
  <app-ride-types-filter (rideTypesChanged)="rideTypeValue = $event;filterChanged()">

  </app-ride-types-filter>
</ng-container>
<ng-container *ngIf="filter.kind === filterTypes.Int">
  <div class="filters__wrapper">
    <ng-select [(ngModel)]="condition" (change)="filterChanged()" [clearable]="false" [searchable]="false"
      [selectOnTab]="true" appendTo="body"
      class="filters__dropdown dropdown--small dropdown--only-icon dropdown--border">
      <ng-option *ngFor="let condition of conditions" [value]="condition">
        <app-svg-icon [icon]="condition"></app-svg-icon>
      </ng-option>
      <ng-template let-item="item" ng-label-tmp>
        <app-svg-icon [icon]="item"></app-svg-icon>
      </ng-template>
    </ng-select>
    <input [(ngModel)]="intValue" (change)="filterChanged()" class="form-control filters__input border" mask="0*"
      placeholder="0" type="text" />
  </div>
</ng-container>
<ng-container *ngIf="filter.kind === filterTypes.Date">
  <div class="form-group">
    <div class="input-group">
      <input [(ngModel)]="dateValue" (dateSelect)="filterChanged()" (blur)="filterChanged(true)"
        (click)="datePicker.toggle()" container="body" class="form-control filters__input filters__input--date border"
        ngbDatepicker placeholder="{{'home.label.select_date' | translate}}" #datePicker="ngbDatepicker">
    </div>
  </div>
</ng-container>
<ng-container *ngIf="filter.kind ===filterTypes.DateRange">
  <div class="form-group hidden">
    <div class="input-group">
      <input (dateSelect)="filterChanged(false, $event)" [displayMonths]="2" [dayTemplate]="day"
        [startDate]="dateRange.startDate!" autoClose="outside" class="form-control border" container="body"
        ngbDatepicker outsideDays="hidden" positionTarget=".filters__input--date" #dateRangePicker="ngbDatepicker">
      <ng-template #day let-date let-focused="focused">
        <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
          [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="dateRange.hoveredDate = date"
          (mouseleave)="dateRange.hoveredDate = null">
          {{ date.day }}
        </span>
      </ng-template>
    </div>
  </div>
  <div class="filters__input-wrapper">
    <div class="form-group">
      <div class="input-group">
        <input (click)="dateRangePicker.toggle()" [value]="formatter.format(dateRange.startDate)"
          (input)="dateRange.startDate = validateInput(dateRange.startDate, dpFromDate.value)"
          class="form-control filters__input filters__input--date filters__input--date--first border"
          placeholder="{{'home.label.from' | translate}}" #dpFromDate>
      </div>
    </div>
    <div class="form-group ml-2">
      <div class="input-group">
        <input (click)="dateRangePicker.toggle()" (blur)="filterChanged(true)"
          [value]="formatter.format(dateRange.endDate)"
          (input)="dateRange.endDate = validateInput(dateRange.endDate, dpToDate.value)"
          class="form-control filters__input filters__input--date border" placeholder="{{'home.label.to' | translate}}"
          #dpToDate>
      </div>
    </div>
  </div>
</ng-container>