import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbCalendar, NgbCalendarIslamicUmalqura, NgbDate, NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { IslamicI18n } from './islamicI18n.service';
@Component({
  selector: 'app-form-calendar-islamic',
  templateUrl: './form-calendar-islamic.component.html',
  styleUrls: ['./form-calendar-islamic.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {provide: NgbCalendar, useClass: NgbCalendarIslamicUmalqura},
    {provide: NgbDatepickerI18n, useClass: IslamicI18n}
  ]
})
export class FormCalendarIslamicComponent implements OnChanges {
  @Input() editMode: boolean = false;
  @Input() isFormSubmitted: boolean = false;
  @Input() name: string;
  @Input() form: FormGroup;
  @Input() title: string = this.translateService.instant('shared.label.fill_title');
  @Input() birthday: boolean = false;
  @Input() isHidden: boolean = false;
  today: NgbDateStruct = null;
  date: { year: number, month: number, day: number };
  get errors() {
    return this.form.controls[this.name].errors;
  }
  defaultValue: NgbDate;

  constructor(private translateService: TranslateService) {
    const date = new Date();

    this.today = {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    // tslint:disable-next-line: early-exit
    if (changes.editMode?.previousValue !== changes.editMode?.currentValue && this.form.controls[this.name]?.value) {
      this.date = {
        year: this.form.controls[this.name]?.value?.getFullYear(),
        month: this.form.controls[this.name]?.value?.getMonth() + 1,
        day: this.form.controls[this.name]?.value?.getDate(),
      };
    }
  }

  onDateSelect($event: NgbDate) {
    const date = new Date(Date.UTC($event.year, $event.month - 1, $event.day));

    const control = this.form.controls[this.name];

    control.patchValue(date);
    this.defaultValue = $event;
  }
}
