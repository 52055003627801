import { CitiesModel } from '@jameel/core/models/';
import { AuthService } from '@jameel/core/services/auth.service';

import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-city-select',
  templateUrl: './city-select.component.html',
  styleUrls: ['./city-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CitySelectComponent implements OnInit, OnDestroy {
  title: string = this.translateService.instant('home.label.city');
  @Input() externalId: string;
  @Input() value: string = '';
  @Input() form: FormGroup;
  @Input() editMode: boolean = false;
  @Input() name: string;
  selectValue: CitiesModel;

  cities: CitiesModel[];

  private sub: Subscription = new Subscription();

  constructor(private authService: AuthService, private translateService: TranslateService) { }

  ngOnInit() {
    this.sub.add(this.authService.loadCities().subscribe((result) => {
      this.cities = result;
      if (!this.value) {
        return;
      }
      const selectedCity = result.find((city) => city.name === this.value);

      this.selectValue = selectedCity;
    }));
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
