<td>
  {{title}}
</td>
<td *ngIf="!editMode">
  {{timeLabel || '-'}}
</td>
<td *ngIf="editMode" [class.edit-mode]="editMode">
  <div class="input-group">
    <ngb-timepicker
      [(ngModel)]="time"
      (ngModelChange)="onTimeChange($event)"
      [meridian]="true"
      [class.is-invalid]="isFormSubmitted && form.get(name).invalid">
    </ngb-timepicker>

    <!-- <input [(ngModel)]="date" (dateSelect)="onDateSelect($event)" (click)="datePicker.toggle()" container="body"
      class="form-control filters__input filters__input--date" ngbDatepicker placeholder="Select date"
      #datePicker="ngbDatepicker"> -->
  </div>
</td>