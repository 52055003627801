
import { VehicleOwnershipType } from '@jameel/core/enums/';
import { ITranslateEnum } from '@jameel/core/models/translateEnumModel.model';

import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-ownership',
  templateUrl: './ownership.component.html',
  styleUrls: ['./ownership.component.scss'],
})

export class OwnershipComponent {
  get errors() {
    return this.form.controls[this.name].errors;
  }
  @Input() editMode: boolean = false;
  @Input() name: string;
  @Input() form: FormGroup;
  @Input() title: string = this.translateService.instant('shared.label.fill_title');
  @Input() type: string = 'text';
  @Input() value = '';
  @Input() isFormSubmitted: boolean = false;

  ownerships: ITranslateEnum[] = [{display: this.translateService.instant('enum.ownership.rental'), value: VehicleOwnershipType.Rental}];

  constructor(private translateService: TranslateService) {}
}
