<td>
    {{title}}
  </td>
  <td *ngIf="!editMode">
    {{form.controls[name]?.value | date}}
  </td>
  <td *ngIf="editMode" [class.edit-mode]="editMode">
    <div class="input-group">
      <input
        [(ngModel)]="date"
        (dateSelect)="onDateSelect($event)"
        [minDate]="birthday ? {year: 1900, month: 1, day: 1} : {}"
        [maxDate]="birthday ? today : {}"
        (click)="datePicker.toggle()"
        container="body"
        class="form-control filters__input filters__input--date"
        [class.is-invalid]="isFormSubmitted && form.get(name).invalid"
        ngbDatepicker
        placeholder="{{'home.label.select_date' | translate}}"
        #datePicker="ngbDatepicker">
    </div>
    <div class="input-group">
      <ngb-timepicker
        [(ngModel)]="time"
        (ngModelChange)="onTimeChange($event)"
        [meridian]="true"
        [class.is-invalid]="isFormSubmitted && form.get(name).invalid">
      </ngb-timepicker>
    </div>
    <app-display-validation [errors]="errors" [additionalValidate]="isFormSubmitted"></app-display-validation>
  </td>
  <td *ngIf="!editMode">
    {{timeLabel || '-'}}
  </td>