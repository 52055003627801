import { NavigationEnum } from '../enums';
import { LocalStorageService } from '../services/local-storage.service';

import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthCaptainGuard implements CanLoad, CanActivate {
  constructor(private localStorageService: LocalStorageService, private router: Router) { }

  canLoad(route: Route, segments: UrlSegment[]): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.checkLogin()
      ? true
      : this.router.parseUrl(NavigationEnum.Login);
  }

  /**
   * Note: Used for navigating to captain's auth-related pages only
   *
   * @returns true if not logged in; in other case redirecting
   * to captain's homepage
   */
  canActivate(): boolean | UrlTree {
    return this.checkLogin()
      ? this.router.parseUrl(NavigationEnum.RegisterDashboard)
      : true;
  }

  checkLogin(): boolean {
    const isLoggedIn = this.localStorageService.getUserInfo?.isUserRegistered;

    if (isLoggedIn) {
      if (this.localStorageService.isCaptain) { return true; }

      this.localStorageService.clearLocalStorage();
    }

    return false;
  }
}
