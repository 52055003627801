<div class="d-flex align-items-center text-center error-page bg-primary pt-5 pb-4 h-100">
  <div class="row flex-grow">
    <div class="col-lg-8 mx-auto text-white">
      <div class="row align-items-center d-flex flex-row">
        <div class="col-lg-6 text-lg-right pr-lg-4">
          <h1 class="display-1 mb-0">{{ 'error.404' | translate }}</h1>
        </div>
        <div class="col-lg-6 error-page-divider text-lg-left pl-lg-4">
          <h2>{{ 'error.sorry' | translate }}</h2>
          <h3 class="font-weight-light">{{ 'error.not_found' | translate }}</h3>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-12 text-center mt-xl-2">
          <a class="text-white font-weight-medium" routerLink="/">
            {{ 'error.back_to_home' | translate }}
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
