<nav class="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
  <div class="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
    <a class="navbar-brand brand-logo" routerLink="/"><img src="assets/images/logo.svg" alt="logo" /></a>
    <a class="navbar-brand brand-logo-mini" routerLink="/"><img src="assets/images/logo-mini.svg" alt="logo" /></a>
  </div>
  <div class="navbar-menu-wrapper d-flex align-items-stretch">
    <button class="navbar-toggler navbar-toggler align-self-center" type="button" data-toggle="minimize"
      (click)="toggleSidebar()">
      <span class="mdi mdi-menu text-light"></span>
    </button>
    <ul class="navbar-nav navbar-nav-right">


      <li class="nav-item nav-logout d-none d-lg-block">
        <a class="nav-link" (click)="changeLang()"  >
          <i class="mdi mdi-web mr-2 text-light"></i>
          <span class="pb-1 text-light" >{{ 'shared.navbar.lang' | translate }}</span>
        </a>
      </li>

      <li class="nav-item nav-profile">
        <div class="nav-link cursor-default">
          <i class="mdi mdi-account text-light"></i>
          <div class="nav-profile-text">
            <p class="mb-1 text-black text-light">{{'app.label.welcome' | translate}}, {{name}}!</p>
          </div>
        </div>
      </li>
      <!-- <li class="nav-item nav-profile dropdown" ngbDropdown>
        <a class="nav-link dropdown-toggle" id="profileDropdown" ngbDropdownToggle>
          <i class="mdi mdi-account text-light"></i>
          <div class="nav-profile-text">
            <p class="mb-1 text-black text-light">{{'app.label.welcome' | translate}}, {{name}}!</p>
          </div>
        </a>
        <div class="dropdown-menu navbar-dropdown" ngbDropdownMenu aria-labelledby="profileDropdown">
          <a class="dropdown-item" href="javascript:;">
            <i class="mdi mdi-cached text-success "></i>
            {{ 'shared.navbar.change_language' | translate }} DE
          </a>
          <div class="dropdown-divider "></div>
          <a class="dropdown-item" (click)="logout()">
            <i class="mdi mdi-logout mr-2 "></i>
            {{ 'shared.navbar.sign_out' | translate }}
          </a>
        </div>
      </li> -->
      <li class="nav-item nav-logout d-none d-lg-block">
        <a class="nav-link" href="javascript:;">
          <i class="mdi mdi-logout mr-2 text-light"></i>
          <span class="pb-1 text-light" (click)="logout()">{{ 'shared.navbar.sign_out' | translate }}</span>
        </a>
      </li>
    </ul>
    <button class="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button"
      (click)="toggleOffcanvas()">
      <span class="mdi mdi-menu"></span>
    </button>
  </div>
</nav>