
import { ICaptainDocumentsModel } from '@jameel/core/models/';

import { createAction, props } from '@ngrx/store';

export const actionRegisterContinue = createAction(
  '[Register Form] Continue process of registration documents',
);

// Documents
export const actionFileUpload = createAction(
  '[Register Form] File upload',
  props<{
    drivingLicenseFront?: FormData;
    nationalId?: FormData;
    bankAccountInformation?: FormData;
  }>(),
);

export const actionFileUploadFinish = createAction(
  '[Register Form] File upload Finished',
);

export const actionFileUploadFailure = createAction(
  '[Register Form] File upload failure',
  props<{ error: string }>(),
);

// Cars

export const actionCarFileUpload = createAction(
  '[Register Form] Car file upload',
  props<{
    carInsurance?: FormData;
    carRegistration?: FormData;
    drivingAuthorization?: FormData;
    vehicleExternalId: string;
  }>(),
);

export const actionCarFileUploadFinish = createAction(
  '[Register Form] Car file upload Finished',
);

export const actionCarFileUploadFailure = createAction(
  '[Register Form] Car file upload failure',
  props<{ error: string }>(),
);

// PersonalPhoto / Avatar upload
export const actionCaptainAvatarUpload = createAction(
  '[Register Form] Captain Avatar upload',
  props<{ file: FormData }>(),
);

export const actionAdminAvatarUpload = createAction(
  '[Register Form] AdminAvatar upload',
  props<{ file: FormData }>(),
);

export const actionAvatarUploadSuccess = createAction(
  '[Register Form] Avatar upload success',
);

// Init documents
export const actionInitDocuments = createAction(
  '[Register Form] Init documents',
);

export const actionInitDocumentsSuccess = createAction(
  '[Register Form] Init documents success',
  props<ICaptainDocumentsModel>(),
);

export const actionResetStore = createAction('[Register Form] Reset store');
