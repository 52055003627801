import { selectSettingsState } from '@jameel/core/core.state';

import { ISettingsState } from './settings.model';

import { createSelector } from '@ngrx/store';

export const selectSettings = createSelector(
  selectSettingsState,
  (state: ISettingsState) => state,
);

export const selectSettingsLanguage = createSelector(
  selectSettingsState,
  (state: ISettingsState) => state.language,
);
