import { IProfileState, selectProfileTabs } from '../profile-tabs.state';

import { createSelector } from '@ngrx/store';

export const profile = createSelector(
  selectProfileTabs,
  (state: IProfileState) => state.profileDetails,
);

export const ministryStatus = createSelector(
  selectProfileTabs,
  (state: IProfileState) => state.profileDetails?.captainDetails?.ministryStatus,
);

export const hasAcceptedMinistryStatus = createSelector(
  selectProfileTabs,
  (state: IProfileState) => state.profileDetails?.captainDetails?.hasAcceptedMinistryStatus,
);

export const profileNationalId = createSelector(
  selectProfileTabs,
  (state: IProfileState) => state.profileDetails?.captainDetails?.nationalIdDocument,
);

export const profileCaptainDetails = createSelector(
  selectProfileTabs,
  (state: IProfileState) => state.profileDetails?.captainDetails,
);

export const rejectedReasons = createSelector(
  selectProfileTabs,
  (state: IProfileState) => state.profileDetails.reasonsRejected,
);

export const cities = createSelector(
  selectProfileTabs,
  (state: IProfileState) => state.profileDetails.cities,
);

export const currentVehicle = createSelector(
  selectProfileTabs,
  (state: IProfileState) => state.profileDetails.currentVehicle,
);

export const currentVehicleDetails = createSelector(
  selectProfileTabs,
  (state: IProfileState) => state.profileDetails.currentVehicleDetails,
);

export const defaultVehicleDetails = createSelector(
  selectProfileTabs,
  (state: IProfileState) => state.profileDetails.defaultVehicleDetails,
);

export const documentUpdated = createSelector(
  selectProfileTabs,
  (state: IProfileState) => state.profileDetails.documentUpdated,
);

export const nationalIdStatus = createSelector(
  selectProfileTabs,
  (state: IProfileState) => state.profileDetails.captainDetails.nationalId.status,
);

export const drivingLicenseStatus = createSelector(
  selectProfileTabs,
  (state: IProfileState) => state.profileDetails.captainDetails.drivingLicense.status,
);

export const bankAccountInformationStatus = createSelector(
  selectProfileTabs,
  (state: IProfileState) => state.profileDetails.captainDetails.bankAccountInformation.status,
);

export const getRideTypeCategoryList = createSelector(selectProfileTabs, (state: IProfileState) => state.profileRideCategory.list);
export const getRideTypeCategoryPageInfo = createSelector(selectProfileTabs, (state: IProfileState) => state.profileRideCategory.pageInfo);
export const getRideTypeCategoryFilterColumns = createSelector(selectProfileTabs, (state: IProfileState) => state.profileRideCategory.filterColumns);
export const getRideTypeCategoryMetaData = createSelector(selectProfileTabs, (state: IProfileState) => state.profileRideCategory.metaData);

// export const drivingLicenseStatus = createSelector(
//   selectProfileTabs,
//   (state: IProfileState) => state.profileDetails.captainDetails.drivingLicense.status,
// );
