<td>{{title}}</td>
<td *ngIf="!editMode">{{form.controls[this.name]?.value | yesNo}}</td>
<td *ngIf="editMode" [formGroup]="form">
  <div class="input-group">
    <div class="custom-control custom-checkbox">
      <input type="checkbox" class="custom-control-input" [id]="name" [formControlName]="name">
      <label class="custom-control-label" [for]="name"></label>
    </div>
  </div>
</td>
