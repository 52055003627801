import { AbstractBaseService } from '@jameel/core/abstraction/base-service';
import {
  EnvelopeGenericModel,
  IEmptyDataEnvelope,
  IFilterRequest,
  IGenericListPaginatedResponse,
  IListParams,
  IResponseEnvelope,
  IUserProfileResponseModelEnvelope,
  IUserStatus,
  UserProfileResponseModel,
} from '@jameel/core/models';

import { environment } from '../../../environments/environment';
import { CarDocumentType, PersonalDocumentType, VehiclePhotoType } from '../enums';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IRideTypeCategoryListResponseModel } from '../models/IncentiveManagement';

@Injectable({ providedIn: 'root' })
export class ProfileService extends AbstractBaseService {
  constructor(private http: HttpClient) {
    super();

    this.baseUrl = environment.API_BASE_URL;
  }

  public profile(): Observable<EnvelopeGenericModel<UserProfileResponseModel>> {
    let url = this.baseUrl + '/admin/profile/avatar';

    url = url.replace(/[?&]$/, '');

    return this.http.get<EnvelopeGenericModel<UserProfileResponseModel>>(url);
  }

  public updateStatus(body: IUserStatus): Observable<IUserProfileResponseModelEnvelope> {
    let url = this.baseUrl + '/admin/application/status';

    url = url.replace(/[?&]$/, '');
    const content = JSON.stringify(body);

    return this.http.post<IUserProfileResponseModelEnvelope>(url, content, {
      headers: this.headers,
    });
  }

  public sendToAPI(externalId: string): Observable<UserProfileResponseModel> {
    let url = this.baseUrl + '/admin/ministry/submit';

    url = url.replace(/[?&]$/, '');

    return this.http.post<IUserProfileResponseModelEnvelope>(url, { externalId }, {
      headers: this.headers,
    }).pipe(map((data) => data.data));
  }

  public uploadDocuments(userExternalId: string, documentType: PersonalDocumentType, file: File): Observable<IEmptyDataEnvelope> {
    let url = this.baseUrl + '/admin/document/selected/personal?';

    if (userExternalId === undefined || userExternalId === null) {
      throw new Error('The parameter \'userExternalId\' must be defined and cannot be null.');
    }

    url += 'userExternalId=' + encodeURIComponent('' + userExternalId) + '&';
    if (documentType === undefined || documentType === null) {
      throw new Error('The parameter \'documentType\' must be defined and cannot be null.');
    }

    url += 'documentType=' + encodeURIComponent('' + documentType) + '&';
    url = url.replace(/[?&]$/, '');

    const content = new FormData();

    if (file !== null && file !== undefined) {
      content.append('request', file);
    }

    return this.http.post<IEmptyDataEnvelope>(url, content).pipe(map((data) => data.data));
  }

  public uploadDocumentsVehicle(userExternalId: string, vehicleExternalId: string,
    documentType: CarDocumentType, file: File): Observable<IEmptyDataEnvelope> {
    let url = this.baseUrl + '/admin/document/selected/vehicle?';

    if (userExternalId === undefined || userExternalId === null) {
      throw new Error('The parameter \'userExternalId\' must be defined and cannot be null.');
    }

    url += 'userExternalId=' + encodeURIComponent('' + userExternalId) + '&';

    if (vehicleExternalId === undefined || vehicleExternalId === null) {
      throw new Error('The parameter \'vehicleExternalId\' must be defined and cannot be null.');
    }

    url += 'vehicleExternalId=' + encodeURIComponent('' + vehicleExternalId) + '&';

    if (documentType === undefined || documentType === null) {
      throw new Error('The parameter \'documentType\' must be defined and cannot be null.');
    }

    url += 'documentType=' + encodeURIComponent('' + documentType) + '&';
    url = url.replace(/[?&]$/, '');

    const content = new FormData();

    if (file !== null && file !== undefined) {
      content.append('request', file);
    }

    return this.http.post<IEmptyDataEnvelope>(url, content).pipe(map((data) => data.data));
  }

  public uploadCarPhotos(userExternalId: string, vehicleExternalId: string,
    vehiclePhotoType: VehiclePhotoType, file: File): Observable<IEmptyDataEnvelope> {
    let url = this.baseUrl + '/admin/vehicle/selected/photos?';

    if (userExternalId === undefined) {
      throw new Error('The parameter \'userExternalId\' must be defined.');
    }
    if (userExternalId !== null) {
      url += 'userExternalId=' + encodeURIComponent('' + userExternalId) + '&';
    }
    if (vehicleExternalId === undefined || vehicleExternalId === null) {
      throw new Error('The parameter \'vehicleExternalId\' must be defined and cannot be null.');
    }

    url += 'vehicleExternalId=' + encodeURIComponent('' + vehicleExternalId) + '&';
    if (vehiclePhotoType === undefined || vehiclePhotoType === null) {
      throw new Error('The parameter \'vehiclePhotoType\' must be defined and cannot be null.');
    }

    url += 'vehiclePhotoType=' + encodeURIComponent('' + vehiclePhotoType) + '&';
    url = url.replace(/[?&]$/, '');

    const content = new FormData();

    if (file !== null && file !== undefined) {
      content.append('request', file);
    }

    return this.http.post<IEmptyDataEnvelope>(url, content).pipe(map((data) => data.data));
  }

  fetchRideCategoryList(
    vehicleExternalId: string,
    params: IListParams,
    filters: IFilterRequest,
  ): Observable<IGenericListPaginatedResponse<IRideTypeCategoryListResponseModel>> {
    let url = this.baseUrl + '/Admin/Vehicle/selected-ride-category/{vehicleExternalId}';
    if (vehicleExternalId === undefined || vehicleExternalId === null) {
      throw new Error('The parameter \'externalId\' must be defined.');
    }
    url = url.replace('{vehicleExternalId}', encodeURIComponent('' + vehicleExternalId));
    url = url.replace(/[?&]$/, '');

    const content = JSON.stringify([filters]);

    return this.http.put<IResponseEnvelope<IGenericListPaginatedResponse<IRideTypeCategoryListResponseModel>>>(url, content, {
      headers: new HttpHeaders({
        'Accept': 'text/plain',
        'Content-Type': 'application/json-patch+json',
        'x-query': params.x_query ? `${params.x_query}` : '',
        'x-order': `${params.x_order}`,
        'x-desc': `${params.x_desc}`,
        'x-pageNumber': `${params.x_pageNumber}`,
        'x-pageSize': `${params.x_pageSize}`,
      }),
    }).pipe(
      map((adminNotifications) => adminNotifications.data),
    );

  }


  getRideCategory(): Observable<any> {
    let url = this.baseUrl + '/user/lookup/ride-category';
    return this.http.get<any>(url);
  }

  public deleteRideCategory(externalId: string): Observable<any> {
    let url = this.baseUrl + '/Admin/Vehicle/ride-type-cat-delete/';

    if (externalId === undefined || externalId === null) {
      throw new Error('The parameter \'externalId\' must be defined and cannot be null.');
    }

    url += encodeURIComponent('' + externalId);
    url = url.replace(/[?&]$/, '');

    return this.http.delete<any>(url).pipe(map((res) => res.data));
  }

  public saveRideCategory(data: any): Observable<any> {
    let url = this.baseUrl + '/Admin/Vehicle/save-ride-type-category';
    url = url.replace(/[?&]$/, '');
    return this.http.post<any>(url, data).pipe(map((data) => data));;
  }


}
