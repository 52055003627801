
import { fileInputAvailableExtensions } from '@jameel/core/consts/settings';
import { VehiclePhotoType } from '@jameel/core/enums';
import { ProfileService } from '@jameel/core/services/profile.service';
import { ToastService } from '@jameel/core/services/toast.service';

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { changeVehicleDetails } from 'app/features/home/application-detail/store/actions';

@Component({
  selector: 'app-upload-car-photos',
  templateUrl: './upload-car-photos.component.html',
  styleUrls: ['./upload-car-photos.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: UploadCarPhotosComponent,
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadCarPhotosComponent {
  files: File[] = [];
  file: File;
  fileName: string;
  dropdownList: any[] = this.toArray(VehiclePhotoType);

  documentType: VehiclePhotoType;
  @Input() userExternalId: string;
  @Input() vehicleExternalId: string;
  @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;

  @Output() readonly onEdit = new EventEmitter();

  constructor(private toastService: ToastService,
    private profileService: ProfileService,
    private store: Store,
    private translateService: TranslateService) {
  }

  selectCarPhoto(vehiclePhotoType: VehiclePhotoType) {
    this.documentType = vehiclePhotoType;
  }

  onSelect(event: any) {
    this.files = [...event.addedFiles] as File[];
    const file = this.files[0];
    const valid = this.isFileValid(file);

    if (valid) {
      this.file = file;
      this.fileName = file?.name;
    }
  }

  onRemove(event: any) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  uploadPhoto() {
    if (!this.file) {
      this.toastService.showError('Add file and car photo');
    }
    const valid = this.isFileValid(this.file);

    // tslint:disable-next-line: early-exit
    if (valid && this.documentType) {
      this.profileService.uploadCarPhotos(this.userExternalId, this.vehicleExternalId,
        this.documentType, this.file).subscribe((result) => {
          this.toastService.showSuccess('File uploaded successfully');
          this.store.dispatch(changeVehicleDetails({ id: this.vehicleExternalId }));
          this.files = [];
          this.onEdit.emit('true');
          this.file = undefined;
          this.fileName = undefined;
          this.documentType = undefined;
          this.ngSelectComponent.handleClearClick();
        }, (error) => {
          this.toastService.showError('File uploaded failure');
        });
    } else {
      this.toastService.showError('Add file and car photo');
    }
  }

  private isFileValid(file: File) {
    return this.checkSize(file.size) && this.checkExtension(file.name);
  }

  private checkExtension(name: string) {
    const basename = name.split(/[\\/]/).pop(); // extract file name from full path ...
    const pos = basename.lastIndexOf('.'); // get last position of `.`

    if (basename === '' || pos < 1) {
      this.toastService.showError(
        this.translateService.instant('shared.error.wrong_extension') + fileInputAvailableExtensions.join(', '),
      );

      return false; //  `.` not found (-1) or comes first (0)
    }

    const ext = basename.slice(pos + 1);

    const isValid = fileInputAvailableExtensions.some((x) => x === ext.toLocaleLowerCase()); // Move to settings file or something like this

    if (!isValid) {
      this.toastService.showError(
        this.translateService.instant('shared.error.wrong_extension') + fileInputAvailableExtensions.join(', '),
      );
    }

    return isValid;
  }

  private checkSize(size: number) {
    const fileSize = Math.round(size / 1024);

    if (fileSize >= 10000) {
      this.toastService.showError(
        this.translateService.instant('shared.error.too_big'),
      );

      return false;
    }

    return true;
  }

  private toArray(enumme: any) {
    return Object.keys(enumme)
      .map((key) => enumme[key]);
  }
}
