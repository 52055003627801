<div class="container-scroller">
  <app-navbar *ngIf="showNavbar"></app-navbar>
  <div class="container-fluid page-body-wrapper"
       [ngClass]="{ 'full-page-wrapper': authPage }"
       #pageWrapper>
    <app-sidebar *ngIf="showSidebar"></app-sidebar>
    <div class="main-panel"
         [ngClass]="{ 'main-panel-only': !showSidebar, 'w-100': authPage }"
         #mainPanel>
      <div [ngClass]="{ 'content-wrapper-auth': authPage, 'p-0': errorPage, 'content-wrapper': !authPage }"
           #contentWrapper>
        <app-spinner *ngIf="isLoading"></app-spinner>
        <div *ngIf="!isLoading"
             [ngClass]="{ 'vh-100': isLogin }">
          <router-outlet></router-outlet>
        </div>
      </div>
      <app-footer *ngIf="showFooter"></app-footer>
      <!-- partial -->
    </div>
    <!-- main-panel ends -->
  </div>
  <!-- page-body-wrapper ends -->
</div>
<app-toasts aria-live="polite"
            aria-atomic="true"></app-toasts>

