<div *ngIf="additionalValidate === true && errors?.required" class="col-sm-12 ">
  <small class="text-danger">
    {{label}} {{'register.label.is_required' | translate}}
  </small>
</div>
<div *ngIf="additionalValidate === true && errors?.requiredFileType" class="col-sm-12 ">
  <small class="text-danger">
    {{'register.label.wrong_extension' | translate}} {{extensions}}
  </small>
</div>
<div *ngIf="errors?.rejected" class="col-sm-12 ">
  <small class="text-danger">
    {{'register.label.file_rejected' | translate}}
  </small>
</div>
<div *ngIf="additionalValidate === true && errors?.minlength" class="col-sm-12 ">
  <small class="text-danger">
    {{'shared.validation.min_length' | translate}} {{errors?.minlength?.requiredLength}}
  </small>
</div>
<div *ngIf="additionalValidate === true && errors?.maxlength" class="col-sm-12 ">
  <small class="text-danger">
    {{'shared.validation.max_length' | translate}} {{errors?.maxlength?.requiredLength}}
  </small>
</div>
<div *ngIf="additionalValidate === true && errors?.iban" class="col-sm-12 ">
  <small class="text-danger">
    {{'shared.validation.iban' | translate}}
  </small>
</div>
<div *ngIf="additionalValidate === true && errors?.yearLowerThan1900" class="col-sm-12 ">
  <small class="text-danger">
    {{'shared.validation.year_should_be_greater_than_1900' | translate}}
  </small>
</div>
<div *ngIf="additionalValidate === true && errors?.invalidNumber" class="col-sm-12 ">
  <small class="text-danger">
    {{'shared.validation.only_digits_allowed' | translate}}
  </small>
</div>