import { AuthService } from '../services/auth.service';
import { ToastService } from '../services/toast.service';

import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(public toastService: ToastService, private authService: AuthService,
    private translateService: TranslateService) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => event),
      catchError((error: HttpErrorResponse) => {
        if (error?.error?.hasErrors) {
          // client-side error
          error?.error?.errors.forEach((element) => {
            if (element?.message === 'InvalidOrExpiredRequestId') {
              this.toastService.showError(this.translateService.instant('error.invalid_or_expired_otp'));
            } else if (element?.code === 'invalidOtp') {
              this.toastService.showError(this.translateService.instant('error.invalid_otp'));
            } else if (element.message === 'OtpPhoneNumberBlockadeIsNotYetOver') {
              this.toastService.showError(this.translateService.instant('error.otp_blockade'));
            } else if (element.message === 'MissingDocument') {
              this.toastService.showError(this.translateService.instant('error.missing_documents'));
            } else {
              this.toastService.showError(`Error: Status: ${element.code} message ${element.message}`);
            }
          });
        } else {
          this.toastService.showError(`${this.translateService.instant('error.error_code')}:
          ${error.status}\n${this.translateService.instant('error.message')}: ${error.message}`);
        }

        return throwError(error);
      }));
  }
}
