<div class="col-6">
  <ngx-dropzone (change)="onSelect($event)"
                [multiple]="false">
    <ngx-dropzone-label>Drop document!</ngx-dropzone-label>
    <ngx-dropzone-preview *ngFor="let f of files"
                          [removable]="true"
                          (removed)="onRemove(f)">
      <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
    </ngx-dropzone-preview>
  </ngx-dropzone>
</div>

<div class="col-6">
  <ng-select class="auto-grow mb-3"
             placeholder="{{'captain.placeholder.select_car_photo' | translate}}"
             (change)="selectCarPhoto($event)">
    <ng-option *ngFor="let item of dropdownList"
               [value]="item">{{item}}
    </ng-option>
  </ng-select>
</div>

<div class="col-6 mb-3">
  <button class="btn btn-block btn-lg font-weight-medium btn-gradient-primary"
          (click)="uploadPhoto()">
    Upload document
  </button>
</div>