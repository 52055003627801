
import { PlateType } from '@jameel/core/enums';
import { ITranslateEnum } from '@jameel/core/models/translateEnumModel.model';

import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-plate-type',
  templateUrl: './plate-type.component.html',
  styleUrls: ['./plate-type.component.scss'],
})
export class PlateTypeComponent {
  @Input() editMode: boolean = false;
  @Input() name: string;
  @Input() form: FormGroup;
  @Input() title: string = this.translateService.instant('shared.label.fill_title');
  @Input() value = '';
  @Input() isFormSubmitted: boolean = false;
  get errors() {
    return this.form.controls[this.name].errors;
  }

  plateTypes: ITranslateEnum[] = [
    {display: this.translateService.instant('enum.plate_type.diplomatic'), value: PlateType.Diplomatic},
    {display: this.translateService.instant('enum.plate_type.export'), value: PlateType.Export},
    {display: this.translateService.instant('enum.plate_type.heavy_equipment'), value: PlateType.HeavyEquipment},
    {display: this.translateService.instant('enum.plate_type.motorcycle'), value: PlateType.Motorcycle},
    {display: this.translateService.instant('enum.plate_type.private'), value: PlateType.Private},
    {display: this.translateService.instant('enum.plate_type.private_minibus'), value: PlateType.PrivateMinibus},
    {display: this.translateService.instant('enum.plate_type.private_transport'), value: PlateType.PrivateTransport},
    {display: this.translateService.instant('enum.plate_type.public_minibus'), value: PlateType.PublicMinibus},
    {display: this.translateService.instant('enum.plate_type.public_transport'), value: PlateType.PublicTransport},
    {display: this.translateService.instant('enum.plate_type.taxi'), value: PlateType.Taxi},
    {display: this.translateService.instant('enum.plate_type.temporary'), value: PlateType.Temporary},
  ];

  constructor(private translateService: TranslateService) {}
}
