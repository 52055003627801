import { StoreFeatureNameEnum } from '@jameel/core/enums/';

import { IProfileModelState } from './models/profile-tabs.model';
import { profileTabsReducer, rideTypeCategoryListReducer } from './reducers/profile-tabs.reducer';

import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import { RideTypeCategoryListState } from 'app/features/captains/captains/captain-details/store/models/trips.model';

export const FEATURE_NAME = StoreFeatureNameEnum.ProfileTabs;
export const selectProfileTabs = createFeatureSelector<IProfileState>(
  FEATURE_NAME,
);
export const reducers: ActionReducerMap<IProfileState> = {
  profileDetails: profileTabsReducer,
  profileRideCategory: rideTypeCategoryListReducer,
};

export interface IProfileState {
  profileDetails: IProfileModelState;
  profileRideCategory: RideTypeCategoryListState
}
