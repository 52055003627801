import { createAction, props } from '@ngrx/store';

export const setAuthPage = createAction(
  `[PAGE] Set Auth Page`,
  props<{ authPage: boolean }>(),
);

export const setErrorPage = createAction(
  `[PAGE] Set Error Page`,
  props<{ errorPage: boolean }>(),
);

export const setFetching = createAction(
  `[PAGE] Set Fetching`,
  props<{ isFetching: boolean }>(),
);
