import { IVehicleResponseModel } from '@jameel/core/models/';

import { createAction, props } from '@ngrx/store';

export const actionAddCar = createAction('[Register Form] Add a car', props<{ carInsurance: FormData, carRegistration: FormData, drivingAuthorization: FormData }>());

export const actionAddCarSuccess = createAction(
  '[Register Form] Add a car success', props<{
    data: IVehicleResponseModel,
    files: { carInsurance: FormData, carRegistration: FormData, drivingAuthorization: FormData },
  }>(),
);

export const actionAddCarFailure = createAction(
  '[Register Form] Add a car failure', props<{ error: string }>(),
);
