<div class="h-100 position-relative">

  <div class=" auth px-0 ">
    <div class="row w-100 mx-0 no-gutters mt-md-5 mt-lg-5 mt-xl-1">
      <div class="col-12 col-md-8 col-xl-4 mx-auto">
        <div class="mb-0">
          <div class="col mx-auto mb-5 mt-5">
            <img class="d-block mx-auto w-100" src="../../../assets/images/auth/logo.png">
          </div>
          <div class="col mx-auto mb-5 mt-5">
            <img *ngIf="lang === 'ar'" class="d-block mx-auto w-100 pt-2 pt-lg-5 pt-lg-3"
              src="../../../assets/images/auth/type_landscape.svg">
            <img *ngIf="lang === 'en'" class="d-block mx-auto w-100 pt-2 pt-lg-5 pt-lg-3"
              src="../../../assets/images/auth/ar-subtitile.svg">
          </div>
        </div>
        <div>
          <div class="auth-form-light text-left py-1 ">

            <form [formGroup]="loginForm" (ngSubmit)="login()">

              <div class="form-group position-relative mb-0">
                <img class="input-image " src="../../../assets/images/auth/input-image.png" />
                <div class="auth-form-light__header center-desktop position-relative">
                  <h5>{{'auth.login.phone_number' | translate}}</h5>
                  <h5 (click)="changeLang()">{{lang}}</h5>
                </div>

                <div class="input-group center-desktop" [attr.dir]="lang == 'en' ? 'ltr' : null" [ngClass]="{'ar-lang-left': lang == 'en'}">
                  <div class="input-group-prepend">
                    <span class="input-group-text">+966</span>
                  </div>
                  <input type="tel" class="form-control form-control-lg  " id="phone" formControlName="phone">
                  <div *ngIf="isSubmitted && phone.errors?.required" class="col-sm-12 ">
                    <small>
                      {{ 'auth.login.phone_number_is_required' | translate }}
                    </small>
                  </div>
                  <div *ngIf="isSubmitted &&( phone.errors?.maxlength || 
                    phone.errors?.minlength)" class="col-sm-12 ">
                    <small>
                      {{'auth.error.number' | translate}}
                    </small>
                  </div>
                  <div *ngIf="isSubmitted &&phone.errors?.phone" class="col-sm-12  ">
                    <small>
                      {{ 'auth.login.please_provide_a_valid_phone_number' | translate}}
                    </small>
                  </div>
                </div>
              </div>
              <div class="mt-1 center-desktop">
                <button class="btn btn-block btn-primary btn-lg font-weight-bold auth-form-btn  m-0">
                  {{ 'auth.login.sign_in' | translate }}
                </button>
              </div>
              

            </form>
            <div class="mt-1 center-desktop">
              <button class="help btn btn-block btn-primary btn-sm font-weight-bold auth-form-btn  m-2">
                {{ 'contact.support' | translate }}
                <a href='https://wa.me/966114414454' target='_blank'>
                  <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAABmJLR0QA/wD/AP+gvaeTAAABvUlEQVRIib2Wv2tUQRRGz2xCVCyEFRsbsfVXLeImaETtFiH/i7WphIBiYaGVFqKlSR1QkSzEGBvRykZkYywSKyUE47F4szo+Ji9vn4tfNXC/mTN37p33Bv6DQlVQbQEdoAtMAkeBw8AGsAa8AOaBlyEEh6arl9U31tOqemmYxcfUmzUXL+ueOr4XYFxdaAgYaF4dq4Lc+UfAQLeqajBKTZcBLfNFvh5jNxpAXqshhUxlTEtJvK1+bwDqALTiOt3MCfYGgxDCJvCksmvy6qaQTsbQTjIJFBdxWE3+HqlrmVTX1f0xPtPgqFT7KWR7F9NsjB9U3zeAbKWQr7uYfqhXoueY+rEU/6z+rID8lclqhXFTPRl9bfVxXPiVekA9oz5SdzJzl1PI3QqI6oZ6LvGfUo+kFVZvZ+bNpYbpPSCqWxaXc1+ujdRnmTnnU0NQ39YAqX5SZ9XT6oR6Qn2Y8a2Y3vgIelATUlcXyoCg9kcImKMsiw4ZlZ5a+p8MPitXc8VsoPvATAhhJ5fJYmk322pPfVdz9yuWa5AoRMgHYB14TvEC6YUQvll0x0XgGnAWOA4cAr4Aff68VpYavVZGqV/B4ZLtpiGO2gAAAABJRU5ErkJggg==">
                </a>
              </button>
            </div>
                

          </div>
        </div>
      </div>


    </div>
  </div>
  <img class="login-image-bottom d-block d-sm-none" src="../../../assets/images/auth/bg_img.svg" />
  <img class="login-image-bottom d-none d-sm-block " src="../../../assets/images/auth/bg_land.svg" />
</div>