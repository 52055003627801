import { IAppState } from '../core.state';

import {
  selectSettingsLanguage,
} from './settings.selectors';

import { Injectable } from '@angular/core';
import { createEffect } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
  distinctUntilChanged,
  tap,
} from 'rxjs/operators';

export const SETTINGS_KEY = 'SETTINGS';

@Injectable()
export class SettingsEffects {
  setTranslateServiceLanguage = createEffect(
    () =>
      this.store.pipe(
        select(selectSettingsLanguage),
        distinctUntilChanged(),
        tap((language) =>
          this.translateService.use(language),
        ),
      ),
    { dispatch: false },
  );
  constructor(
    private store: Store<IAppState>,
    private translateService: TranslateService) { }
}
