
import { CheckCarStatus } from '@jameel/core/enums/';
import { ITranslateEnum } from '@jameel/core/models/translateEnumModel.model';

import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-check-car',
  templateUrl: './check-car.component.html',
  styleUrls: ['./check-car.component.scss'],
})

export class CheckCarComponent {
  @Input() editMode: boolean = false;
  @Input() name: string;
  @Input() form: FormGroup;
  @Input() title: string = this.translateService.instant('shared.label.fill_title');
  @Input() value = '';
  @Input() isFormSubmitted: boolean = false;

  get errors() {
    return this.form.controls[this.name].errors;
  }

  status: ITranslateEnum[] = [
    { display: this.translateService.instant('enum.check_car_status.approved'), value: CheckCarStatus.Approved },
    { display: this.translateService.instant('enum.check_car_status.denied'), value: CheckCarStatus.Denied },
    { display: this.translateService.instant('enum.check_car_status.pending'), value: CheckCarStatus.Pending },
  ];

  constructor(private translateService: TranslateService) { }
}
