import { AfterViewInit, Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-form-table-item',
  templateUrl: './form-table-item.component.html',
})
export class FormTableItemComponent {
  @Input() editMode: boolean = false;
  @Input() isFormSubmitted: boolean = false;
  @Input() name: string;
  @Input() form: FormGroup;
  @Input() title: string = this.translateService.instant('shared.label.fill_title');
  @Input() type: string = 'text';
  @Input() mask: string = '';
  @Input() value: any;

  get errors() {
    return this.form.controls[this.name].errors;
  }

  constructor(private translateService: TranslateService) { }
}
