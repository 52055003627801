export const environment = {
  production: true,
  env_name: 'prod',
  APP_VERSION: require('../../package.json').version + '-prod',
  API_BASE_URL: 'https://api.jameel-app.com',
  BLOB_BASE_URL: 'https://jameelsge.blob.core.windows.net',
  i18nPrefix: '',
  ZENDESK_URL: 'https://jameelksa.zendesk.com',
  ZENDESK_URL_RIDER: 'https://jameelksa.zendesk.com/hc/ar',
  GOOGLE_KEY: 'AIzaSyBqUfdJXXLg2XIacExP17SIx3bUorVxSIM',
  MAPBOX_KEY:'pk.eyJ1Ijoic3dpZnQ5MiIsImEiOiJjbHB4eHl5aHMwdHNqMm1wZm82eXJpbGZzIn0.NVEee7BW9qLQBZ7dmB6q5A',
};
