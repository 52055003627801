import { fileInputAvailableExtensions } from '@jameel/core/consts/settings';
import { InputFileActions } from '@jameel/core/enums/';
import { ToastService } from '@jameel/core/services/toast.service';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-file',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FileUploadComponent,
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileUploadComponent implements ControlValueAccessor {
  @Input() progress;
  @Input() label: string;
  @Input() hide: boolean;
  @Input() isValid: boolean = false;
  @Input() lockLabel: string = 'register.label.approved';
  @Input() set action(triggerAction: InputFileActions) {
    // tslint:disable-next-line: early-exit
    if (triggerAction === InputFileActions.PickFile) {
      this.interval = setInterval(() => {
        if (this.fileInput) {
          this.fileInput.nativeElement.click();
          clearInterval(this.interval);
        }
      }, 10);
    }
  }
  @Input() set lockAlreadyUploaded(value: boolean) {
    this.lockUploaded = value;

    if (value) {
      this.isLocked = this.fileName && !this.newFileLoaded ? true : false;
    } else {
      this.isLocked = false;
    }
  }

  @ViewChild('fileInput') fileInput: ElementRef;

  onChange: Function;
  fileName: string = '';
  interval: any;
  file: File | null = null;
  newFileLoaded: boolean = false;
  lockUploaded: boolean = false;
  isLocked: boolean = false;

  constructor(
    private host: ElementRef<HTMLInputElement>,
    private changeDetector: ChangeDetectorRef,
    private toastService: ToastService,
    private translateService: TranslateService,
    private modalService: NgbModal,
  ) { }

  @HostListener('change', ['$event.target.files']) async emitFiles(event: FileList) {
    this.newFileLoaded = true;
    this.isLocked = false;

    const file = event && event.item(0);
    const valid = this.isFileValid(file);

    if (valid) {
      const compressedFile = await this.compressFile(file);
      this.file = compressedFile;
      this.fileName = compressedFile?.name;
      this.onChange(compressedFile);
    } else {
      this.clearInput();
      this.onChange(null);
      this.changeDetector.markForCheck();
    }
  }

  writeValue(value: File | null) {
    if (!value) {
      this.isLocked = false;
      this.clearInput();

      return;
    }
    this.host.nativeElement.value = '';
    this.file = value;
    this.fileName = value.name.substring(value.name.lastIndexOf('/') + 1);

    if (this.lockUploaded) {
      this.isLocked = (this.fileName && !this.newFileLoaded) ? true : false;
    } else {
      this.isLocked = false;
    }

    this.changeDetector.markForCheck();
  }

  registerOnChange(fn: Function) {
    this.onChange = fn;
  }

  registerOnTouched(fn: Function) { }

  openPreviewModal(previewModal: TemplateRef<NgbModal>, pdfModal: TemplateRef<NgbModal>) {
    if (this.fileName.endsWith('pdf')) {
      this.modalService.open(pdfModal, { windowClass: 'modal-responsive' });
    } else {
      this.modalService.open(previewModal, { windowClass: 'modal-responsive' });
    }
  }

  downloadPdf() {
    window.open(this.file.name, '_blank');
  }

  private clearInput() {
    this.host.nativeElement.value = '';
    this.file = null;
    this.fileName = '';
  }

  private isFileValid(file: File) {
    return this.checkSize(file.size) && this.checkExtension(file.name);
  }

  private checkExtension(name: string) {
    const basename = name.split(/[\\/]/).pop(); // extract file name from full path ...
    const pos = basename.lastIndexOf('.'); // get last position of `.`

    if (basename === '' || pos < 1) {
      this.toastService.showError(
        this.translateService.instant('shared.error.wrong_extension') + fileInputAvailableExtensions.join(', '),
      );

      return false; //  `.` not found (-1) or comes first (0)
    }

    const ext = basename.slice(pos + 1);

    const isValid = fileInputAvailableExtensions.some((x) => x === ext.toLocaleLowerCase()); // Move to settings file or something like this

    if (!isValid) {
      this.toastService.showError(
        this.translateService.instant('shared.error.wrong_extension') + fileInputAvailableExtensions.join(', '),
      );
    }

    return isValid;
  }

  private checkSize(size: number) {
    const fileSize = Math.round(size / 1024);

    if (fileSize >= 10024) {
      this.toastService.showError(
        this.translateService.instant('shared.error.too_big'),
      );

      return false;
    }

    return true;
  }

  private async compressFile(file: File): Promise<File | null> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        const img = new Image();
        img.src = e.target.result;

        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          // Set the canvas dimensions to the image dimensions
          canvas.width = img.width;
          canvas.height = img.height;

          // Draw the image on the canvas
          ctx.drawImage(img, 0, 0);

          // Compress the image and convert it back to a File
          canvas.toBlob(
            async (blob) => {
              if (blob) {
                const compressedFile = new File([blob], file.name, { type: file.type });
                // Check the size of the compressed file
                if (this.checkSize(compressedFile.size)) {
                  resolve(compressedFile);
                } else {
                  reject(new Error('Compressed file is too large.'));
                }
              } else {
                reject(new Error('Blob creation failed.'));
              }
            },
            file.type,
            0.7 // Compression quality (0.7 means 70%)
          );
        };

        img.onerror = (error) => {
          reject(error);
        };
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  }

}
