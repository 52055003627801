import { AuthenticationResponseModel, IUserLoginResponseModel } from '../models/';

import { Injectable } from '@angular/core';
import * as jwt_decode from 'jwt-decode';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  private static readonly APP_PREFIX = 'JAMEEL-';
  private static readonly USER_INFO = 'USER_INFO';
  private static readonly CAPTAIN_OTP = 'CAPTAIN_OTP';
  private static readonly USER_PRIV = 'USER_PRIV';
  roles : string[] = [];
  public relolad = new BehaviorSubject<number>(0);


  static loadInitialState() {
    return Object.keys(localStorage).reduce((state: any, storageKey) => {
      if (storageKey.includes(LocalStorageService.APP_PREFIX)) {
        const stateKeys = storageKey
          .replace(LocalStorageService.APP_PREFIX, '')
          .toLowerCase()
          .split('.')
          .map((key) =>
            key
              .split('-')
              .map((token, index) =>
                index === 0
                  ? token
                  : token.charAt(0).toUpperCase() + token.slice(1),
              )
              .join(''),
          );
        let currentStateRef = state;

        stateKeys.forEach((key, index) => {
          if (index === stateKeys.length - 1) {
            currentStateRef[key] = JSON.parse(LocalStorageService.getItem(storageKey));

            return;
          }
          currentStateRef[key] = currentStateRef[key] || {};
          currentStateRef = currentStateRef[key];
        });
      }

      return state;
    }, {});
  }

  static getItem(key: string) {
    return JSON.parse(localStorage.getItem((`${key}`)));
  }

  /** Tests that localStorage exists, can be written to, and read from. */
  testLocalStorage() {
    const testValue = 'testValue';
    const testKey = 'testKey';
    let retrievedValue: string;
    const errorMessage = 'localStorage did not return expected value';

    this.setItem(testKey, testValue);
    retrievedValue = this.getItem(testKey);
    this.removeItem(testKey);

    if (retrievedValue !== testValue) {
      throw new Error(errorMessage);
    }
  }

  public get getUserInfo() {
    return JSON.parse(this.getItem(LocalStorageService.USER_INFO)) as AuthenticationResponseModel;
  }

  public get getDecodedJWT() {
    const data = JSON.parse(this.getItem(LocalStorageService.USER_INFO)) as AuthenticationResponseModel;

    return data?.tokens?.token ? jwt_decode(data.tokens.token) : null;
  }

  public get getUserRole() {
    const decodedJWT = this.getDecodedJWT;

    if (!decodedJWT) {
      return;
    }

    for (const key in decodedJWT) {
      if (decodedJWT.hasOwnProperty(key) && key.includes('role')) {
        return decodedJWT[key];
      }
    }

    return null;
  }

  public get isAdmin() {
    if(Array.isArray(this.getUserRole)){
      this.roles = this.getUserRole;
      return this.roles.includes('Administrator');
    }
    else{
      return this.getUserRole === 'Administrator';
    }
  }

  public get isLimited() {
    if(Array.isArray(this.getUserRole)){
      this.roles = this.getUserRole;
      return this.roles.includes('Limited');
    }
    else{
      return this.getUserRole === 'Limited';
    }
  }

  public get isLimitedCaptain() {
    if(Array.isArray(this.getUserRole)){
      this.roles = this.getUserRole;
      return this.roles.includes('LimitedCaptain');
    }
    else{
      return this.getUserRole === 'LimitedCaptain';
    }
  }
  
  public get isLimitedJameelEye() {
    if(Array.isArray(this.getUserRole)){
      this.roles = this.getUserRole;
      return this.roles.includes('LimitedJameelEye');
    }
    else{
      return this.getUserRole === 'LimitedJameelEye';
    }
  }
  
  public get isLimitedTrips() {
    if(Array.isArray(this.getUserRole)){
      this.roles = this.getUserRole;
      return this.roles.includes('LimitedTrips');
    }
    else{
      return this.getUserRole === 'LimitedTrips';
    }
  }
  
  public get isLimitedCustomer() {
    if(Array.isArray(this.getUserRole)){
      this.roles = this.getUserRole;
      return this.roles.includes('LimitedCustomer');
    }
    else{
      return this.getUserRole === 'LimitedCustomer';
    }
  }
  
  public get isLimitedFinances() {
    if(Array.isArray(this.getUserRole)){
      this.roles = this.getUserRole;
      return this.roles.includes('LimitedFinances');
    }
    else{
      return this.getUserRole === 'LimitedFinances';
    }
  }
  
  public get isLimitedApplications() {
    if(Array.isArray(this.getUserRole)){
      this.roles = this.getUserRole;
      return this.roles.includes('LimitedApplications');
    }
    else{
      return this.getUserRole === 'LimitedApplications';
    }
  }

  public get isLimitedNotifications() {
    if(Array.isArray(this.getUserRole)){
      this.roles = this.getUserRole;
      return this.roles.includes('LimitedNotifications');
    }
    else{
      return this.getUserRole === 'LimitedNotifications';
    }
  }

  public get isCaptain() {
    if(Array.isArray(this.getUserRole)){
      this.roles = this.getUserRole;
      return this.roles.includes('Captain');
    }
    else{
      return this.getUserRole === 'Captain';
    }
  }

  setItem(key: string, value: any) {
    localStorage.setItem(`${LocalStorageService.APP_PREFIX}${key}`, JSON.stringify(value));
  }

  getItem(key: string) {
    return JSON.parse(localStorage.getItem((`${LocalStorageService.APP_PREFIX}${key}`)));
  }

  removeItem(key: string) {
    localStorage.removeItem(`${LocalStorageService.APP_PREFIX}${key}`);
  }

  public clearLocalStorage() {
    localStorage.clear();
  }

  public clearUserInfo() {
    this.removeItem(LocalStorageService.USER_INFO);
  }

  public setUserInfo(value: AuthenticationResponseModel) {
    this.setItem(LocalStorageService.USER_INFO, JSON.stringify(value));
  }

  public setCaptainOtp(value: IUserLoginResponseModel) {
    this.setItem(LocalStorageService.CAPTAIN_OTP, JSON.stringify(value));
  }

  public getCaptainOtp(): IUserLoginResponseModel {
    return JSON.parse(this.getItem(LocalStorageService.CAPTAIN_OTP)) as IUserLoginResponseModel;
  }

  public removeCaptainOtp(): void {
    this.removeItem(LocalStorageService.CAPTAIN_OTP);
  }

  public userHaveAccess(privialge): boolean {
    var access = false;
    var userAccess = this.getUserPrivilages;
    if (
      userAccess.filter((t) => t.toLowerCase() == "all")
        .length > 0
    )
    {
      access = true;
    }
    else{
    if (!this.isAdmin) {
      if (userAccess) {
        if (
          userAccess.filter((t) => t.toLowerCase() == privialge.toLowerCase())
            .length > 0
        ) {
          access = true;
        }
        else{
          access = false;
        }
      }
    }
    else if(this.isAdmin)
    {
      access = true;
    }
  }
    return access;
  }

  public get isOperation_Op() {
    if(Array.isArray(this.getUserRole)){
      this.roles = this.getUserRole;
      return this.roles.includes('OPERATION_OP');
    }
    else{
      return this.getUserRole === 'OPERATION_OP';
    }
  }

  public get isOperation_Super() {
    if(Array.isArray(this.getUserRole)){
      this.roles = this.getUserRole;
      return this.roles.includes('OPERATION_SUPER');
    }
    else{
      return this.getUserRole === 'OPERATION_SUPER';
    }
  }

  public get isMarketing_Op() {
    if(Array.isArray(this.getUserRole)){
      this.roles = this.getUserRole;
      return this.roles.includes('MARKETING_OP');
    }
    else{
      return this.getUserRole === 'MARKETING_OP';
    }
  }

  public get isMarketing_Super() {
    if(Array.isArray(this.getUserRole)){
      this.roles = this.getUserRole;
      return this.roles.includes('MARKETING_SUPER');
    }
    else{
      return this.getUserRole === 'MARKETING_SUPER';
    }
  }

  public get isIt_Op() {
    if(Array.isArray(this.getUserRole)){
      this.roles = this.getUserRole;
      return this.roles.includes('IT_OP');
    }
    else{
      return this.getUserRole === 'IT_OP';
    }
  }

  public get isIt_Super() {
    if(Array.isArray(this.getUserRole)){
      this.roles = this.getUserRole;
      return this.roles.includes('IT_SUPER');
    }
    else{
      return this.getUserRole === 'IT_SUPER';
    }
  }

  public get isFinance_Op() {
    if(Array.isArray(this.getUserRole)){
      this.roles = this.getUserRole;
      return this.roles.includes('FINANCE_OP');
    }
    else{
      return this.getUserRole === 'FINANCE_OP';
    }
  }

  public get isFinance_Super() {
    if(Array.isArray(this.getUserRole)){
      this.roles = this.getUserRole;
      return this.roles.includes('FINANCE_SUPER');
    }
    else{
      return this.getUserRole === 'FINANCE_SUPER';
    }
  }

  public get getUserPrivilages() {
    return JSON.parse(JSON.parse(localStorage.getItem((LocalStorageService.USER_PRIV)))) as any[];
  }

  setUserPrivilages(value: any) {
    localStorage.setItem(`${LocalStorageService.USER_PRIV}`, JSON.stringify(value));
    this.relolad.next(1);
  }

}
