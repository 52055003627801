import { DrivingLicenseType } from '@jameel/core/enums/';
import { ITranslateEnum } from '@jameel/core/models/translateEnumModel.model';

import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-form-driving-license-type',
  templateUrl: './form-driving-license-type.component.html',
  styleUrls: ['./form-driving-license-type.component.scss'],
})
export class FormDrivingLicenseTypeComponent {
  @Input() editMode: boolean = false;
  @Input() name: string;
  @Input() form: FormGroup;
  @Input() title: string = this.translateService.instant('shared.label.fill_title');
  @Input() type: string = 'text';
  @Input() isFormSubmitted: boolean = false;
  get errors() {
    return this.form.controls[this.name].errors;
  }

  driversTypes: ITranslateEnum[] = [
    {display: this.translateService.instant('enum.license_type.light_transport'), value: DrivingLicenseType.LightTransport},
    {display: this.translateService.instant('enum.license_type.private'), value: DrivingLicenseType.Private},
    {display: this.translateService.instant('enum.license_type.public_taxi'), value: DrivingLicenseType.PublicTaxi},
    {display: this.translateService.instant('enum.license_type.small_bus'), value: DrivingLicenseType.SmallBus},
  ];
  constructor(private translateService: TranslateService) { }
}
