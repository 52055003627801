import * as c from './components';
import * as p from './pipes';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { LightboxModule } from 'ngx-lightbox';
import { NgxMaskModule } from 'ngx-mask';
import { FormCalendarTimeComponent } from './components/form-calendar-time/form-calendar-time.component';
import { FormTableItemInputComponent } from './components/form-table-item-input/form-table-item-input.component';

const Components = [
  c.BaseTableComponent,
  c.DisplayValidationComponent,
  c.DropdownComponent,
  c.FileUploadComponent,
  c.TableFiltersComponent,
  c.SvgIconComponent,
  c.SpinnerComponent,
  c.CarouselComponent,
  c.FormTableItemComponent,
  c.FormTableItemCheckComponent,
  c.FormGenderComponent,
  c.FormCalendarComponent,
  c.FormTimePickerComponent,
  c.FormDrivingLicenseTypeComponent,
  c.FormSelectComponent,
  c.FormSelectChangeComponent,
  c.FormCheckboxComponent,
  c.AvatarComponent,
  c.PlateTypeComponent,
  c.OwnershipComponent,
  c.CheckCarComponent,
  c.CityFilterComponent,
  c.ExportCsvComponent,
  c.CitySelectComponent,
  c.RideTypesFilterComponent,
  c.UploadDocumentComponent,
  c.UploadDocumentVehicleComponent,
  c.UploadCarPhotosComponent,
  c.FormCalendarTimeComponent,
  c.FormCalendarIslamicComponent,
  c.FormTableItemInputComponent
];

const Pipes = [
  p.SplitCamelCasePipe,
  p.YesNoPipe,
];

@NgModule({
  declarations: [...Components, ...Pipes, FormCalendarTimeComponent],
  exports: [
    ...Components,
    ...Pipes,
    CommonModule,
    FormsModule,
    NgbModule,
    ReactiveFormsModule,
    TranslateModule,
    NgSelectModule,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgxMaskModule,
    LightboxModule,
    NgxDropzoneModule,
  ],
})
export class SharedModule { }
