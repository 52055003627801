<ng-container *ngIf="list;else spinner">
  <ngb-carousel *ngIf="file">
    <ng-template ngbSlide>
      <div class="position-relative">
        <div>
          <div class="picsum-img-wrapper" style="min-height: 20rem;">
            <img *ngIf="!file.fileUrl.includes('.pdf')" [src]="file.fileUrl" (click)="openModal(zoomModal, true)"
              alt="Random first slide">
            <div class="pdf" *ngIf="file.fileUrl.includes('.pdf')">
              <a [attr.href]="file.fileUrl">
                <div class="pdf-wrapper">
                  <i class="mdi mdi-arrow-down-bold-circle color-main pdf-icon"></i>
                  <p>{{'carousel.shared.download' | translate}}</p>
                </div>
              </a>
            </div>
          </div>
          <div class="rejected" *ngIf="file.status === 'Rejected'">
            <div class="w-100 h-100 p-1">
              <div class="header pt-3 pb-2 text-light">
                <h5>{{'home.label.document.rejected' | translate}}</h5>
              </div>
              <div class="p-3 bg-white">{{file.rejectionReason}}</div>
            </div>
          </div>
        </div>
        <div class="carousel-caption" *ngIf="file.status === 'WaitingForApproval'">
          <div class="btn-group" role="group" aria-label="Basic example">
            <button type="button" class="btn btn-gradient-success mr-3" (click)="openModal(approveModal)">{{
              'home.label.approve' | translate }}</button>
            <button type="button" class="btn btn-gradient-primary" (click)="openModal(rejectModal)">{{
              'home.label.reject' | translate}}</button>
          </div>
        </div>

      </div>

    </ng-template>
  </ngb-carousel>

  <ng-template #zoomModal let-modal class="modal fade" aria-labelledby="zoomModal">
    <div class="modal-header">
      <button type="button" class="close" (click)="modal.dismiss()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <img [src]="file.fileUrl">
    </div>
  </ng-template>

  <ng-template #rejectModal let-modal class="modal fade" aria-labelledby="exampleModalLabel-3">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel-3">{{ 'home.label.reject_reason' | translate }}</h5>
        <button type="button" class="close" (click)="modal.dismiss()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <ng-select class="auto-grow mb-3" [(ngModel)]="selectedValue"
          placeholder="{{'home.placeholder.reason' | translate}}">
          <ng-option *ngFor="let item of list" [value]="item">{{item.name}}</ng-option>
        </ng-select>
        <div class="form-group mb-0">
          <div class="input-group ">
            <textarea class="form-control form-control-lg" id="notes" [(ngModel)]="notes"
              placeholder="{{ 'home.placeholder.notes' | translate }}"></textarea>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="btn-group" role="group" aria-label="Basic example">
          <button type="button" class="btn btn-light mr-2" (click)="modal.close()">{{ 'home.button.not_reject' |
            translate }}</button>
          <button type="button" class="btn btn-gradient-primary " (click)="reject()">
            {{ 'home.button.reject_document' | translate }}
          </button>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #approveModal let-modal class="modal fade" aria-labelledby="exampleModalLabel-3">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel-3">{{ 'home.label.approve_confirmation' | translate }}?
        </h5>
        <button type="button" class="close" (click)="modal.dismiss()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-footer">
        <div class="btn-group" role="group" aria-label="Basic example">
          <button type="button" class="btn btn-light mr-2" (click)="modal.dismiss()">{{ 'home.button.close' | translate
            }}</button>
          <button type="button" class="btn btn-gradient-success " (click)="approve()">{{ 'auth.sing_in.confirm' |
            translate }}</button>
        </div>

      </div>
    </div>
  </ng-template>
</ng-container>

<ng-template #spinner>
  <app-spinner></app-spinner>
</ng-template>