<footer class="footer">
  <div class="d-sm-flex justify-content-center justify-content-sm-between">
    <span class="text-muted text-center text-sm-left d-block d-sm-inline-block"><a target="_blank"></a></span>
    <span
      class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">{{ 'shared.footer.description' | translate }}
      <i class="mdi mdi-heart text-danger"></i></span>

      <a href='https://wa.me/966583345521' target='_blank'><i class= "fa fa-whatsapp"></i></a>
  </div>
</footer>
