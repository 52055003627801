import { IAppState } from '@jameel/core/core.state';

import { LocalStorageService } from '../services/local-storage.service';

import { ActionReducer, INIT, UPDATE } from '@ngrx/store';

export const initStateFromLocalStorage = (
  reducer: ActionReducer<IAppState>,
): ActionReducer<IAppState> => (state, action) => {
  const newState = reducer(state, action);

  const init = LocalStorageService.loadInitialState();
  const auth = init.user_info ? { user: init.user_info } : null;

  const lang = init?.lang ? { settings: { language: init.lang.lang } } : null;
  if ([INIT.toString(), UPDATE.toString()].includes(action.type)) {
    let initState = auth ? { ...newState, ...init, auth } : { ...newState, ...init };

    return initState = lang ? { ...initState, ...lang } : { ...initState };
  }

  return newState;
};
