import { Gender } from '@jameel/core/enums/';
import { ITranslateEnum } from '@jameel/core/models/translateEnumModel.model';

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-form-gender',
  templateUrl: './form-gender.component.html',
  styleUrls: ['./form-gender.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormGenderComponent {
  @Input() editMode: boolean = false;
  @Input() name: string;
  @Input() form: FormGroup;
  @Input() type: string = 'text';
  @Input() title: string = this.translateService.instant('shared.label.fill_title');
  @Input() isFormSubmitted: boolean = false;
  get errors() {
    return this.form.controls[this.name].errors;
  }
  genders: ITranslateEnum[] = [
    { display: this.translateService.instant('enum.gender.female'), value: Gender.Female },
    { display: this.translateService.instant('enum.gender.male'), value: Gender.Male },
    { display: this.translateService.instant('enum.gender.prefer_not_say'), value: Gender.PreferNotSay },
  ];
  constructor(private translateService: TranslateService) { }
}
