import { InputFileActions } from '@jameel/core/enums/';
import { ToastService } from '@jameel/core/services/toast.service';
import { AvatarService } from '@jameel/shared/components/avatar/avatar.service';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarComponent implements OnInit, OnDestroy {
  @Input() imgUrl: string;
  @Input() externalId: string = null;
  @Input() isHidden: boolean = false;

  form: FormGroup = this.fb.group({
    avatarImg: ['', [Validators.required]],
  });

  action: InputFileActions = null;
  private sub = new Subscription();

  constructor(
    private changeRef: ChangeDetectorRef,
    private fb: FormBuilder,
    private avatarService: AvatarService,
    private toastService: ToastService,
    private translateService: TranslateService,
  ) { }

  ngOnInit(): void {
    this.sub.add(
      this.form.valueChanges.subscribe(() => this.submit()),
    );
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  editAvatar(): void {
    this.action = InputFileActions.PickFile;
  }

  prepareFormData(file: File): FormData {
    const formData = new FormData();

    formData.append('request', file);

    return formData;
  }

  submit(): void {
    this.action = null;

    if (this.form.invalid) {
      this.toastService.showError(
        this.translateService.instant('register.label.invalid'),
      );

      return;
    }
    if (!!this.externalId) {
      this.sub.add(this.avatarService
        .uploadUserAvatar(
          this.prepareFormData(this.form.controls.avatarImg.value),
          this.externalId,
        )
        .subscribe((response: null) => {
          // @ts-ignore
          this.imgUrl = response.data.avatarUrl;
          this.changeRef.markForCheck();
        }),
      );
    } else {
      this.sub.add(this.avatarService
        .uploadAvatar(
          this.prepareFormData(this.form.controls.avatarImg.value),
        )
        .subscribe((response: null) => {
          // @ts-ignore
          this.imgUrl = response.data.avatarUrl;
          this.changeRef.markForCheck();
        }),
      );
    }
  }
}
