<div class="avatar-box">
  <img class="w-100" src="../../../../assets/images/profile.jpg" *ngIf="!imgUrl" />
  <img class="w-100" [src]="imgUrl" *ngIf="!!imgUrl" />
  <div class="edit" [hidden]="isHidden" (click)="editAvatar()">
    <i class="mdi mdi-lead-pencil"></i>
    <form class="form-sample" [formGroup]="form" (ngSubmit)="submit()">
      <app-file formControlName="avatarImg" [hide]="true" [label]="" [action]="action"></app-file>
    </form>
  </div>
</div>
