import { UserLoginWriteServiceModel } from '@jameel/core/models/';
import { LocalStorageService } from '@jameel/core/services/local-storage.service';
import { ToastService } from '@jameel/core/services/toast.service';
import { actionSettingsChangeLanguage } from '@jameel/core/settings/settings.actions';
import { selectSettingsLanguage } from '@jameel/core/settings/settings.selectors';

import { authLogin } from '../store/auth.actions';

import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-login-captain',
  templateUrl: './login-captain.component.html',
  styleUrls: ['./login-captain.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginCaptainComponent implements OnInit, OnDestroy {
  loginForm: FormGroup = this.fb.group({
    phone: ['', [Validators.minLength(9), Validators.maxLength(9), Validators.required]],
  });
  isSubmitted: boolean = false;
  referralCode: string = '';
  lang: 'ar' | 'en';
  priv:any=[];
  private sub = new Subscription();

  constructor(private fb: FormBuilder,
    private route: ActivatedRoute,
    private store: Store,
    private localStorageService: LocalStorageService,
    private toastService: ToastService,
    private translateService: TranslateService) { }

  ngOnInit(): void {
    this.priv=[];
    this.localStorageService.setUserPrivilages(JSON.stringify(this.priv));

    this.sub.add(this.store.select(selectSettingsLanguage)
      .subscribe((result) => {
        this.lang = result as 'ar' | 'en' === 'en' ? 'ar' : 'en';
      }));
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  changeLang() {
    if (this.lang === 'ar') {
      this.sub.add(this.store.dispatch(actionSettingsChangeLanguage({ language: 'ar' })));
      this.localStorageService.setItem('lang', JSON.stringify({ lang: 'ar' }));
    } else {
      this.sub.add(this.store.dispatch(actionSettingsChangeLanguage({ language: 'en' })));
      this.localStorageService.setItem('lang', JSON.stringify({ lang: 'en' }));
    }
  }

  get phone() {
    return this.loginForm.get('phone');
  }

  login() {
    this.isSubmitted = true;

    this.route.queryParams.subscribe(params => {
      this.referralCode = params['referralcode'];
  });


  if(this.referralCode)
  {
    const REFERRAL_CODE = 'REFERRAL_CODE';
    this.localStorageService.setItem(REFERRAL_CODE, this.referralCode);
  }

    if (this.loginForm.value.phone.startsWith('677')) {
      const phoneNumberInternal: string = this.loginForm.value.phone
        .replace(/\s/g, '')
        .replace(/\+/g, '');

      this.store.dispatch(authLogin({ user: new UserLoginWriteServiceModel({ phoneNumber: phoneNumberInternal}) }));

      return;
    }

    if (this.loginForm.invalid) {
      this.toastService.showError(this.translateService.instant('auth.error.number'));

      return;
    }

    const phoneNumber: string = this.loginForm.value.phone
      .replace(/\s/g, '')
      .replace(/\+/g, '');

    this.store.dispatch(authLogin({ user: new UserLoginWriteServiceModel({ phoneNumber }) }));
  }
}
