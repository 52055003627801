
import { NavigationEnum } from '@jameel/core/enums/';
import { setAuthPage, setErrorPage } from '@jameel/core/page/page.actions';
import { IPageState } from '@jameel/core/page/page.model';
import {
  selectAuthPage,
  selectErrorPage,
  selectIsFetching,
} from '@jameel/core/page/page.selectors';
import { selectSettingsLanguage } from '@jameel/core/settings/settings.selectors';

import { IAppState } from './core/core.state';
import { DocumentDir, Language } from './core/settings/settings.model';

import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
  NavigationEnd,
  NavigationStart,
  RouteConfigLoadEnd,
  RouteConfigLoadStart,
  Router,
} from '@angular/router';
import { select, Store } from '@ngrx/store';
import { environment } from 'environments/environment';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  isProd = environment.production;
  title = 'Captain registration';
  authPage: boolean;
  errorPage: boolean;
  showSidebar = true;
  showNavbar = true;
  showFooter = false;
  isLoading: boolean;
  isLogin = true;
  lat: number = 51.678418;
  lng: number = 7.809007;

  private subscription: Subscription = new Subscription();

  constructor(private router: Router, private store$: Store<IPageState>, private titleService: Title,
    private coreStore$: Store<IAppState>, @Inject(DOCUMENT) private document: Document) { }

  ngOnInit() {
    this.titleService.setTitle('Captain Registration جميل');

    this.subscription.add(
      this.store$
        .pipe(select(selectIsFetching))
        .subscribe((isFetching) => (this.isLoading = isFetching)),
    );
    this.subscription.add(
      this.store$
        .pipe(select(selectAuthPage))
        .subscribe((authPage: boolean) => {
          this.authPage = authPage;
          this.showSidebar = !authPage;
          this.showNavbar = !authPage;
          this.showFooter = false;
          this.isLogin = authPage;
        }),
    );
    this.subscription.add(
      this.store$
        .pipe(select(selectErrorPage))
        .subscribe((errorPage: boolean) => (this.errorPage = errorPage)),
    );
    this.subscription.add(
      this.router.events
        .pipe(filter((event) => event instanceof NavigationEnd))
        .subscribe((event) => {
          window.scrollTo(0, 0);
        }),
    );
    this.subscription.add(
      this.router.events.subscribe((event) => {
        // tslint:disable-next-line: no-collapsible-if
        if (event instanceof NavigationEnd && this.isProd) {
          if (event.urlAfterRedirects.includes(NavigationEnum.Auth) ||
            event.urlAfterRedirects.includes(NavigationEnum.Register)) {
            if (event.urlAfterRedirects.includes('auth/admin')) {
              return;
            }

            gtag('config', 'G-RZ1KJKCWF2', {
              page_path: event.urlAfterRedirects,
            });
          }
        }
      }));

    this.subscription.add(
      this.coreStore$
        .pipe(select(selectSettingsLanguage))
        .subscribe((lng: Language) => {
          this.setDocumentDir(lng);
          this.titleService.setTitle('Captain Registration جميل');
        }),
    );
  }

  ngAfterViewInit() {
    this.subscription.add(
      this.router.events
        .pipe(filter((event) => event instanceof NavigationStart))
        .subscribe((event: NavigationStart) => {
          if (
            event.url.includes(NavigationEnum.Auth) ||
            event.url === NavigationEnum.Default ||
            event.url === NavigationEnum.Main ||
            event.url === NavigationEnum.Error404 ||
            event.url === NavigationEnum.Error500
          ) {
            this.store$.dispatch(setAuthPage({ authPage: true }));
            if (
              event.url === NavigationEnum.Error404 ||
              event.url === NavigationEnum.Error500
            ) {
              this.store$.dispatch(setErrorPage({ errorPage: true }));
            }
          } else {
            this.store$.dispatch(setAuthPage({ authPage: false }));
            this.store$.dispatch(setErrorPage({ errorPage: false }));
          }
        }),
    );

    this.subscription.add(
      this.router.events
        .pipe(
          filter(
            (event) =>
              event instanceof RouteConfigLoadStart ||
              event instanceof RouteConfigLoadEnd,
          ),
        )
        .subscribe((event) => {
          if (event instanceof RouteConfigLoadStart) {
            this.isLoading = true;
          } else if (event instanceof RouteConfigLoadEnd) {
            this.isLoading = false;
          }
        }),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  setDocumentDir(lng: Language) {
    const dir = DocumentDir[lng];

    this.document.dir = dir;

    if (dir === 'rtl') {
      this.document.activeElement.classList.add('rtl');
    } else {
      this.document.activeElement.classList.remove('rtl');
    }
  }
}
