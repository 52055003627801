
import { fileInputAvailableExtensions } from '@jameel/core/consts/settings';
import { PersonalDocumentType } from '@jameel/core/enums';
import { ProfileService } from '@jameel/core/services/profile.service';
import { ToastService } from '@jameel/core/services/toast.service';

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { fetchProfileDetails } from 'app/features/home/application-detail/store/actions';

@Component({
  selector: 'app-upload-document',
  templateUrl: './upload-document.component.html',
  styleUrls: ['./upload-document.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: UploadDocumentComponent,
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadDocumentComponent {
  files: File[] = [];
  file: File;
  fileName: string;
  @Input() documentType: PersonalDocumentType;
  @Input() userExternalId: string;

  @Output() readonly onEdit = new EventEmitter();

  constructor(private toastService: ToastService,
    private profileService: ProfileService,
    private store: Store,
    private translateService: TranslateService) {
  }

  onSelect(event: any) {
    this.files = [...event.addedFiles] as File[];
    const file = this.files[0];
    const valid = this.isFileValid(file);

    if (!valid) {
      return;
    }
    this.file = file;
    this.fileName = file?.name;
    this.profileService.uploadDocuments(this.userExternalId, this.documentType, file).subscribe((result) => {
      this.toastService.showSuccess('File uploaded successfully');
      this.store.dispatch(fetchProfileDetails({ id: this.userExternalId }));
      this.files = [];
      this.onEdit.emit('true');
    }, (error) => {
      this.toastService.showError('File uploaded failure');
    });
  }

  private isFileValid(file: File) {
    return this.checkSize(file.size) && this.checkExtension(file.name);
  }

  private checkExtension(name: string) {
    const basename = name.split(/[\\/]/).pop(); // extract file name from full path ...
    const pos = basename.lastIndexOf('.'); // get last position of `.`

    if (basename === '' || pos < 1) {
      this.toastService.showError(
        this.translateService.instant('shared.error.wrong_extension') + fileInputAvailableExtensions.join(', '),
      );

      return false; //  `.` not found (-1) or comes first (0)
    }

    const ext = basename.slice(pos + 1);

    const isValid = fileInputAvailableExtensions.some((x) => x === ext.toLocaleLowerCase()); // Move to settings file or something like this

    if (!isValid) {
      this.toastService.showError(
        this.translateService.instant('shared.error.wrong_extension') + fileInputAvailableExtensions.join(', '),
      );
    }

    return isValid;
  }

  private checkSize(size: number) {
    const fileSize = Math.round(size / 1024);

    if (fileSize >= 10000) {
      this.toastService.showError(
        this.translateService.instant('shared.error.too_big'),
      );

      return false;
    }

    return true;
  }
}
