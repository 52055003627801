<div class="table__container--outer">
  <div class="table__container">
    <ng-container *ngIf="!hideFiltersAndSearch">
      <div class="table__title form-inline" *ngIf="tableTitle">
        <h2>{{tableTitle || '&nbsp;'}}</h2>
      </div>
      <div class="d-flex flex-nowrap justify-content-between mb-2">
        <div class="table__filters d-flex flex-wrap" *ngIf="filterColumns?.length">
          <app-table-filters *ngFor="let filter of filterColumns" [translatePrefix]="translatePrefix" [filter]="filter" [dateRangeFilterName]="dateRangeFilterName"
            (filtersChanged)="onFiltersChange($event)">
          </app-table-filters>
        </div>
        <div class="table__search ml-auto">
          <input [hidden]="hideSearch" (keyup)="setSearchQuery($event.target.value)" class="form-control border" name="searchTerm"
            placeholder="{{'shared.placeholder.table_search' | translate}}" type="text" />
        </div>
      </div>
    </ng-container>
    <div class="table__wrapper" [class.shorter]="!fullPageTable">
      <table class="table table-striped table-fixed table-bordered table-hover">
        <thead [class.sticky--top]="hideFiltersAndSearch" class="table__head">
          <tr>
            <th *ngFor="let header of headers | async" (click)="header.sortable && sortTableByColumn(header.name)"
              [ngClass]="getSortingClass(header.name)" [class.sorting-disabled]="!header.sortable" scope="col">
              {{ translatePrefix + header.name | translate}}
            </th>
            <th *ngIf="showDeleteButton">Action</th>
          </tr>
        </thead>
        <tbody class="table__body">
          <tr *ngFor="let item of list$ | async">
            <ng-container *ngFor="let header of headers | async">
              <ng-container [ngSwitch]="header.type">
                <td *ngSwitchCase="valueTypes.String">
                  {{item[header.name]}}
                </td>
                <td *ngSwitchCase="valueTypes.StringArray">
                  {{item[header.name]?.join('&nbsp;+&nbsp;')}}
                </td>
                <td *ngSwitchCase="valueTypes.Link">
                  <a (click)="redirectToLink(item[header.name])">{{item[header.name]?.label}}</a>
                </td>
                <td *ngSwitchCase="valueTypes.ExternalLink">
                  <a [href]="item[header.name]?.url" target="_blank">{{item[header.name]?.label}}</a>
                </td>
                <td *ngSwitchCase="valueTypes.Number">
                  {{item[header.name]}}
                </td>
                <td *ngSwitchCase="valueTypes.Email">
                  <a href="mailto:{{item[header.name]}}">{{item[header.name]}}</a>
                </td>
                <td *ngSwitchCase="valueTypes.PhoneNumber">
                  <a href="tel:{{item[header.name]}}">{{item[header.name]}}</a>
                </td>
                <td *ngSwitchCase="valueTypes.Date" class="text-wrap-no">
                  {{item[header.name] * 1000 | date:'shortDate'}}
                </td>
                <td *ngSwitchCase="valueTypes.Time" class="text-wrap-no">
                  <ng-container *ngIf="item[header.name]; else noTime">
                    {{item[header.name] * 1000 | date:'shortTime'}}
                  </ng-container>
                  <ng-template #noTime>-</ng-template>
                </td>
                <td *ngSwitchCase="valueTypes.Datetime">
                  <ng-container *ngIf="item[header.name]?.timestamp; else noDate">
                    <ng-container *ngIf="item[header.name]?.alternativeText; else date">
                      {{item[header.name]?.alternativeText}}
                    </ng-container>
                    <ng-template #date>
                      {{item[header.name]?.timestamp * 1000 | date:'shortDate'}}
                    </ng-template>
                    @ {{item[header.name]?.timestamp * 1000 | date:'shortTime'}}
                  </ng-container>
                  <ng-template #noDate>-</ng-template>
                </td>
                <td *ngSwitchCase="valueTypes.TimePeriod">
                  {{item[header.name]?.startDate * 1000 | date:'shortDate'}}
                  - {{item[header.name]?.endDate * 1000 | date:'shortDate'}}
                </td>
                <td *ngSwitchCase="valueTypes.Enum">
                  {{item[header.name] | splitCamelCase}}
                </td>
                <td *ngSwitchCase="valueTypes.EnumArray">
                  <ng-container *ngFor="let value of item[header.name]; let last = last">
                    {{value | splitCamelCase}}
                    <ng-container *ngIf="!last">&nbsp;+&nbsp;</ng-container>
                  </ng-container>
                </td>
                <td *ngSwitchCase="valueTypes.Boolean" class="text-wrap-no">
                  {{item[header.name] | yesNo}}
                </td>
                <td *ngSwitchCase="valueTypes.Image">
                  <img *ngIf="item[header.name]; else noImage" [src]="item[header.name]" />
                  <ng-template #noImage>-</ng-template>
                </td>
                <td *ngSwitchCase="valueTypes.Price" class="text-wrap-no">
                  {{item[header.name]?.amount | currency : '' : '' : '1.2-2'}} {{item[header.name]?.currencyCode}}
                </td>
                <td *ngSwitchCase="valueTypes.Percentage" class="text-wrap-no">
                  {{item[header.name]}}%
                </td>
                <td *ngSwitchCase="valueTypes.MixedNumeric">
                  <ng-container [ngSwitch]="item[header.name]?.format">
                    <ng-container *ngSwitchCase="valueTypes.Price">{{item[header.name]?.value | currency}}
                    </ng-container>
                    <ng-container *ngSwitchCase="valueTypes.Percentage">{{item[header.name]?.value}}%</ng-container>
                  </ng-container>
                </td>
                <td *ngSwitchCase="valueTypes.Action">
                  <a (click)="handleAction(item[header.name])">{{item[header.name]?.label}}</a>
                </td>
                <td *ngSwitchCase="valueTypes.CheckAction">
                  <input type="checkbox" (change)="handleCheckAction(item)" />
                </td>
                <td *ngSwitchCase="valueTypes.ActionArray">
                  <ng-container *ngFor="let action of item[header.name]; let last = last">
                    <a (click)="handleAction(action)">{{action.label}}</a>
                    <ng-container *ngIf="!last">&nbsp;|&nbsp;</ng-container>
                  </ng-container>
                </td>
                <td *ngSwitchDefault>
                  {{item[header.name]}}
                </td>

              </ng-container>
            </ng-container>

            <td *ngIf="showDeleteButton">
              <a (click)="handleAction({label:'externalId', externalId:item['externalId'],actionType:'Delete'})" href="javascript:;" class="nav-link"><i
                  class="mdi mdi-trash-can" style="font-size:1.5rem"></i></a>
            </td>
          </tr>
        </tbody>
      </table>
      <div *ngIf="!(list$ | async)?.length" class="table__empty">
        <p class="mb-0">{{'home.label.no_results' | translate}}</p>
      </div>
    </div>
  </div>
  <div *ngIf="(list$ | async)?.length">
    <div class="table__pagination-wrapper mt-4">
      <div class="table__pagination-select">
        <p>{{'home.label.show' | translate}}</p>
        <div class="table__pagination-select-wrapper">
          <ng-select [(ngModel)]="params.x_pageSize" (change)="setParams()" [clearable]="false" [closeOnSelect]="true"
            [items]="paginationOptions" [searchable]="false" [selectOnTab]="true" bindLabel="name" bindValue="value"
            class="dropdown--small dropdown--border" dropdownPosition="top"></ng-select>
        </div>
        <p>{{'home.label.per_page' | translate}}</p>
      </div>
      <ngb-pagination *ngIf="(pageInfo$ | async)?.totalCount > 1" [(page)]="params.x_pageNumber"
        [pageSize]="params.x_pageSize" (pageChange)="setPageNumber($event)"
        [collectionSize]="(pageInfo$ | async)?.totalCount" class="table__pagination d-flex justify-content-center"
        maxSize="5"></ngb-pagination>
    </div>
  </div>
</div>