import { AbstractBaseService } from '@jameel/core/abstraction/base-service';
import { IEmptyData } from '@jameel/core/models';

import { environment } from '../../../../environments/environment';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AvatarService extends AbstractBaseService {
  constructor(private http: HttpClient) {
    super();

    this.baseUrl = environment.API_BASE_URL;
  }

  // Upload avatar for users (captains, admins)
  public uploadUserAvatar(
    file: FormData,
    externalId: string,
  ): Observable<IEmptyData> {
    let url = `${this.baseUrl}/admin/profile/avatar/selected?externalId=${externalId}`;

    const headers = new HttpHeaders();

    headers.set('Content-Type', 'multipart/form-data');
    url = url.replace(/[?&]$/, '');

    return this.http.post<IEmptyData>(url, file, {
      headers,
    });
  }

  // Upload avatar in registration
  public uploadAvatar(file: FormData): Observable<IEmptyData> {
    let url = this.baseUrl + '/admin/profile/avatar';

    const headers = new HttpHeaders();

    headers.set('Content-Type', 'multipart/form-data');
    url = url.replace(/[?&]$/, '');

    return this.http.post<IEmptyData>(url, file, {
      headers,
    });
  }
}
