import { ICaptainTrainingResponseModel, ITrainingLookupResponse } from '@jameel/core/models/';

import { createAction, props } from '@ngrx/store';

export const initTrainings = createAction('[Init training] Init training');
export const initTrainingSuccess = createAction('[Init training success] Init training success', props<{ trainings: ITrainingLookupResponse[] }>());
export const initTrainingFailure = createAction('[Init training failure] Init documents failure', props<{ errors: string }>());

export const getCurrentTraining = createAction('[Get Current Training] Get current training');
export const getCurrentTrainingSuccess = createAction('[Get current training success] Get current training success', props<{ training: ICaptainTrainingResponseModel }>());

export const actionScheduleTraining = createAction('[Schedule training] Schedule training', props<{ id: string }>());
export const actionScheduleTrainingSuccess = createAction('[Schedule training success] Schedule training success');
export const actionScheduleTrainingFailure = createAction('[Schedule training failure] Schedule training failure', props<{ errors: string }>());
