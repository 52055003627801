
import { BalanceAdjustmentType, BlockadeType, Gender, UserApplicationStatus } from '../enums/';

import { ICaptainDetailsResponseModel, IErrorDto, ILanguageResponseModel, IVehicleResponseModel } from './';

export interface IUserDashboard {
  externalId?: string;
  hasCompletedOnlineTraining?: boolean;
  hasDownloadedCaptainApplication?: boolean;
  hasFixedRejectedDocuments?: boolean;
  hasUploadedDocuments?: boolean;
  hasUploadedVehiclePhotos?: boolean;
}

export interface IUserLanguage {
  language?: ILanguageResponseModel;
  externalId?: string;
  created?: number;
  updated?: number | null;
}

export interface IUserProfileResponseModelEnvelope {
  data?: UserProfileResponseModel;
  errors?: IErrorDto[] | null;
  hasErrors?: boolean;
}

export interface IUserProfileResponseModel {
  name?: string | null;
  phoneNumber?: string | null;
  email?: string | null;
  referralId?: string | null;
  rating?: number;
  avatarUrl?: string | null;
  birthday?: number | null;
  city?: string | null;
  preferredLanguages?: IUserLanguage[] | null;
  spokenLanguages?: IUserLanguage[] | null;
  captainDetails?: ICaptainDetailsResponseModel;
  vehicles?: IVehicleResponseModel[] | null;
  gender?: Gender;
  externalId?: string;
  created?: number;
  updated?: number | null;
  companyId?: number;
}

export class UserCaptainRegistrationRequestModel implements IUserCaptainRegistrationRequestModel {
  cityExternalId!: string;
  name!: string;
  phoneNumber!: string;
  email!: string;
  requestId!: string;
  hasAcceptedTermsAndConditions: boolean = true;
  referralCode: string;

  constructor(data?: IUserCaptainRegistrationRequestModel) {
    if (!data) {
      return;
    }
    for (const property in data) {
      if (data.hasOwnProperty(property)) {
        (this)[property] = (data)[property];
      }
    }
  }
}

export interface IUserCaptainRegistrationRequestModel {
  cityExternalId: string;
  name: string;
  phoneNumber: string;
  email: string;
  requestId: string;
  hasAcceptedTermsAndConditions: boolean;
  referralCode: string;
}

export class UserLoginResponseModel implements IUserLoginResponseModel {
  otpRecipientPhoneNumber?: string | null;
  requestId?: string | null;
  constructor(data?: IUserLoginResponseModel) {
    if (!data) {
      return;
    }
    for (const property in data) {
      if (data.hasOwnProperty(property)) {
        (<any>this)[property] = (<any>data)[property];
      }
    }
  }
}

export interface IUserLoginResponseModel {
  otpRecipientPhoneNumber?: string | null;
  requestId?: string | null;
}

export class UserLoginResponseModelEnvelope implements IUserLoginResponseModelEnvelope {
  data?: UserLoginResponseModel;
  errors?: IErrorDto[] | null;
  readonly hasErrors?: boolean;
  constructor(data?: IUserLoginResponseModelEnvelope) {
    if (!data) {
      return;
    }
    for (const property in data) {
      if (data.hasOwnProperty(property)) {
        (this)[property] = (data)[property];
      }
    }
  }
}

export interface IUserLoginResponseModelEnvelope {
  data?: UserLoginResponseModel;
  errors?: IErrorDto[] | null;
  hasErrors?: boolean;
}

export class UserLoginWithPasswordRequestModel implements IUserLoginWithPasswordRequestModel {
  username?: string | null;
  password?: string | null;
  constructor(data?: IUserLoginWithPasswordRequestModel) {
    if (!data) {
      return;
    }
    for (const property in data) {
      if (data.hasOwnProperty(property)) {
        (<any>this)[property] = (<any>data)[property];
      }
    }
  }
}

export interface IGoogleAuthenticatorResponseModelEnvelope {
  data?: IGoogleAuthenticatorResponse;
  errors?: IErrorDto[] | null;
  hasErrors?: boolean;
}

export interface IGoogleAuthenticatorResponse{
  qrUrl?: string | null;
  entrySetupCode?: string | null;
}

export interface IUserLoginWithPasswordRequestModel {
  username?: string | null;
  password?: string | null;
}

export class UserLoginWriteServiceModel implements IUserLoginWriteServiceModel {
  phoneNumber?: string | null;
  constructor(data?: IUserLoginWriteServiceModel) {
    if (!data) {
      return;
    }
    for (const property in data) {
      if (data.hasOwnProperty(property)) {
        (<any>this)[property] = (<any>data)[property];
      }
    }
  }
}

export interface IUserLoginWriteServiceModel {
  phoneNumber?: string | null;
}

export class UserProfileResponseModel implements IUserProfileResponseModel {
  name?: string | null;
  phoneNumber?: string | null;
  email?: string | null;
  referralId?: string | null;
  rating?: number;
  avatarUrl?: string | null;
  birthday?: number | null;
  city?: string | null;
  preferredLanguage?: ILanguageResponseModel;
  gender?: Gender;
  externalId?: string;
  created?: number;
  updated?: number | null;

  constructor(data?: IUserProfileResponseModel) {
    if (!data) {
      return;
    }
    for (const property in data) {
      if (data.hasOwnProperty(property)) {
        (this)[property] = (data)[property];
      }
    }
  }
}

export interface IUserStatus {
  applicationStatus: UserApplicationStatus;
  externalId: string;
}

export interface IUserUpdateStatusRequestModel {
  userExternalId: string;
  type: BlockadeType;
  reason: string;
  expiration?: number | null;
}

export interface IRiderBalanceAdjustmentRequestModel {
  riderExternalId: string;
  value: number;
  balanceAdjustmentType : BalanceAdjustmentType;
  description : string;
}

export interface ICaptainBalanceAdjustmentRequestModel {
  captainExternalId: string;
  value: number;
  balanceAdjustmentType : BalanceAdjustmentType;
  description : string;
}


export interface IRiderBalanceAdjustmentHistoryRequestModel {
  userExternalId: string;
  userName: string;
  value: number;
  adjustmentType: string;
  description: string;
  created: number;
}


export interface ICaptainBalanceAdjustmentHistoryRequestModel {
  userExternalId: string;
  userName: string;
  value: number;
  adjustmentType: string;
  description: string;
  created: number;
  isPin?: boolean;
}

export interface IMissingDocumentsResponseModel {
  documentType: string;
  isMissing: boolean;
}

export interface IMinistryResponse {
  identityNumber: string;
  driverEligibility: string;
  eligibilityExpiryDate: string;
  rejectionReasons: string[];
  criminalRecordStatus: string;
  vehicles: [
    {
      rejectionReasons: string[],
      sequenceNumber: string;
      vehicleEligibility: string;
    }
  ];
}
