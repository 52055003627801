import { AbstractBaseService } from '@jameel/core/abstraction/base-service';
import { IFilterRequest, IListParams } from '@jameel/core/models';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedService extends AbstractBaseService {
  constructor(private http: HttpClient) {
    super();
  }

  public fetchListCsv(
    params: IListParams,
    filters: IFilterRequest,
    urlExtension: string,
  ): Observable<any> {
    const url = this.baseUrl + urlExtension;
    const content = JSON.stringify([filters]);

    return this.http.put(url, content,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json-patch+json',
            'Accept': 'text/plain',
            'x-query': params.x_query ? `${params.x_query}` : '',
            'x-order': `${params.x_order}`,
            'x-desc': `${params.x_desc}`,
            'x-pageNumber': `${params.x_pageNumber}`,
            'x-pageSize': `${params.x_pageSize}`,
          }),
        responseType: 'blob',
      });
  }

  public downloadCsv(
    params: any,
    urlExtension: string,
  ): Observable<any> {
    const url = this.baseUrl + urlExtension;
    const content = JSON.stringify(params);
    return this.http.put(url, content,
      {
        headers: new HttpHeaders(
          {
            'Content-Type': 'application/json-patch+json',
            'Accept': 'text/plain',
          }),
        responseType: 'blob',
      });
  }
}
