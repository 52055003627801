import { IVehicleDetailsResponse, IVehicleResponsePaginated } from '@jameel/core/models/';

import { createAction, props } from '@ngrx/store';

export const actionCarPhotos = createAction('[Car Photos] Add car photos',
  props<{
    exteriorFront?: FormData, exteriorRight?: FormData, exteriorLeft?: FormData, carProfile?: FormData,
    exteriorRear?: FormData, interiorFront?: FormData, interiorRear?: FormData, externalId: string,
  }>());

export const actionCarPhotosSuccess = createAction(
  '[Car Photos] Add car photos success',
);

export const actionCarPhotosFailure = createAction(
  '[Car Photos] Add car photos failure',
);

// Init
export const actionInitCarPhotos = createAction('[Car Photos] Init car photos', props<{
  exteriorFront?: string, exteriorRight?: string, exteriorLeft?: string, carProfile?: FormData,
  exteriorRear?: string, interiorFront?: string, interiorRear?: string,
}>());

// Init cars
export const actionInitCars = createAction('[Car Photos] Init cars');

export const actionInitCarsSuccess = createAction('[Car Photos] Init cars success',
  props<IVehicleResponsePaginated>());

export const actionInitCarPFailure = createAction('[Car Photos] Init cars failure',
  props<{ error: string }>());

// Init cars
export const actionChangeCurrentCar = createAction('[Car Photos] Change current car', props<{ id: string }>());

export const actionChangeCurrentCarSuccess = createAction('[Car Photos] Change current car success',
  props<{ car: IVehicleDetailsResponse }>());

export const actionChangeCurrentCarFailure = createAction('[Car Photos] Change current car failure',
  props<{ error: string }>());
