import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'yesNo' })
export class YesNoPipe implements PipeTransform {
  transform(value: boolean): string {
    if (value === null) {
      return 'N/A';
    }

    return value ? 'Yes' : 'No';
  }
}
