<nav class="sidebar sidebar-offcanvas d-flex flex-column" id="sidebar" 
  *ngIf="isAdmin || isOperation_Op || isOperation_Super || isMarketing_Op || isMarketing_Super || isIt_Op || isIt_Super || isFinance_Op || isFinance_Super">
  <ul class="nav flex-grow-1">
    <li class="nav-item" routerLinkActive="active" *ngFor="let nav of navigation" routerLink="{{ nav.routerLink }}"
      [routerLinkActiveOptions]="{ exact: true }">
      <!-- <a class="nav-link" (click)="nav.expandArrowstate = !nav.expandArrowstate"
        [attr.aria-expanded]="nav.expandArrowstate" aria-controls="ui-basic" *ngIf="!!nav.children.length">
        <span class="menu-title">{{ nav.translate | translate }}</span>
        <i class="menu-arrow"></i>
        <i [class]="'mdi menu-icon ' + nav.options.iconClasses"></i>
      </a> -->
      <a class="nav-link" (click)="nav.expandArrowstate = !nav.expandArrowstate"
        [attr.aria-expanded]="nav.expandArrowstate" aria-controls="ui-basic" *ngIf="nav.visible"
        routerLinkActive="active">
        <span class="menu-title">{{ nav.translate | translate }}</span>
        <i [class]="'mdi menu-icon ' + nav.options.iconClasses"></i>
      </a>

      <div class="collapse" [id]="nav.name" [ngbCollapse]="!nav.expandArrowstate" *ngIf="!!nav.children.length">
        <ul class="nav flex-column sub-menu">
          <li class="nav-item" *ngFor="let secondLevel of nav.children" routerLink="{{ secondLevel.routerLink }}">
            <a class="nav-link" routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: secondLevel.options.routerExact }">{{ secondLevel.translate |
              translate }}</a>
          </li>
        </ul>
      </div>
    </li>
    <li class="nav-item" (click)="goToZendesk()">
      <a #zendeskLink class="nav-link" href="https://jameelksa.zendesk.com/agent/get-started" target="_blank"
        aria-controls="ui-basic">
        <span class="menu-title">Zendesk</span>
        <i class="mdi menu-icon mdi-ticket"></i>
      </a>
    </li>

  </ul>

  <div class="app-version" [class.hidden]="production">
    <div>ver.</div>{{appVersion}}
  </div>
</nav>

<nav class="sidebar sidebar-offcanvas" id="sidebar" 
*ngIf="!isAdmin && !isOperation_Op && !isOperation_Super && !isMarketing_Op && !isMarketing_Super && !isIt_Op && !isIt_Super && !isFinance_Op && !isFinance_Super">
  <ul class="nav flex-grow-1">
    <li class="nav-item" [ngClass]="{
      active:
        dashboard.isActive || myDocuments.isActive
    }">
      <a class="nav-link" (click)="uiBasicCollapsed = !uiBasicCollapsed" [attr.aria-expanded]="uiBasicCollapsed"
        #register aria-controls="ui-basic">
        <span class="menu-title">{{ "route.register" | translate }}</span>
        <i class="menu-arrow"></i>
        <i class="mdi mdi-crosshairs-gps menu-icon"></i>
      </a>
      <div class="collapse" id="ui-basic" [ngbCollapse]="!uiBasicCollapsed">
        <ul class="nav flex-column sub-menu">
          <li class="nav-item">
            <a class="nav-link" [ngClass]="{ 'text-primary': dashboard.isActive }" routerLink="register/dashboard"
              routerLinkActive="active" #dashboard="routerLinkActive">
              {{ "route.dashboard" | translate }}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [ngClass]="{ 'text-primary': myDocuments.isActive }" routerLink="register/my-documents"
              routerLinkActive="active" #myDocuments="routerLinkActive">
              {{ "route.my_documents" | translate }}</a>
          </li>
        </ul>
      </div>
    </li>
    <li class="nav-item" (click)="logout()">
      <a class="nav-link" target="_blank" aria-controls="ui-basic">
        <span class="menu-title">{{ 'shared.navbar.sign_out' | translate }}</span>
        <i class="mdi menu-icon mdi-logout"></i>
      </a>
    </li>
  </ul>

  <div class="app-version" [class.hidden]="production">
    <div>ver.</div>{{appVersion}}
  </div>
</nav>