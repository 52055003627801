import { CsvExportUrl } from '@jameel/core/enums';
import { IFilterRequest, IListParams } from '@jameel/core/models';
import { SharedService } from '@jameel/core/services';

import { Component, Input, OnDestroy } from '@angular/core';
import { saveAs } from 'file-saver';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-export-csv',
  templateUrl: './export-csv.component.html',
  styleUrls: ['./export-csv.component.scss'],
})
export class ExportCsvComponent implements OnDestroy {
  @Input() activeFilters: IFilterRequest;
  @Input() params: IListParams;
  @Input() urlExtension: CsvExportUrl;
  @Input() name : string = 'home.label.export_csv';
  @Input() disabled: boolean =false;

  private sub = new Subscription();
  isLoading: boolean= true;
  constructor(private sharedService: SharedService) { }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  getFileName(): string {
    switch (this.urlExtension) {
      case CsvExportUrl.TripList:
        return 'TripList';

      case CsvExportUrl.TrainingList:
        return 'TrainingList';

      case CsvExportUrl.RiderList:
        return 'CustomerList';
      case CsvExportUrl.CaptainList:
        return 'CaptainList';

      case CsvExportUrl.ApplicationList:
        return 'ApplicationList';

     case CsvExportUrl.ApplicationAllList:
          return 'AllApplicationList';

      default:
        return 'List';
    }
  }

  export() {
    var that = this;
    this.isLoading = false;
    this.sub.add(this.sharedService.fetchListCsv(this.params, this.activeFilters, this.urlExtension).subscribe((res) => { 
      saveAs(res, `${this.getFileName()}.csv`);
      that.isLoading = true;
    }));
  }
}
