import { fileInputAvailableExtensions } from '@jameel/core/consts/settings';

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-display-validation',
  templateUrl: './display-validation.component.html',
  styleUrls: ['./display-validation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisplayValidationComponent {
  @Input() additionalValidate: boolean;
  @Input() label: string;
  @Input() errors: any; // Any: because custom validations
  extensions = fileInputAvailableExtensions;
}
