import {
    AuthenticationResponseModel, CitiesModel, ConfirmOtpRequestModel,
    IChangePasswordRequestModel,
    IRequestResetPasswordRequestModel, ResendOtpRequestModel, UserCaptainRegistrationRequestModel,
    UserLoginWithPasswordRequestModel, UserLoginWriteServiceModel,
} from '@jameel/core/models/';

import { createAction, props } from '@ngrx/store';

export const authLogin = createAction('[Auth] Login', props<{ user: UserLoginWriteServiceModel }>());

export const authLoginZendesk = createAction('[Auth] Login Zendesk', props<{ user: UserLoginWriteServiceModel }>());
export const authLoginZendeskSuccess = createAction('[Auth] Login Zendesk Success', props<{ user: UserLoginWriteServiceModel }>());

export const authZendeskToken = createAction('[Auth] Get Zendesk token', props<{ jwt: string }>());
export const authZendeskTokenSuccessful = createAction('[Auth] Get Zendesk token Success', props<{ token: string }>());

export const authConfirmOtp = createAction('[Auth] Confirm OTP', props<{ otpRequest: ConfirmOtpRequestModel, isZendesk?: boolean }>());
export const authConfirmOtpSuccess = createAction('[Auth] Confirm OTP Success', props<{ authenticationResponseModel: AuthenticationResponseModel, isZendesk?: boolean }>());
export const authConfirmOtpFailure = createAction('[Auth] Confirm OTP Failure', props<{ error: any }>());

export const authRegisterCaptain = createAction('[Auth] Register captain', props<{ userCaptainRegistrationRequestModel: UserCaptainRegistrationRequestModel }>());
export const authRegisterCaptainFailure = createAction('[Auth] Register captain Failure', props<{ error: any }>());
export const authRegisterCaptainSuccess = createAction('[Auth] Register captain Success ', props<{ authenticationResponseModel: AuthenticationResponseModel }>());

export const authLogout = createAction('[Auth] Logout');

export const authLoadCity = createAction('[Auth] Load city ');
export const authLoadCitySuccess = createAction('[Auth] Load city success', props<{ cities: CitiesModel[] }>());

export const authLoadCityInternal = createAction('[Auth] Load city internal');
// export const authLoadCityInternalSuccess = createAction('[Auth] Load city internal success', props<{ cities: CitiesModel[] }>());

export const resendOtp = createAction(`[Auth] Resend Otp`, props<{ otpResend: ResendOtpRequestModel }>());
export const resendOtpFailure = createAction(`[Auth] Resend Otp Failure`, props<{ error: any }>());

export const authLoginAdmin = createAction('[Auth] Login Admin', props<{ loginAdminRequest: UserLoginWithPasswordRequestModel }>());
export const authLoginAdminSuccess = createAction('[Auth] Login Admin Success', props<{ authenticationResponseModel: AuthenticationResponseModel }>());
export const authLoginAdminFailure = createAction('[Auth] Login Admin Failure', props<{ error: any }>());

export const authResetPassword = createAction('[Auth] Reset password', props<{ resetPassword: IRequestResetPasswordRequestModel }>());
export const authResetPasswordSuccess = createAction('[Auth] Reset password Success');
export const authResetPasswordFailure = createAction('[Auth] Reset password Failure', props<{ error: any }>());

export const authChangePassword = createAction('[Auth] Change password', props<{ resetPassword: IChangePasswordRequestModel }>());
export const authChangePasswordSuccess = createAction('[Auth] Change password Success');
export const authChangePasswordFailure = createAction('[Auth] Change password Failure', props<{ error: any }>());

export const authChangeEmail = createAction('[Auth] Login Zendesk change email', props<{ email: string, jwt: string }>());
export const authChangeEmailSuccess = createAction('[Auth] Login Zendesk change email', props<{ jwt: string }>());
