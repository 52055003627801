<td>
  {{title}}
</td>
<td *ngIf="!editMode">
  {{form.controls[name]?.value || '-'}}
</td>
<td *ngIf="editMode" [formGroup]="form">
  <div class="input-group">
    <input [formControlName]="name" [value]="value" [type]="type" [mask]="mask" class="form-control form-control-lg"
      [class.is-invalid]="isFormSubmitted && form.get(name).invalid"
      placeholder="{{ 'home.placeholder.fill_text' | translate }}">
  </div>
  <app-display-validation [errors]="errors" [additionalValidate]="isFormSubmitted"></app-display-validation>
</td>