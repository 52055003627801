import { FilterKind, QuantityFilterCondition, ValueFilterCondition } from '@jameel/core/enums';
import { IDateFilter, IEnumFilter, IFilterOptions, IQuantityFilter, ISingleFilterRequest, IValueFilter } from '@jameel/core/models';

import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import {
  NgbCalendar,
  NgbDate,
  NgbDateParserFormatter,
  NgbDateStruct,
  NgbInputDatepicker,
} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-table-filters',
  templateUrl: './table-filters.component.html',
  styleUrls: ['./table-filters.component.scss'],
})
export class TableFiltersComponent implements AfterViewInit {
  @Input() filter: IFilterOptions;
  @Input() translatePrefix: string;

  @Output() readonly filtersChanged = new EventEmitter<ISingleFilterRequest>();

  @ViewChild('dateRangePicker') dateRangePicker: NgbInputDatepicker;

  readonly filterTypes = FilterKind;
  readonly conditions: QuantityFilterCondition[] = Object.values(QuantityFilterCondition);
  readonly valueFilterConditions: ValueFilterCondition[] = Object.values(ValueFilterCondition);

  condition: QuantityFilterCondition = this.conditions[0];
  intValue: number;
  enumValue: string;
  dateValue: NgbDateStruct;
  cityValue: string;
  rideTypeValue: string;
  dateRange: IDateRange = {
    startDate: null,
    endDate: null,
    hoveredDate: null,
  };

  isFilterActive = false;
  @Input() dateRangeFilterName?: string;
  constructor(private calendar: NgbCalendar, public formatter: NgbDateParserFormatter) {
  }

  filterChanged(clearInput: boolean = false, date: NgbDate = null): void {
    if (this.filter.kind === FilterKind.Enum) {
      this.isFilterActive = !!this.enumValue;
      this.filtersChanged.emit({
        clearFilter: !this.isFilterActive,
        filter: <IEnumFilter>{
          fieldName: this.filter.name,
          value: this.enumValue,
        },
        type: this.filter.kind,
      });

      return;
    }
    if (this.filter.kind === FilterKind.Int) {
      this.isFilterActive = !!this.intValue;
      this.filtersChanged.emit({
        clearFilter: !this.isFilterActive,
        filter: <IQuantityFilter>{
          condition: this.condition,
          fieldName: this.filter.name,
          value: this.intValue,
        },
        type: this.filter.kind,
      });

      return;
    }
    if (this.filter.kind === FilterKind.Date) {
      if (clearInput && this.dateValue) {
        this.dateValue = null;

        return;
      }
      this.isFilterActive = !!this.dateValue;
      this.filtersChanged.emit({
        clearFilter: !this.isFilterActive,
        filter: <IDateFilter>{
          fieldName: this.filter.name,
          date: this.prepareTimestamp(this.dateValue),
        },
        type: this.filter.kind,
      });

      return;
    }
    if (this.filter.kind === FilterKind.String && this.filter.name === 'city') {
      if (clearInput && this.cityValue) {
        this.cityValue = null;

        return;
      }
      this.isFilterActive = !!this.cityValue;
      this.filtersChanged.emit({
        clearFilter: !this.isFilterActive,
        filter: <IValueFilter>{
          fieldName: this.filter.name,
          value: this.cityValue,
        },
        type: this.filter.kind,
      });

      return;
    }

    if (this.filter.kind === FilterKind.String && this.filter.name === 'rideType') {
      if (clearInput && this.rideTypeValue) {
        this.cityValue = null;

        return;
      }
      this.isFilterActive = !!this.rideTypeValue;
      this.filtersChanged.emit({
        clearFilter: !this.isFilterActive,
        filter: <IValueFilter>{
          fieldName: this.filter.name,
          value: this.rideTypeValue,
        },
        type: this.filter.kind,
      });

      return;
    }

    // tslint:disable-next-line: early-exit
    if (this.filter.kind === FilterKind.DateRange) {
      if (clearInput && this.dateRange.startDate) {
        this.dateRange.startDate = null;
        this.dateRange.endDate = null;
        this.dateRange.hoveredDate = null;
      }
      if (!this.dateRange.startDate && !this.dateRange.endDate) {
        this.isFilterActive = false;
        this.dateRange.startDate = date;
      } else if (this.dateRange.startDate && !this.dateRange.endDate && date && date.after(this.dateRange.startDate)) {
        this.dateRange.endDate = date;
        this.isFilterActive = true;
        this.dateRangePicker.close();
      } else {
        this.isFilterActive = false;
        this.dateRange.endDate = null;
        this.dateRange.startDate = date;
      }

      this.filtersChanged.emit({
        clearFilter: !this.isFilterActive,
        filter: <IDateFilter>{
          fieldName: this.filter.name,
          startDate: this.prepareTimestamp(this.dateRange.startDate),
          endDate: this.prepareTimestamp(this.dateRange.endDate),
        },
        type: this.filter.kind,
      });

      return;
    }
  }

  isHovered(date: NgbDate): boolean {
    return this.dateRange.startDate
      && !this.dateRange.endDate
      && this.dateRange.hoveredDate
      && date.after(this.dateRange.startDate)
      && date.before(this.dateRange.hoveredDate);
  }

  isInside(date: NgbDate): boolean {
    return this.dateRange.endDate
      && date.after(this.dateRange.startDate)
      && date.before(this.dateRange.endDate);
  }

  isRange(date: NgbDate): boolean {
    return date.equals(this.dateRange.startDate)
      || (this.dateRange.endDate && date.equals(this.dateRange.endDate))
      || this.isInside(date)
      || this.isHovered(date);
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);

    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  prepareTimestamp(date: NgbDateStruct): number | null {
    return date ? new Date(date.year, date.month - 1, date.day).getTime() / 1000 : null;
  }

  ngAfterViewInit() {
    if (this.filter) {
      if (this.dateRangeFilterName) {
        if (this.filter.name == this.dateRangeFilterName) {
          if (this.filter.kind === this.filterTypes.DateRange) {
            var todayDate = new Date();
            let last30Days = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate() - 30);
            var monthStartDate = new NgbDate(last30Days.getFullYear(), last30Days.getMonth() + 1,last30Days.getDate());
            this.dateRange.startDate =  monthStartDate;//this.calendar.getToday();
            this.dateRange.endDate = this.calendar.getNext(this.calendar.getToday(),"d",1);
            this.dateRange.hoveredDate = this.calendar.getToday();
          }
        }
      }
    }
  }
}

interface IDateRange {
  startDate: NgbDate;
  endDate: NgbDate;
  hoveredDate: NgbDate;
}
