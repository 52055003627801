<td>
  {{title}}
</td>
<td *ngIf="!editMode">
  {{form.controls[name]?.value || '-'}}
</td>
<td *ngIf="editMode" [formGroup]="form">
  <div class="input-group mb-3">
    <input [formControlName]="name" [type]="type" [mask]="mask" class="form-control form-control-lg"
      [class.is-invalid]="isFormSubmitted && form.get(name).invalid"
      placeholder="{{ 'home.placeholder.fill_text' | translate }}">
    <button (click)="checkValue()" class="btn form-control-lg btn-gradient-primary" type="button" id="button-addon2">check
      <!-- <span *ngIf="loading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> -->
    </button>
  </div>


  <app-display-validation [errors]="errors" [additionalValidate]="isFormSubmitted"></app-display-validation>
</td>