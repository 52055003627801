import { IErrorDto } from './errorDto.model';

export class AuthenticationResponseModel implements IAuthenticationResponseModel {
  isOtpConfirmed?: boolean;
  isUserRegistered?: boolean;
  otpRecipientPhoneNumber?: string | null;
  requestId?: string | null;
  email?: string | null;
  isGoogleAuthenticator: boolean;
  userExternalId?: string  | null;
  tokens?: AuthenticationTokensResponseModel;
  privilages: any[];
  constructor(data?: IAuthenticationResponseModel) {
    if (!data) {
      return;
    }
    for (const property in data) {
      if (data.hasOwnProperty(property)) {
        (this)[property] = (data)[property];
      }
    }
  }
}

export interface IAuthenticationResponseModel {
  isOtpConfirmed?: boolean;
  isUserRegistered?: boolean;
  otpRecipientPhoneNumber?: string | null;
  requestId?: string | null;
  tokens?: AuthenticationTokensResponseModel;
}

export class AuthenticationResponseModelEnvelope implements IAuthenticationResponseModelEnvelope {
  data?: AuthenticationResponseModel;
  errors?: IErrorDto[] | null;
  readonly hasErrors?: boolean;
  constructor(data?: IAuthenticationResponseModelEnvelope) {
    if (!data) {
      return;
    }
    for (const property in data) {
      if (data.hasOwnProperty(property)) {
        (this)[property] = (data)[property];
      }
    }
  }
}

export interface IAuthenticationResponseModelEnvelope {
  data?: AuthenticationResponseModel;
  errors?: IErrorDto[] | null;
  hasErrors?: boolean;
}

export class AuthenticationTokensResponseModel implements IAuthenticationTokensResponseModel {
  refreshToken?: string | null;
  token?: string | null;
  constructor(data?: IAuthenticationTokensResponseModel) {
    if (!data) {
      return;
    }
    for (const property in data) {
      if (data.hasOwnProperty(property)) {
        (<any>this)[property] = (<any>data)[property];
      }
    }
  }
}

export interface IAuthenticationTokensResponseModel {
  refreshToken?: string | null;
  token?: string | null;
}

export class AuthenticationTokensResponseModelEnvelope implements IResponseModelEnvelope<AuthenticationTokensResponseModel> {
  data?: AuthenticationTokensResponseModel;
  errors?: IErrorDto[] | null;
  readonly hasErrors?: boolean;

  constructor(data?: IResponseModelEnvelope<AuthenticationTokensResponseModel>) {
    if (!data) {
      return;
    }
    for (const property in data) {
      if (data.hasOwnProperty(property)) {
        (this)[property] = (data)[property];
      }
    }
  }
}

export interface IResponseModelEnvelope<T> {
  data?: T;
  errors?: IErrorDto[] | null;
  hasErrors?: boolean;
}

export class ConfirmOtpRequestModel implements IConfirmOtpRequestModel {
  otp!: string;
  requestId!: string;
  isZendesk?: boolean;
  constructor(data?: IConfirmOtpRequestModel) {
    if (!data) {
      return;
    }
    for (const property in data) {
      if (data.hasOwnProperty(property)) {
        (<any>this)[property] = (<any>data)[property];
      }
    }
  }
}

export interface IConfirmOtpRequestModel {
  otp: string;
  requestId: string;
  isZendesk?: boolean;
}

export class ResendOtpRequestModel implements IResendOtpRequestModel {
  requestId!: string;
  constructor(data?: IResendOtpRequestModel) {
    if (!data) {
      return;
    }
    for (const property in data) {
      if (data.hasOwnProperty(property)) {
        (<any>this)[property] = (<any>data)[property];
      }
    }
  }
}

export interface IResendOtpRequestModel {
  requestId: string;
}

export class RefreshTokenRequestModel implements IRefreshTokenRequestModel {
  refreshToken!: string;

  constructor(data?: IRefreshTokenRequestModel) {
    if (!data) {
      return;
    }
    for (const property in data) {
      if (data.hasOwnProperty(property)) {
        (this)[property] = (data)[property];
      }
    }
  }
}

export interface IRefreshTokenRequestModel {
  refreshToken: string;
}

export interface IRequestResetPasswordRequestModel {
  email: string;
}

export interface IChangePasswordRequestModel {
  email: string;
  token: string;
  password: string;
}
