import { CitiesModel } from '@jameel/core/models/';

import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { AdminProfileService } from 'app/features/home/application-detail/store/services/admin-profile.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-city-filter',
  templateUrl: './city-filter.component.html',
  styleUrls: ['./city-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CityFilterComponent implements OnInit, OnDestroy {
  cityValue: string;

  cities: CitiesModel[];
  @Output() readonly cityChangedEvent = new EventEmitter<string>();

  private sub: Subscription = new Subscription();

  constructor(private adminProfileService: AdminProfileService) { }
  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  ngOnInit() {
    this.sub.add(this.adminProfileService.loadCities().subscribe((result) => {
      this.cities = result;
    }));
  }

  cityChanged() {
    this.cityChangedEvent.emit(this.cityValue);
  }
}
