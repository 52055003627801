import { UserApplicationStatus } from '@jameel/core/enums';
import {
  CitiesModel, ICaptainBankInformationRequestModel,
  ICaptainDrivingLicenseResponseModel,
  ICaptainNationalIdRequestModel, IFilterRequest, IGenericListPaginatedResponse, IListParams, ILookupResponseModel, IUserProfileResponseModel,
  IVehicleDetailsResponse, IVehicleInsuranceRequest, IVehicleInsuranceResponse, IVehicleRegistrationRequestModel, IVehicleResponseModel,
} from '@jameel/core/models/';
import { IRideTypeCategoryListResponseModel } from '@jameel/core/models/IncentiveManagement';

import { createAction, props } from '@ngrx/store';

export const fetchProfileDetails = createAction('[Profile tabs] Fetch profile details', props<{ id: string }>());
export const fetchProfileDetailsSuccess = createAction(
  '[Profile tabs] Fetch profile details success', props<{ profile: IUserProfileResponseModel }>(),
);
export const fetchProfileDetailsFailure = createAction(
  '[Profile tabs] Fetch profile details failure', props<{ error: string }>(),
);

// Save data from document

export const saveNationalIdData = createAction('[Profile tabs] Save national ID', props<{ data: ICaptainNationalIdRequestModel }>());
export const saveNationalIdDataSuccess = createAction('[Profile tabs] Save national ID success', props<{ profile: IUserProfileResponseModel }>());
export const saveNationalIdDataFailure = createAction('[Profile tabs] Save national ID failure');

// Save data from document

export const saveDrivingLicenseData = createAction('[Profile tabs] Save driving license', props<{ data: ICaptainDrivingLicenseResponseModel }>());
export const saveDrivingLicenseSuccess = createAction('[Profile tabs] Save driving license success', props<{ profile: IUserProfileResponseModel }>());
export const saveDrivingLicenseFailure = createAction('[Profile tabs] Save driving license failure');

// Save bank information

export const saveBankInformation = createAction('[Profile tabs] Save bank information', props<{ data: ICaptainBankInformationRequestModel }>());
export const saveBankInformationSuccess = createAction('[Profile tabs] Save bank information success', props<{ profile: IUserProfileResponseModel }>());
export const saveBankInformationFailure = createAction('[Profile tabs] Save bank information failure');

// Approve
export const approveDocument = createAction('[Profile tabs] Approve document', props<{ id: string }>());
export const approveDocumentSuccess = createAction('[Profile tabs] Approve document success');
export const approveDocumentFailure = createAction('[Profile tabs] Approve document failure');

// Reject
export const rejectDocument = createAction('[Profile tabs] Reject document', props<{
  documentExternalId: string,
  rejectReasonExternalId: string,
  notes: string,
}>());
export const rejectDocumentSuccess = createAction('[Profile tabs] Reject document success');
export const rejectDocumentFailure = createAction('[Profile tabs] Reject document failure');

// Get Rejected reasons
export const rejectedReasons = createAction('[Profile tabs] Fetch rejected reasons');
export const rejectedReasonsSuccess = createAction('[Profile tabs] Fetch rejected reasons success', props<{ data: ILookupResponseModel[] }>());
export const rejectedReasonsFailure = createAction('[Profile tabs] Fetch rejected reasons failure');

// City
export const city = createAction('[Profile tabs] Fetch city reasons');
export const citySuccess = createAction('[Profile tabs] Fetch city reasons success', props<{ data: CitiesModel[] }>());
export const cityFailure = createAction('[Profile tabs] Fetch city reasons failure');

// Change city
export const changeCity = createAction('[Profile tabs] Change city', props<{ externalId: string, city: string }>());
export const changeCitySuccess = createAction('[Profile tabs] Change city success');
export const changeCityFailure = createAction('[Profile tabs] Change city failure');

// Change email
export const changeEmail = createAction('[Profile tabs] Change email', props<{ externalId: string, email: string }>());
export const changeEmailSuccess = createAction('[Profile tabs] Change email success', props<{ email: string }>());
export const changeEmailFailure = createAction('[Profile tabs] Change email failure');

// Change phoneNumber
export const changePhoneNumber = createAction('[Profile tabs] Change phone number', props<{ externalId: string, phoneNumber: string }>());
export const changePhoneNumberSuccess = createAction('[Profile tabs] Change phone number success', props<{ phoneNumber: string }>());
export const changePhoneNumberFailure = createAction('[Profile tabs] Change phone number failure');

// Change current vehicle tab
export const currentVehicle = createAction('[Profile tabs] Change current vehicle tab', props<{ vehicle: IVehicleResponseModel }>());

// Change current vehicle tab
export const changeVehicleDetails = createAction('[Default vehicle] Default vehicle details', props<{ id: string }>());
export const changeVehicleDetailsSuccess = createAction('[Default vehicle] Default vehicle details success',
  props<{ vehicle: IVehicleDetailsResponse }>());
export const changeVehicleDetailsFailure = createAction('[Default vehicle] Default vehicle details failure',
  props<{ error: string }>());

// Change current vehicle tab
export const defaultVehicleDetails = createAction('[Default car] Change default vehicle details', props<{ id: string }>());
export const defaultVehicleDetailsSuccess = createAction('[Default car] Change default vehicle vehicle details success',
  props<{ vehicle: IVehicleDetailsResponse }>());
export const defaultVehicleDetailsFailure = createAction('[Default car] Change default vehicle vehicle details failure',
  props<{ error: string }>());

// Update vehicle registration
export const updateVehicleRegistration = createAction('[Profile tabs] Update vehicle registration', props<{ vehicle: IVehicleRegistrationRequestModel }>());
export const updateVehicleRegistrationSuccess = createAction('[Profile tabs] Update vehicle registration success',
  props<{ vehicle: IVehicleDetailsResponse }>());
export const updateVehicleRegistrationFailure = createAction('[Profile tabs] Update vehicle registration failure',
  props<{ error: string }>());

// Update insurance
export const updateVehicleInsurance = createAction('[Profile tabs] Update vehicle insurance', props<{ vehicle: IVehicleInsuranceRequest }>());
export const updateVehicleInsuranceSuccess = createAction('[Profile tabs] Update vehicle insurance success',
  props<{ vehicle: IVehicleInsuranceResponse }>());
export const updateVehicleInsuranceFailure = createAction('[Profile tabs] Update vehicle insurance failure',
  props<{ error: string }>());

// Update application status
export const updateApplicationStatus = createAction('[Profile tabs] Update application status',
  props<{ applicationStatus: UserApplicationStatus, externalId: string, reason?: string, documentType?: string }>());
export const updateApplicationStatusSuccess = createAction('[Profile tabs] Update application status success',
  props<{ profile: IUserProfileResponseModel }>());
export const updateApplicationStatusFailure = createAction('[Profile tabs] Update application status failure',
  props<{ error: string }>());

// Update application status
export const sendToAPI = createAction('[Ministry API] Update application status',
  props<{ externalId: string }>());
export const sendToAPISuccess = createAction('[Ministry API] Update application status success',
  props<{ profile: IUserProfileResponseModel }>());
export const sendToAPIFailure = createAction('[Ministry API] Update application status failure',
  props<{ error: string }>());

export const resetStore = createAction('[Profile] Reset store');


// profile ride category
export const fetchRideTypeCategoryList =
  createAction('[Ride Type Category list] Fetch Ride Type Category list', props<{ payload: { externalId: any, params: IListParams, filters: IFilterRequest } }>());
export const fetchRideTypeCategoryListSuccess =
  createAction('[Ride Type Category] Fetch Ride Type Category list success', props<{ rideTypeCategoryList: IGenericListPaginatedResponse<IRideTypeCategoryListResponseModel> }>());
