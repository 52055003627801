import { IAuthState } from '../authentication/store/auth.models';
import { authReducer } from '../authentication/store/auth.reducers';

import { initStateFromLocalStorage } from './meta-reducers/init-state-from-local-storage.reducer';
import { IPageState } from './page/page.model';
import { pageReducer } from './page/page.reducer';
import { IRouterStateUrl } from './router/router.state';
import { ISettingsState } from './settings/settings.model';
import { settingsReducer } from './settings/settings.reducer';

import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { ActionReducerMap, createFeatureSelector, MetaReducer } from '@ngrx/store';

export const reducers: ActionReducerMap<IAppState> = {
  auth: authReducer,
  router: routerReducer,
  settings: settingsReducer,
  page: pageReducer,
};

export const selectAuthState = createFeatureSelector<IAppState, IAuthState>(
  'auth',
);

export const selectSettingsState = createFeatureSelector<
  IAppState,
  ISettingsState>('settings');

export const selectRouterState = createFeatureSelector<
  IAppState,
  RouterReducerState<IRouterStateUrl>>('router');

export const metaReducers: MetaReducer<IAppState>[] = [
  initStateFromLocalStorage,
];

export interface IAppState {
  auth: IAuthState;
  router: RouterReducerState<IRouterStateUrl>;
  settings: ISettingsState;
  page: IPageState;
}
